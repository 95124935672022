<template>
  <div id="intro" class="background">

    <div class="topheader">
      <button v-if="introstate > 2" class="exit" @click="goBack()" type="button"><img class="closeblock" src="../assets/back.svg" alt=""></button>
      <button v-if="introstate > 2" class="skip" @click="goNext()" type="button"><img class="closeblock" src="../assets/swipeRight.svg" alt=""></button>
    </div>
    
    <div class="statescontainer">
          
      <!-- welcome message -->
      <div v-if="introstate == 1">
        <div class="introtext flyin1">{{this.language.intro.intro1}}</div>
        <img class="logochuckling" src="../assets/hidelike.svg"><br>


        <button @click="next()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.intro.next}}</div>
        </button>
      </div>

      <!-- choose whether to set a profile pic or to create an avatar -->
      <div v-if="introstate == 2">
        <div class="introtext flyin1">{{this.language.intro.intro2a}}</div>
        <div class="introtext flyin2">{{this.language.intro.intro2b}}</div>
        <div class="introtext flyin3">{{this.language.intro.intro2c}}</div>
        <img class="logochuckling" src="../assets/profile.svg"><br>
        <button @click="goPic()" class="nextbutton"  type="button">
          <div class="buttonfont">{{this.language.intro.buttonPicUpload}}</div>
          </button><br>
        <button @click="goAvatar()" class="nextbutton"  type="button">
          <div class="buttonfont">{{this.language.intro.buttonCreateAvatar}}</div>
        </button>
      </div>

      <!-- set profile pic -->
      <div v-if="introstate == 3">
        <div class="introtext flyin1">{{this.language.intro.uploadYourPictures}}</div>
        <profilepicture
          class="profilepicture" 
        >
        </profilepicture>
        <button @click="goDescription()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.intro.next}}</div>
        </button>
      </div>
      
      <!-- create avatar -->
      <div v-if="introstate == 4">
        <div class="introtext flyin1">{{this.language.intro.createYourAvatar}}</div>
        <avatarcreator
          ref="avatarComponent"
          class="profilepicture" 
        >
        </avatarcreator>
        <button @click="saveAvatar(); goDescription()" class="nextbutton" type="button">
          <div class="buttonfont">{{this.language.intro.next}}</div>
        </button>
      </div>

       <!-- set description -->
      <div v-if="introstate == 5">
        <div class="introtext flyin1">{{this.language.intro.describeYou}}</div>
        <description
          ref="descriptionComponent"
          class="description" 
        >
        </description>
        <button @click="saveDescription(); goFirstScan()" class="nextbutton" type="button">
            <div class="buttonfont">{{this.language.intro.next}}</div>
        </button>
      </div>

      <!-- go for the first scan -->
      <div v-if="introstate == 6">
        <div class="introtext flyin1">{{this.language.intro.scanNow}}</div>
        <button @click="goRadarScreen()"><img class="logo pulsate" src="../assets/hidelike-scan.svg"></button><br>
        <button @click="goRadarScreen()" class="nextbutton" type="button">
            <div class="buttonfont">{{this.language.intro.next}}</div>
        </button>
      </div>

    </div>
  </div> 
</template>


<script>
import profilepicture from '@/components/profilepicture'
import avatarcreator from '@/components/avatarcreator'
import description from '@/components/description'
import { store, mutations } from '@/components/store'


export default {
  name: "intro",
  
  components: {
    profilepicture,
    avatarcreator,
    description
  },

  data() {
    return {
      introstate: 1
    }

  },

  computed: {
    language() {return store.language},
  },

  methods: {

      goBack() {
        if (this.introstate == 4 || this.introstate == 5) {
          this.introstate = 2
        } else {
          if (this.introstate > 2 ) {this.introstate--}

        }
      },

      goNext() {

        if (this.introstate == 3) {
          this.goDescription()
        } else if (this.introstate == 4) {
          this.saveAvatar()
          this.goDescription()
        } else if (this.introstate == 5) {
          this.saveDescription()
          this.goFirstScan()
        } else {
          this.goRadarScreen()
        }
        


      },

      next() {
        this.introstate = 2
      },

      goPic() {
        this.introstate = 3
      },

      goAvatar() {
        this.introstate = 4
      },

      goDescription () {
        this.introstate = 5
      },

      goFirstScan () {
        this.introstate = 6
      },

      goRadarScreen() {
        this.$router.replace({
          name: "Driver"
        })
      },

      saveAvatar () {
        this.$refs.avatarComponent.saveAvatar()
      },

      saveDescription () {
        this.$refs.descriptionComponent.sendDescription()
      }

  }
}

</script>

<style scoped>


.background {
  padding-top: 30px;
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(315deg, #ffcc30 40%,#f5416c 80%);
  overflow-y: scroll;
}


.topheader {
  position: fixed;
  padding: 20px;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 8999;
}

.exit {
  margin-right: auto;
  height: 40px;
  width: 40px;
}

.skip {
  margin-left: auto;
  height: 40px;
  width: 40px;
}

.closeblock {
    height: 40px
}

.statescontainer {
  margin-top: 30px;
  z-index: 8998;

}

.introtext {
  color: white;
  font-size: 30px;
  transform: translateX(200%);
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px
}

.flyin1 {
  animation: flyin 0.5s forwards;
  }

.flyin2 {
  animation: flyin 0.5s forwards;
  animation-delay: 0.7s;
}

.flyin3 {
  animation: flyin 0.5s forwards;
  animation-delay: 0.9s;
}

.flyin4 {
  animation: flyin 0.5s forwards;
  animation-delay: 2s;
}

  /* fly in animation */
  @keyframes flyin {
    0%   { 
    transform: translateX(200%); 		
    }
    100% { 
    transform: translateX(0%); 
    }
  }


.logochuckling {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 20px;
  height: 100px;
  width: 100px;
  animation: rotation 0.8s infinite linear;
}

@keyframes rotation {
  25% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }

}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  height: 100px;
  width: 100px

}

.pulsate {
  transform: scale(1);
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.90);
	}
}




  .profilepicture {
    margin-bottom: 20px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px

  }

    .description {
    margin-bottom: 20px;
    margin-top: 30px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px

  }

  ::v-deep .socialicon {
     filter: brightness(100)
    }

  .nextbutton {
    max-width: 400px;
    height: 60px;
    border-radius: 30px;
    width: 90vw;
    margin-bottom: 30px;
    background-color: #ffffff;
    color: #e9436d;
    padding: 0.5rem;
    font: inherit;
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .buttonfont {
  font-weight: bold;
  font-size: 16px
  }

</style>


