<template>
  <div id="friends_dialog" class="friends_dialog">

      <div class="header">
        <button @click="openPopup(12)" class="reportbutton">
          <img class="reportIcon" src="../assets/report.svg" alt="">
         </button>
      </div>
   
        <div id="gallery_container" class="gallery_container">


          <div id="imgselector" class="imgselector">
            <div id="scroll_gallery" v-for="(item, index) in selectedGallery" :key='index' >
              <img class="swipe_image" @load="imgagesLoaded()" :src="item.imgurl" :id="'galleryImg' + index">
            </div>
          </div>

          <div class="swipebuttoncontainer">
            <button v-if="selectedGallery.length > 1 && allImagesLoaded" id="nextImg" class="swipeRight" @click="selectViewStateUp()">
              <img v-if="viewState+1 === selectedGallery.length" class="closeblocksmall" src="../assets/startImg.svg" alt="">
              <img v-else class="closeblock" src="../assets/swipeRight.svg" alt="">
            </button>

            <div v-else-if="selectedGallery.length > 1" class="loader">{{this.language.hidelikecard.load}}</div>
           </div>
        </div>
        
        <div class="friend_container">
          <div v-if="likeslistener.includes(selectedUser.id) && likes.includes(selectedUser.id) || !firstLikeDone" class="infoprompt">
            <div v-if="likeslistener.includes(selectedUser.id) && likes.includes(selectedUser.id)">
              <div class="matchinfo"> {{this.language.hidelikecard.youLikeEachOther}} <br>
                {{this.language.hidelikecard.goForChat}}</div>
            </div>
            <div v-else-if="!firstLikeDone">
             <div class="hidelikeinfo">{{this.language.hidelikecard.giveHidelike}} <img class="iconinline" src="../assets/hidelike-grey.svg" alt=""><br>
              {{this.language.hidelikecard.nobodyCanSee}} </div> 
            </div>
          </div>
        </div>
      
        <div class="friends_dialogflex">
          <div class="friends_toolbar">

            <div class="friends_buttons">
              <button @click="close()" class="hidelike_default"><img class="iconsmall" src="../assets/back.svg" alt=""></button>
            </div>

            <div class="friends_buttons">
              <button v-if="likeslistener.includes(selectedUser.id) && likes.includes(selectedUser.id)" @click="DeleteLikesToArray(selectedUser.id)" class="match"><img class="iconmatch" src="../assets/heart-smile.svg"></button>
              <button v-else-if="likes.includes(selectedUser.id) && likeslistener.includes(selectedUser.id) == false" @click="DeleteLikesToArray(selectedUser.id)" class="hidelike"><img class="icon" src="../assets/hidelike.svg"></button>
              <button v-else @click="SaveLikesToArray(selectedUser)" class="hidelike_default"><img class="icon" src="../assets/hidelike.svg"></button>
            </div>

            <div class="friends_buttons">
              <button v-if="likeslistener.includes(selectedUser.id) && likes.includes(selectedUser.id)" class="hidelike" @click="CreateChatroom(selectedUser)"><img class="iconmedium"  src="../assets/contacts-white.svg"></button>
              <button v-else class="hidelike_default" @click="openPopup(10)"><img class="iconmedium"  src="../assets/contacts.svg"></button>
            </div>

          </div>
        </div>
        
        <div class="infocontainer">
          <div class="descriptionbox"> 
            {{selectedProfile.description}}
          </div>
    
          <div class="socialbox">
            <div v-if="selectedProfile.gender != null || selectedProfile.age != null" class="socialprofile">
              <div v-if="selectedProfile.gender === 'female'" class="socialtext"><img class="socialimage" src="../assets/female.svg"></div>
              <div v-if="selectedProfile.gender === 'male'" class="socialtext"><img class="socialimage" src="../assets/male.svg"></div>
              <div v-if="selectedProfile.gender === 'x'" class="socialtext"><img class="socialimage" src="../assets/xgender.svg"></div>
              <div v-if="selectedProfile.age != null" class="socialtext">{{selectedProfile.age}}</div>
            </div>
            <div>
              <button v-if="selectedProfile.instagram != null && selectedProfile.instagram != ''" @click="openPopup(14, instaRoute+selectedProfile.instagram)" class="socialbutton">
                <img class="socialimage" src="../assets/instagram.svg">
              </button>
            </div>
            <a v-if="selectedProfile.snapchat != null && selectedProfile.snapchat != ''" @click="openPopup(15, snapRoute+selectedProfile.snapchat)" class="socialbutton">
              <img class="socialimage" src="../assets/snapchat.svg">
            </a>
          </div>
    

        </div>  
  </div>
</template>


<script>
import firebase from "firebase";
import { store, mutations } from '@/components/store'

export default {

props: [
      'selectedUser',
      'selectedImage',
      'selectedGallery',
      'selectedGalleryFull',
      'selectedDescription',
      'selectedProfile',
      'likeslistener'
    ],

  data() {
    return {
      user: firebase.auth().currentUser,
      viewState: 0, //index of displayed image (triggered by the next-button)
      loadedImages: 0, //counter for all images which are loaded to cache
      allImagesLoaded: false, //triggers the "load..."-sign at DOM
      instaRoute: "http://www.instagram.com/",
      snapRoute: "http://www.snapchat.com/add/"
    }
  },

  computed: {
    profileImage() {return store.profileImage},
    likesStorage() {return store.likesStorage},
    likes() {return store.likes},
    chatswith() {return store.chatswith},
    firstLikeDone() {return store.firstLikeDone},
    pushAvailable() {return store.pushAvailable},
    newbiestate() {return store.newbiestate},
    installEvent() {return store.installEvent},
    chatsOrder() {return store.chatsOrder},
    language() {return store.language},
  },

  methods: {

      imgagesLoaded () {
        //triggered by the loaded-event in the v-for list
        this.loadedImages++
        if (this.loadedImages > 1) {
          //trigger the loader to disappear
          this.allImagesLoaded = true
          // reset the loaded images counter
          this.loadedImages = 0
        }
      },

      selectViewStateUp () {

        // programmatically scroll to selected image by viewState

        // scroll to the right
        if (this.viewState+1 < this.selectedGallery.length) {
          let scroller = document.getElementById('galleryImg' + (this.viewState+1))
          scroller.scrollIntoView(false)
          this.viewState++
        // scroll back to start when end is reached
        } else {
          let scroller = document.getElementById('galleryImg0')
          scroller.scrollIntoView(false)
          this.viewState = 0
        }
      },

      close() {
        //reset all trigger-variables of this child
        this.viewState = 0
        this.allImagesLoaded = false
        this.loadedImages = 0

        //this is a work-around which is required as iOS Safari does
        // not support the smooth-opion for scrolling. Therefore the
        // scroll-behaviour has to be set to auto to reset the scroll
        // and after closing resetted to "smooth"
        // ...so far no other solution in sight :(
        let elmnt = document.getElementById('imgselector')
        elmnt.style.scrollBehavior = "auto";
        elmnt.scrollLeft = 0
    
        this.$emit('close')

        elmnt.style.scrollBehavior = "smooth";

      },

      openPopup(no, routing) {
        this.$emit('openPopup', no, routing)
      },

      SaveLikesToArray() {

        if (this.selectedUser.id == this.user.uid) {
          this.openPopup("You are right: LIKE YOURSELF! 🥰")
        } else {

        if (this.profileImage != 0) {

          const wine = this.selectedUser.id

          mutations.pushLikes(wine)

          const db = firebase.firestore();

          db.collection("users")
          .doc(this.user.uid)
          .update({ likes: firebase.firestore.FieldValue.arrayUnion(wine), likesStorage: firebase.firestore.FieldValue.arrayUnion(wine)})


          /// check if the liked user has liked this user already
          if (this.likeslistener.includes(wine) && !this.chatswith.includes(wine)) {   

            // if this user has been liked by the other user already the other User ID is pushed
            // to the this.chatswith (to avoid another adding by the if-then-check above)
            mutations.pushChatswith(wine)

            // generate channelid by combining the two involved userids whereby the smaller value of user id is placed first
            const channelid = this.user.uid > wine ? wine + this.user.uid : this.user.uid + wine

            //open chatroom under generated channelid
            const thisUser = [this.user.uid]
            const otherUser = [wine]

            db.collection("chatrooms")
            .doc(channelid)
            .set({
              users: [this.user.uid, wine],
              [thisUser]: true,
              [otherUser]: true
            })
            .then(() => {
              // send a message to this user to inform about the match which might haas
              // happened without being recognized so far. The message is prompted as
              // special intro for the chat conversation (without v-card border format, see chat page)
              db.collection("chatrooms").doc(channelid).collection("messages")
              .doc()
              .set({
                text: "new match...", 
                init: true, 
                sender: "hidelike", 
                time: firebase.firestore.FieldValue.serverTimestamp()
            })

            // trigger the unread to which the other user is listening to
            db.collection("unreads")
            .doc(wine)
            .update({ 
              unreadfrom: firebase.firestore.FieldValue.arrayUnion(this.user.uid)})
            })

            // update order of friends list
            if (this.chatsOrder.includes(wine)) {
              // copy chatswith array to reset chatsorder for friendslist
              const newChatOrder = [...this.chatsOrder]

              // take out other userschat id an re-insert on top of array
              // take out other userchat
              const index = newChatOrder.indexOf(wine);
              if (index > -1) {
              newChatOrder.splice(index, 1); // 2nd parameter means remove one item only
              }
              // re-insert
              newChatOrder.unshift(wine)

              // update internal state of chatorder
              mutations.setChatsOrder(newChatOrder)
            } else {
              const newChatOrder = [...this.chatsOrder]
              newChatOrder.unshift(wine)
              mutations.setChatsOrder(newChatOrder)
            }

            //save channelid and other users' ID unter this users profile
            //note that the chatswith-value is loaded under getUserData on mounting this page
            db.collection("users")
            .doc(this.user.uid)
            .update({ 
              chats: firebase.firestore.FieldValue.arrayUnion(channelid),
              chatswith: firebase.firestore.FieldValue.arrayUnion(wine),
              chatsOrder: this.chatsOrder
            })

            // trigger cloud function to send an email to the other user (wine) to inform about the match
            // email is sent via sendingblue and cloud function is configured in firebase extensions
            db.collection("mail")
            .add({toUids: [wine], message: {subject: "match on hidelike", text:"you have a new match on www.hidelike.com"}})

            // trigger cloud function to send a push notification to the other user (wine) to inform about the match
            db.collection("notifications")
            .add({
              title: "match on hidelike.com", 
              text: "you have a new match on hidelike.com", 
              clickaction: "https://www.hidelike.com",
              icon: "https://www.hidelike.com/logo.png",
              userid: wine,
              type: 2 // 1 = Like, 2 = Match, 3 = Message
            })
            // update likesStorage in actual session to prevent double notification because of the like itself (see below)
            mutations.pushLikesStorage(wine)
          }

          //update trigger for field which the recipient is listening to
          db.collection("likes")
          .doc(wine)
          .update({ likedby: firebase.firestore.FieldValue.arrayUnion(this.user.uid)})

          // check whether this is the first like to prompt the soft-opt-in for push notifications
          if (!this.firstLikeDone) {

            // triggers the soft-push-opt-in dialog if push is available
            if (this.pushAvailable) {

              setTimeout(() => {
                this.openPopup(1)
              }, 1000) 
            }

            // set the firstlikeDone Var to true to show in the actual session that the first like has been done
            mutations.setfirstLikeDone(true)

            // save to db that the first like has been done already
            const db = firebase.firestore()
            db.collection("users")
            .doc(this.user.uid)
            .update({firstLikeDone: true})

          }

          // checking if the liked user has already been liked somewhen in the past to prevent notification-spam by multiple
          // liking/disliking of the same user. The likesStorage is a one-way storage variable to save all likes ever done
          // by this user. The likesStorage is saved under the likes function above
          if (!this.likesStorage.includes(wine) || this.likesStorage == undefined) {
            
            // trigger cloud function to send a push notification to the other user (wine) to inform about the hidelike

            setTimeout(() => {
              if (this.likes.includes(wine)) {

                console.log("likes push sent")

                // update likesStorage in actual session
                mutations.pushLikesStorage(wine)

                db.collection("notifications")
                .add({
                  title: "new hidelike on hidelike.com", 
                  text: "give hidelikes to find out who!", 
                  clickaction: "https://www.hidelike.com",
                  icon: "https://www.hidelike.com/logo.png",
                  userid: wine,
                  type: 1 // 1 = Like, 2 = Match, 3 = Message
                })
              }
                    
            }, 5000) 
          }


          if (this.newbiestate && this.likes.length > 5 && this.installEvent != null) {
            this.openPopup(5)
            mutations.setNewbiestate(false)
            
          } else if (this.newbiestate && this.likes.length > 5) {
            this.openPopup(6)
            mutations.setNewbiestate(false)
          }

        } else {
          this.openPopup(3)
        }
      }
  
    },

    DeleteLikesToArray(wine) {

      if (!this.chatswith.includes(wine)) { 

        mutations.deleteLikes(wine)

        const db = firebase.firestore();
        db.collection("users")
        .doc(this.user.uid)
        .update({ likes: firebase.firestore.FieldValue.arrayRemove(wine)})

        db.collection("likes")
        .doc(wine)
        .update({ likedby: firebase.firestore.FieldValue.arrayRemove(this.user.uid)})
      }
    },

    CreateChatroom(wine) {
     
      /// generate channelid by combining the two involved userids whereby the smaller value of user id is placed first
      const channelid = this.user.uid > wine.id ? wine.id + this.user.uid : this.user.uid + wine.id

      if (!this.chatswith.includes(wine)) {
        //open chatroom under generated channelid
        const db = firebase.firestore()
        db.collection("chatrooms")
        .doc(channelid)
        .set({ 
          users: [this.user.uid, wine.id],
          [this.user.uid]: true,
          [wine.id]: true,
        })

        // save chat information in corresponding fields at this users' profile
        db.collection("users")
        .doc(this.user.uid)
        .update({ 
          chats: firebase.firestore.FieldValue.arrayUnion(channelid),
          chatswith: firebase.firestore.FieldValue.arrayUnion(wine.id),
        })
      }


      this.$router.push(
        {
            name: "chat",
            params: {
              selectedUser: wine.id,
              selectedGallery: this.selectedGallery,
              selectedProfile: this.selectedProfile,
              selectedDescription: wine.description
            }
        }
      )
    },

  }
}

</script>

<style scoped>

.friends_dialog {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0px;
  z-index: 8998; /* Specify a stack order in case you're using a different order for other elements */
  background-color: #f3f1f2;
}

.header {
  display: flex;
  justify-content: flex-end;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-right: 15px;
  margin-bottom: -55px;
  max-width: 600px;
}

.reportbutton {
  margin-left: auto;
  position: fixed;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #7a7a7a83;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  z-index: 8999;
}

.reportIcon {
    height: 30px;
    margin: auto;
}

.gallery_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -25px;
  max-width: 600px;
}



.imgselector {
  justify-content: flex-start;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  align-items: flex-end;
  bottom: 0;
  display: flex;   
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: auto;
  margin-right: auto;

}

.imgselector::-webkit-scrollbar {
  display: none;
}

.swipe_image {
  max-height: 600px;
  height: 100vw;
  width: auto;
  scroll-snap-align: start
}


.swipebuttoncontainer {
  position: fixed;
  display: flex;
  margin-left: auto;
  padding-right: 15px;
  height: 40px;
  width: 75px;
  z-index: 8999;
}

.swipeRight {
  margin-left: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #00b7a883;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

}

.closeblock {
    height: 20px;
    margin: auto;
}

.closeblocksmall {
    height: 18px;
    margin: auto;
}

.loader {
  font-size: 15px;
  color: white;
  margin-left: auto;
  margin-right: 15px;
  position: fixed;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 60px;
  border-radius: 20px;
  background-color: #7a7a7a83;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  z-index: 8999;
}


.friend_container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: auto;
  background-color: #f3f1f2;
}

.infoprompt {
  display: flex;
  text-align: center;
  justify-content: center;
  max-width: 300px;
  width: 80vw;
  position: fixed;
  padding: 7px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 60px;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.774);
  z-index: 8999;
}

.matchinfo {
  font-size: 16px;
  color: #f5416c;
}

.hidelikeinfo {
  font-size: 16px;
  color: #5F5F5F;
}

.iconinline {
 height: 18px;

}


.friends_dialogflex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: auto;
  
}

.friends_toolbar {
  display: flex;
  flex-direction: row;
  width: 100vw;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  max-width: 600px;
  background-color: #f3f1f2;
  justify-content: space-around;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 8999;
}

.friends_buttons {
    padding: 20px;
    font-size: 30px;
    color: white
}

  .hidelike_default {
  display: flex;
  border-radius: 35px;
  background-color: #d0d0cf;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .hidelike {
  display: flex;
  border-radius: 35px;
  background-color: #ffcc30;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .match{
  display: flex;
  border-radius: 35px;
  background-color: #f5416c;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .icon {
    height: 50px;
    width: 50px;
  }

  .iconmedium {
    height: 40px;
    width: 40px;
  }

  .iconsmall {
    height: 25px;
    width: 25px;
  }

    .iconmatch {
    height: 50px;
    width: 50px;
    animation: rotation 0.8s infinite linear;
  }

  @keyframes rotation {
  25% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }

}

.infocontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
}

.descriptionbox {
  display: flex;
  text-align: center;
  justify-content: center;
  justify-items: center;
  max-width: 500px;
  width: 90vw;
  padding: 7px;
  height: auto;
  max-height: 100px;
  min-height: 40px;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  z-index: 8999;
}

.socialbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  max-width: 500px;
  width: 90vw;
  margin-top: 10px;
  padding: 7px;
  height: auto;
  z-index: 8999;

}

.socialprofile {
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  align-items: center;
  color: #60605F;
  border-radius: 20px;
  height: 40px;
  width: auto;
  margin-right: 8px;
  margin-left: 8px;
  padding-left: 5px;
  padding-right: 5px
}

.socialtext {
  font-size: 18px;
  font-weight: bold;
  color: #60605F;
  margin-left: 3px;
  margin-right: 3px
}

.socialbutton {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  margin-left: 8px
}

.socialimage {
  height: 40px;
  width: 40px;
  margin: auto;
}






</style>

