<template>
  <v-app id="chat">

    <div class="background">

      <button class="chatpartner" @click="openGallery()">
        <img class="userimage" v-if="selectedGallery[0].imgurl != 0 && selectedGallery != undefined" :src= selectedGallery[0].imgurl>
        <img class="userimage" v-else src="../assets/profile-grey.svg">
      </button>
        
      <button class="exit" @click="firecounter()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button>

        <div v-show="!newUpload" class="chattable"> 
          <div class="topdiscance" id="top"></div>

          <button v-if="messagesCounter == this.limit" class="navbuttonsmall" @click="loadMoreMessages()">
            <img class="navicon" src="../assets/up.svg" alt="">
          </button>

          <div  v-for="(message, index) in messages" :key="index" :class="['d-flex flex-row align-center my-2']">

            <div class="mymessage" v-if="message.sender == user.uid">
              <button v-if="message.image.imgurl != null" class="myimagemessage" @click="zoomPic(message.image.imgurl)">
                <img v-if="message.image.imgurl != null" class="myimagemessage" @load="imageLoader()" :src="message.image.imgurl" alt="">
              </button>
              {{message.text}}
            </div>

            <div class="othermessage" v-else-if="message.init != true">
              <button v-if="message.image.imgurl != null" class="otherimagemessage" @click="zoomPic(message.image.imgurl)">
                <img v-if="message.image.imgurl != null" class="otherimagemessage" @load="imageLoader()" :src="message.image.imgurl" alt="">
              </button>
              {{message.text}}
            </div>
              
            <div class="initmessage" v-else-if="message.init">
              {{message.text}}
            </div>
          </div> 

          <div class="bottomdiscance" id="chatscreen"></div>
            
        </div>

        <div v-show="newUpload" class="previewscreen">
          <img class="previewimage" :src="previewPicture" alt="">
        </div>

        
        <div class="bottombar" v-if="blocktrigger != true">

          <button class="upload" @click="openFileUpload()">
            <img v-if="this.uploaded" class="uploadicon" src="../assets/cameragreen.svg" alt="">     
            <div v-else-if="this.processingImage" class="uploadicon"><div class="uploadvalue">{{this.uploadValue+'%'}}</div></div> 
            <img v-else class="uploadicon" src="../assets/camera.svg" alt="">
          </button>

          <div class="text">
            <textarea @click="delayedAutoScroll()" class="textarea" name="text" id="text" v-bind:placeholder="this.language.chat.typeMessageHere" v-on:keyup="resize()" v-model="msg" @keypress.enter.prevent="sendmessage"></textarea>
          </div>

          <div class="sendbutton">
            <button v-if="this.processingImage" class="sendinactive" @click="sendmessage"><img class="sendicon" src="../assets/send.svg" alt=""></button>
            <button v-else class="send" @click="sendmessage"><img class="sendicon" src="../assets/send.svg" alt=""></button>
          </div>
        </div>

      <!-- show other users' profiles-->
      <gallery id="gallery" class="gallery"
        :selectedUser="selectedUser"
        :selectedGallery="selectedGallery"
        :selectedDescription="selectedDescription"
        :selectedProfile="selectedProfile"
        @close="closeGallery()"
      >
      </gallery>

      <!-- component to show images fullscreen-->
      <button v-if="zoom" id="zoomimage" class="zoomimage" @click="zoomPic()">
        <img class="imagebig" :src="zoomImage">
      </button>
      
       <!-- hidden file input-->
      <input id="file" type="file" accept="image/*" @change="previewGallery" style="display:none"> 

    </div>
  </v-app>
</template>

<script>
import firebase from 'firebase';
import gallery from '@/components/gallery'
import loadImage from 'blueimp-load-image'
import { store, mutations } from '@/components/store'

export default {

  props: [
    'selectedUser',
    'selectedGallery',
    'selectedDescription',
    'selectedProfile'
  ],
  
  name: 'chat',

  components: {
    gallery,
  },

  data(){	 

    return {
      user: firebase.auth().currentUser,
      chatid: 0,
      channelid: '',
      lastKey: '',
      messagesCounter: 0,
      msg: null,
      messages: [],
      unsubscribe: '',
      limit: 10,
      arrayalert: '', // limit of chat messages displayed on first page open
      blocktrigger: false,
      zoom: false,  //trigger to view images fullscreen
      zoomImage: null, //src for preview screen
      uploadValue: 0, //...what could it be??? ;)
      picture: null, // store to save donwload token
      previewPicture: null, // src for preview picture (as local image url)
      newUpload: false, // triggers the preview screen to appear or disappear
      timestamp: 0, // to store timestamp to be save as an image property
      processingImage: false, // trigger to indicate that image is processing
      uploaded: false, //trigger to confirm that donwload Token is available
      loadMore: false //trigger to bypass the automatic scroll-down
  
    }
  },

  computed: {   
    language() {return store.language},
    chatswith() {return store.chatswith},
    chatsOrder() {return store.chatsOrder},
    messageLength: function () {return this.messages.length - 1},
    blocked: function () {if (this.messages[this.messageLength].block && this.messages[this.messageLength].sender != this.user.uid) {return true} else {return false}},

  },

  mounted() {

    const db = firebase.firestore()
    this.channelid = this.user.uid > this.selectedUser ? this.selectedUser + this.user.uid : this.user.uid + this.selectedUser
     
    // Start listening to the query.
    this.unsubscribe = db.collection("chatrooms").doc(this.channelid).collection("messages").orderBy('time', 'desc').limit(this.limit).onSnapshot(
      querySnapshot => {
        querySnapshot.docChanges().forEach(
          change => {
            if (change.type === "added") {
              const data = {
                'time': change.doc.data().time, 
                'sender': change.doc.data().sender,
                'text': change.doc.data().text,
                'block': change.doc.data().block,
                'init': change.doc.data().init,
                'image': change.doc.data().image
              }

              if (data.image == undefined) {
                data.image = {imgurl: null, uid: 0}
              }

              // check if arrayalert (= messages.lenght after forEach completed) contains some elements
                    
              if (this.arrayalert > 0) {
                    
                // if first run of firestore listener has already happened arrayalert is > 0 which means
                // any further element has to added at the end of the messages array
                this.messages.push(data)
                    
                    
              } else {

                // if this is the first run of firestore listener the arrayalert is 0 which means
                // the array from firestore has to be unshifted to meet time-descending from bottom
                this.messages.unshift(data)

                this.lastKey = change.doc.data().time
                this.messagesCounter = this.messages.length
                    
              }
            }  
          }
        )

        // setting arrayalert after forEach-Loop is finished (arrayalert is needed to distinct between
        // first run of firestore listener and further onChange Addings.
        this.arrayalert = this.messages.length

        // scrolling down on initializing chat
        this.$nextTick(
          () => { 
            let elmnt = document.getElementById('chatscreen');
            elmnt.scrollIntoView(false);
          }
        )
      }
    )
  },

  watch: {
    // the autoscroll method is watched to keep scroll to bottom
    messages() {
     this.autoscroll()
     this.blocktrigger = this.blocked
    }
  },

  methods: {

    delayedAutoScroll() {
      setTimeout(() => {
        this.autoscroll()
      }, 300) 
    },

    zoomPic (pic) {
      this.zoomImage = pic
      if (this.zoom) {this.zoom = false} else {
          this.zoom = true
      }
    },
    openFileUpload() {
      const file = document.getElementById("file")
      file.click()
    },

    previewGallery(event) {
      //reset upload trigger
      //this.newUpload = true
      this.uploadValue = 0
      this.uploaded = false

      //prozessing Image trigger (getting download URL)
      this.processingImage = true

      // reset local storage for donwload url
      this.picture = null

      // get timestamp to be processed for db and store reference
      const freezeTimeStamp = Date.now().toString()
      this.timestamp = freezeTimeStamp

      const input = event.target.files[0];

      loadImage(input, { canvas: true, orientaton: true})
      .then(
        (data) => {   
          return new Promise(
            function (resolve) {
              data.image.toBlob(
                function (blob) {
                  data.blob = blob
                  resolve(data)
                }, 'image/jpeg', 0.3 // image compression
              )
            }
          )
        }
      )
      .then(
        (data) => { 
          //update local state with image blob to immediately be displayed to the user
          this.previewPicture = URL.createObjectURL(data.blob)
          this.newUpload = true

          return new Promise(
            (resolve, reject) => {

                // save new image to firebase storage
                const storageRef = firebase
                .storage()
                .ref('chats/' + this.channelid + '/' + freezeTimeStamp +'.jpg')
                .put(data.blob)

                // get download URL to be save in DB
                storageRef
                .on(
                  `state_changed`,snapshot=>{
                    this.uploadValue = Math.floor((snapshot.bytesTransferred/snapshot.totalBytes)*100)
                    
                    }, error => {
                      console.log(error.message)
                    },
                    
                  async () => {
                    
                    const imgURL = await storageRef.snapshot.ref
                    .getDownloadURL()
                    this.picture = imgURL
                    this.processingImage = false
                    this.uploaded = true
            
          
                    resolve()

                  }
                )
            } 
          )
        }
      )    
    },

    loadMoreMessages () {

      this.messagesCounter = 0

      // trigger to bypass the auto bottom scrolling on images loading
      this.loadMore = true

      const db = firebase.firestore()

      db.collection("chatrooms").doc(this.channelid).collection("messages").orderBy('time', 'desc').startAfter(this.lastKey).limit(this.limit).get()
      .then((querySnapshot) => {
          querySnapshot.forEach((change) => {

              const data = {
                'time': change.data().time, 
                'sender': change.data().sender,
                'text': change.data().text,
                'block': change.data().block,
                'init': change.data().init,
                'image': change.data().image
              }


                if (data.image == undefined) {data.image = {imgurl: null, uid: 0}}
                

                this.lastKey = change.data().time
                  
                // if this is the first run of firestore listener the arrayalert is 0 which means
                // the array from firestore has to be unshifted to meet time-descending from bottom
                this.messages.unshift(data)
                    
                this.messagesCounter++
          }
        )        
      })
    },

    openGallery () {

        if (this.selectedGallery != undefined && this.selectedGallery[0].imgurl != 0) {
          document.getElementById("gallery").style.display = "block";
        }

    },

    closeGallery () {
      document.getElementById("gallery").style.display = "none";

    },

    resize () {

      const textArea = document.getElementById("text")

      textArea.style.height = "auto"
      textArea.style.height = textArea.scrollHeight + "px"

    },

    sendmessage() {

      const db = firebase.firestore();

      // save new message (incl. userID and timestamp)
      db.collection("chatrooms").doc(this.channelid).collection("messages")
      .doc()
      .set({
        text: this.msg, 
        sender: this.user.uid, 
        time: firebase.firestore.FieldValue.serverTimestamp(), 
        block: false,
        image: {imgurl: this.picture, uid: this.timestamp}
      })

      this.msg = "" // empty textarea
      this.newUpload = false // reset preview screen
      this.picture = null
      this.timestamp = 0
      this.uploadValue = 0
      this.previewPicture = null
      this.newUpload = false
      this.processingImage = false
      this.uploaded = false

      document.getElementById("text").style.height = "auto"



      // update lastsent field in collection "messagecount" and handover channelid to method gettimestamp() below
      //db.collection("chatrooms").doc(channelid).collection("messagecount")
     // .doc(this.user.uid)
     // .set({lastsent: firebase.firestore.FieldValue.serverTimestamp()},{ merge: true })
     



      //update trigger for field which the recipient is listening to
      db.collection("unreads")
      .doc(this.selectedUser)
      .update({ unreadfrom: firebase.firestore.FieldValue.arrayUnion(this.user.uid)})

      // send push notificaton
      // trigger cloud function to send a push notification to the other user (wine) to inform about the match
      db.collection("notifications")
      .add({
        title: "message on hidelike.com", 
        text: "new message on hidelike.com", 
        clickaction: "https://www.hidelike.com",
        icon: "https://www.hidelike.com/logo.png",
        userid: this.selectedUser,
        type: 3 // 1 = Like, 2 = Match, 3 = Message
      })
   

    },

    firecounter() {

      //the X-Button performs two functions -depending on screen state:
      //1. if no pic is uploadid the button quits the chat to return to home
      //2. if a pic is loaded, the button exits the preview screen

      if (!this.newUpload) {
      //const channelid = this.user.uid > this.selectedUser ? this.selectedUser + this.user.uid : this.user.uid + this.selectedUser
      const db = firebase.firestore();

      // detach listener
      if(this.unsubscribe) {this.unsubscribe()}

      // remove any unreads on this chat
      db.collection("unreads")
      .doc(this.user.uid)
      .update({ unreadfrom: firebase.firestore.FieldValue.arrayRemove(this.selectedUser)})
      
      // set new order of friendslist
      if (this.chatsOrder.includes(this.selectedUser)) {
          
        // copy chatswith array to reset chatsorder for friendslist
        const newChatOrder = [...this.chatsOrder]

        // take out other userschat id an re-insert on top of array
        // take out other userchat
        const index = newChatOrder.indexOf(this.selectedUser);
        if (index > -1) {
          newChatOrder.splice(index, 1); // 2nd parameter means remove one item only
        }

        // re-insert
        newChatOrder.unshift(this.selectedUser)

        // update internal state of chatorder
        mutations.setChatsOrder(newChatOrder)

        // update db with chatorder
        db.collection("users")
        .doc(this.user.uid)
        .update({ chatsOrder: newChatOrder})

        } else {

          // copy chatswith array to reset chatsorder for friendslist
          const newChatOrder = [...this.chatsOrder]

          // re-insert
          newChatOrder.unshift(this.selectedUser)

          // update internal state of chatorder
          mutations.setChatsOrder(newChatOrder)

          // update db with chatorder
          db.collection("users")
          .doc(this.user.uid)
          .update({ chatsOrder: newChatOrder})

        }
        // navigate back to home
        this.$router.push(
          {
            name: "Driver",
      
          }
        )

      } else {
        //as image upload was cancelled all triggers are set to default
        this.newUpload = false
        this.processingImage = false
        this.uploadValue = 0
        this.uploaded = false
      }
    },

    imageLoader () {
      
    if (!this.loadMore) {
      this.autoscroll()
    } else {
        this.$nextTick(
          () => { 
            let elmnt = document.getElementById('top');
            elmnt.scrollIntoView(false);
          }
        )

        setTimeout(() => {
        this.loadMore = false
      }, 2000) 
    }
    


    },

    autoscroll() {
    /// main autoscroll function to align messages to bottom
      let elmnt = document.getElementById('chatscreen');
      elmnt.scrollIntoView(false);
    }
  }
}

</script>

<style scoped>

  .background {
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: -webkit-center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f3f1f2;
  }

  .chatpartner {
    position: fixed;
    left: 0;
    margin-top: 15px;
    margin-left: 15px;
    width: 70px;
    height: 70px;
    z-index: 8999;
  }

  .userimage {
    background-color: #f3f1f2;
    margin-right: 5px;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    z-index: 8999;
  }

  .exit {
    position: fixed;
    background-color: #ffffff00;
    right: 0;
    margin-top: 15px;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    color: rgb(78, 78, 78);
    z-index: 8999;
  }

  .closeblock {
    height: 50px
  }

  .chattable {
    padding-left: 10vw;
    padding-right: 10vw;
    position:relative;
    overflow-y: scroll;
    width: 100%;
  }

  .topdiscance {
    height: 100px
  }

  .navbuttonsmall {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 30px;
    background-color: #00b7a9;
  }

  .navicon {
    height: 20px;
    margin: auto;
  }

  .mymessage {
    word-break: break-word;
    margin-top: 10px;
    margin-left: 20vw;
    padding: 10px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: rgb(0, 0, 0);
    background-color: #ecebee;
    font-size: 16px;
    text-align: left;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .myimagemessage {
    display: block;
    width: 200px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .othermessage {
    word-break: break-word;
    margin-top: 10px;
    padding: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: #000000;
    background-color: white;
    font-size: 16px;
    text-align: left;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .otherimagemessage {
    display: block;
    width: 200px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .initmessage {
    margin-top: 10px;
  }

  .bottomdiscance {
    height: 100px
  }

  .previewscreen {
    height: 100vh;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .previewimage {
    height: 100vh;
  }

  .bottombar {
    background-color: #ffffff00;
    display: grid;
    grid-template-columns: 1fr 0px 1fr;
    align-items: center;
    overflow: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 100px;
  }

  .upload {
    text-align: -webkit-center;
    position: absolute;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 25px;
    background-color: #c9c7c7;
    width: 50px;
    height: 50px;
    z-index: 9900;
  }

  .uploadicon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    font-size: 12px;
    z-index: 9901;
  }

  .uploadvalue {
    font-size: 12px;
    z-index: 9901;
  }

  .text{
    display: flex;
    z-index: 8999;
  }

  .textarea {
    overflow: hidden;
    border: 1px solid rgb(255, 255, 255);
    width: 93vw;
    padding-right: 70px;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 35px;
    padding-left: 65px;
    padding-top: 10px;
    padding-bottom: 10px;
    font: inherit;
    outline: none;
      -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .sendbutton {
    z-index: 9900;
  }

  .sendinactive {
    display: flex;
    margin-left: -70px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: #f1f1f1;
    width: 50px;
    height: 50px;
  }

  .send {
    display: flex;
    margin-left: -70px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: #00b7a9;
    width: 50px;
    height: 50px;
  }

  .sendicon {
    height: 30px;
  }

  .gallery {
    display: none;
    z-index: 9900;
  }

  .zoomimage {
    display: block;
    position: fixed;
    justify-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.685); /* Black background with opacity */
    z-index: 9998; 
    overflow-y: scroll;
  }

  .imagebig {
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    height: auto;
    max-width: 600px
  }

</style>








