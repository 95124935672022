// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router';
import { routes } from './router'
import './registerServiceWorker'

//
import firebase from 'firebase';
import 'firebase/firestore'
import 'firebase/auth'
import { store, mutations } from '@/components/store'


export const firebaseConfig = {
  apiKey: "AIzaSyDT3z7yYmlZsXtGYwbVcBn9_w36x2Cuz0A",
  authDomain: "hidelikecom.firebaseapp.com",
  projectId: "hidelikecom",
  storageBucket: "hidelikecom.appspot.com",
  messagingSenderId: "884197097527",
  appId: "1:884197097527:web:934c2fed16e01b8b663fb2",
  measurementId: "G-T6R3W3YJ4T"
};

//

const firebaseAPP = firebase.initializeApp(firebaseConfig)
export default firebaseAPP.firestore()

import vuetify from '@/plugins/vuetify';



let app = '';

Vue.config.productionTip = false

Vue.use(VueRouter);


const router = new VueRouter({
	mode: 'history',
	routes
	
});

router.beforeEach((to, from, next) => {

    /// get state uf URL to check params
    /// don't forget to import store on top 
    let params = new URLSearchParams(document.location.search);
    let paramId = params.get("id")
    if (paramId != null) {
      mutations.setUrlState(paramId)
    } 
    ///
	
	const currentUser = firebase.auth().currentUser;
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	if(requiresAuth && !currentUser) next('Login');
  else if(!requiresAuth && currentUser) next('Driver');
	else next();
})

firebase.auth().onAuthStateChanged(() => {
	if(!app){
		app = new Vue({
            router,
            vuetify,

            render: h => h(App)
        }).$mount('#app');
	}
});

