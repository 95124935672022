<template>
  <div id="intro" class="background">
    <button class="exit" @click="goBack()" type="button"><img class="closeblock" src="../assets/back-grey.svg" alt=""></button><br>
    <div class="statescontainer">
          
      <!-- welcome messagem:src="userimage" -->
      <div v-if="introstate == 1">
        <button @click="next()">
          <img v-if="profileImage == 0" class="logo" src="../assets/profile-grey.svg">
          <img v-else class="profilepic" :src="profileImage">
        </button><br>

        <button @click="next()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.profilePictures}}</div>
        </button><br>
        <button @click="goDescription()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.aboutYou}}</div>
        </button><br>
        <button @click="goSettings()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.settings}}</div>
        </button><br>
        <button @click="openPopup(16)" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.yourHidelikeAddress}}</div>
        </button><br>
        <button @click="showFaq()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.faq}}</div>
        </button><br>
        <button @click="openPopup(13)" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.deleteMyAccount}}</div>
        </button><br>
        <button @click="signOut()" class="nextbutton"  type="button">
            <div class="buttonfont">{{this.language.profile.logout}}</div>
        </button>
      </div>

      <!-- choose whether to set a profile pic or to create an avatar -->
      <div v-if="introstate == 2">
        <div class="introtext">{{this.language.profilepicture.intro}}</div>
        <img class="profilepic" src="../assets/profile-grey.svg">
        <button @click="goPic()" class="nextbutton"  type="button">
          <div class="buttonfont">{{this.language.profilepicture.picturesButton}}</div>
          </button><br>
        <button @click="goAvatar()" class="nextbutton"  type="button">
          <div class="buttonfont">{{this.language.profilepicture.avatarButton}}</div>
        </button>
      </div>

      <!-- set profile pic -->
      <div v-if="introstate == 3">
        <div class="introtext">{{this.language.gallery.intro}}</div>
        <profilepicture
          class="profilepicture" 
        >
        </profilepicture>
      </div>
      
      <!-- create avatar -->
      <div v-if="introstate == 4">
        <div class="introtext">{{this.language.avatarcreator.createYourAvatar}}</div>
        <avatarcreator
          ref="avatarComponent"
          class="profilepicture" 
        >
        </avatarcreator>
        <button @click="saveAvatar(); goBack()" class="nextbutton" type="button">
          <div class="buttonfont">{{this.language.avatarcreator.save}}</div>
        </button>
      </div>

      <!-- set description -->
      <div v-if="introstate == 5">
        <description
          ref="descriptionComponent"
          class="description" 
        >
        </description>
        <button @click="saveDescription(); goBack()" class="nextbutton" type="button">
            <div class="buttonfont">{{this.language.description.save}}</div>
        </button>
      </div>

       <!-- set settings -->
      <div v-if="introstate == 6">
        <div class="introtext">{{this.language.settings.settings}}</div>
        <settings
          ref="settingsComponent"
          class="settings"
        >
        </settings>

      </div>

    </div>

   <!-- generic popup component-->
    <popup 
      id="popup"
      :popupState="popupState" 
      class="popup"
      :user="user" 
      @openPopup="openPopup()"
      @deleteUser="deleteUser"
      @getAnalytics="getAnalytics"
    >
    </popup>


    <!-- dialog for faq -->
    <faq id="faq" class="faq_dialog"
      @showFaq="showFaq()"
    >
    </faq>


    
  </div> 
</template>


<script>
import firebase from "firebase";
import profilepicture from '@/components/profilepicture'
import avatarcreator from '@/components/avatarcreator'
import description from '@/components/description'
import settings from '@/components/settings'
import popup from '@/components/popup'
import faq from '@/components/faq'
import { store, mutations } from '@/components/store'


export default {
  name: "intro",
  
  components: {
    profilepicture,
    avatarcreator,
    description,
    settings,
    popup, 
    faq
  },

  computed: {
      language() {return store.language},
      profileImage() {return store.profileImage}
    },

  data() {
    return {
      user: firebase.auth().currentUser,
      introstate: 1,
      popupState: 0
    }

  },

  methods: {

      getAnalytics(key, value) {

        const db = firebase.firestore()
        db.collection("users")
        .doc(this.user.uid)
        .update({
          [`analytics.${key}`]: value
        })
      },

      goBack() {
        if (this.introstate == 1) {
          this.$router.replace({
            name: "Driver"
          })
        } else {
          this.introstate = 1
        }
      },

      next() {
        this.introstate = 2
      },

      goPic() {
        this.introstate = 3
      },

      goAvatar() {
        this.introstate = 4
      },

      goDescription () {
        this.introstate = 5
      },

      goSettings () {
        this.introstate = 6
      },

      goFirstScan () {
        this.introstate = 6
      },

      goRadarScreen() {
        this.$router.replace({
          name: "Driver",
        })
      },

      saveAvatar () {
        this.$refs.avatarComponent.saveAvatar()
      },

      saveDescription () {
        this.$refs.descriptionComponent.sendDescription()
      },

      openPopup (state) {
      
        const popupState = (document.getElementById("popup").style.display == "block")

        if (!popupState && state != undefined) {
          this.popupState = state
          document.getElementById("popup").style.display = "block"
        } else {
          document.getElementById("popup").style.display = "none"
          this.popupState = 0
        }
      },

      deleteUser (password) {

        const credential = firebase.auth.EmailAuthProvider.credential(
          this.user.email, // references the user's email address
          password
        )

        this.user.reauthenticateWithCredential(credential).then(() => {
          this.user.delete().then(() => {
            console.log("user deleted successfully!")
            this.$router.replace({
              name: "landingpage"
            })

          }).catch((error) => {
            console.log("user deletion failed")
            this.openPopup()
            this.openPopup(error.message)
          })
        
        }).catch((error) => {
          console.log("user re-authetication failed")
            this.openPopup()
            this.openPopup(error.message)
        })



      },


      showFaq () {
        const faqState = (document.getElementById("faq").style.display == "block")
        if (!faqState) {
          document.getElementById("faq").style.display = "block"
        } else {
          document.getElementById("faq").style.display = "none"
        }
      },

      signOut() {

        if (this.$route.params.notificationToken != undefined) {
          

          const db = firebase.firestore()
          db.collection("users")
          .doc(this.user.uid)
          .update({ notificationToken: firebase.firestore.FieldValue.arrayRemove(this.$route.params.deviceToken)})
        }
        

        firebase
        .auth()
        .signOut()
        .then(
          user => {
            this.$router.push(
              {
                name: "Login"
              }
            )
            this.$destroy()
            window.location.reload()
          }
        )
        .catch(
          error => {
            console.log(error.message);
          }
        )
    }

  }
}

</script>

<style scoped>


.background {
  padding-top: 30px;
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f1f2;
  overflow-y: scroll;
}


.exit {
  position: fixed;
  top: 0;
  left: 10px;
  padding: 10px;
  width: 50px;
  margin-right: auto;
  margin-left: 5px;
  margin-top: 10px;
  text-align: -webkit-left;
  float: left;
  color: white;
  font-size: 30px
}

.closeblock {
    height: 40px
}

.statescontainer {
margin-top: 30px

}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  height: 100px;
  width: 100px

}

.introtext {
  color: #5F5F5F;
  font-size: 30px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px
}


.profilepic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  height: 150px;
  width: 150px;
  border-radius: 75px

}

  .profilepicture {
    margin-bottom: 20px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px

  }

    .description {
    margin-bottom: 20px;
    margin-top: 30px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px

  }

    ::v-deep .label {

  color: rgb(0, 0, 0);
}

    ::v-deep .labelabove {

  color: rgb(0, 0, 0);
}

  ::v-deep .agefield {

  color: rgb(0, 0, 0);
}
  
  ::v-deep .textfield {

  color: rgb(0, 0, 0);
}

  ::v-deep .snapchat {

  color: rgb(0, 0, 0);
}

  ::v-deep .instagram {

  color: rgb(0, 0, 0);
}

  ::v-deep  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(143, 143, 143);
    opacity: 1; /* Firefox */
    font-size: 18px;
  }

    .settings {
    margin-bottom: 20px;
    margin-top: 30px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px
  }

    ::v-deep .select {

      color: #5F5F5F;
    }



.nextbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  margin-bottom: 30px;
  background-color: #ffffff;
  color: #e9436d;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonfont {
font-weight: bold;
font-size: 16px
}

.popup {
  display: none;
}

.faq_dialog {
  display: none; /* Hidden by default */
}


</style>


