<template>
    <div id="popup" class="popupContainer">

        <!-- pop up for soft opt-in for push notifications -->
        <div v-if="popupState == 1" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupTextheader">
                <div>{{this.language.popup.p1text}}</div>
            </div>
            <button class="popupconfirm" @click="PushOptIn()">{{this.language.popup.yes}}</button>  
        </div>

        <!-- pop up for no gps-alert -->
        <div v-else-if="popupState == 2" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupTextheader">
              {{this.language.popup.p2header}}
            </div>
            
            <div class="popupText">
              {{this.language.popup.p2text}}
            </div>
  
            <button class="popupconfirm" @click="closePopup()">{{this.language.popup.ok}}</button>  
        </div>

         <!-- pop up for no profile picture-->
        <div v-else-if="popupState == 3" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
              <div class="popupTextheader">
                {{this.language.popup.p3header}}
              </div>
              <div class="popupText">
                {{this.language.popup.p3text}}
              </div>
            <button @click="profileDialogOn()" class="hidelike"><img src="../assets/profile.svg"></button>
        </div>

        <!-- pop up to block user -->
        <div v-else-if="popupState == 4" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupText">
                <div>{{this.language.popup.p4header}}</div>
            </div>
            <button class="report" @click="blockChat()">{{this.language.popup.p4button}}</button>  
        </div>

        <!-- install PWA -->
        <div v-else-if="popupState == 5" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupTextheader">
                {{this.language.popup.p5header}}
              </div>
              <div class="popupText">
                {{this.language.popup.p5text}}
              </div>
     
            <button class="popupconfirm" @click="installPwa()">{{this.language.popup.p5button}}</button>  
        </div>

        <!-- add App to homescreen -->
        <div v-else-if="popupState == 6" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
                <div class="popupTextheader">{{this.language.popup.p6header}}</div>
                <div class="popupText">{{this.language.popup.p6text}}</div>
                <div class="popupText">{{this.language.popup.p6instruction1}}<img class="browsericons" src="../assets/addhome.png">{{this.language.popup.p6instruction2}}<img class="browsericons"  src="../assets/addhomechrome.png">{{this.language.popup.p6instruction3}}</div>
    
            
            <button class="popupconfirm" @click="installPwa()">{{this.language.popup.ok}}</button>  
        </div>


        <!-- info about updating -->
        <div v-else-if="popupState == 7" class = "popupCard">
            <div class="popupTextheader">
              {{this.language.popup.p7header}}
            </div>
        </div>

        <!-- info about updating -->
        <div v-else-if="popupState == 8" class = "popupCard">
          <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div v-if="likeslistener.length > 1" class="popupText">{{this.language.popup.p8text1}}{{likeslistener.length}}{{this.language.popup.p8text2}}</div>
            <div v-if="likeslistener.length == 1" class="popupText">{{this.language.popup.p8text3}}</div>
            <div v-if="likeslistener.length == 0" class="popupText">{{this.language.popup.p8text4}}</div>
            <div v-if="likeslistener.length > 0" class="popupText">{{this.language.popup.p8text5}}</div>
            
            
        </div>

        <!-- add App to homescreen -->
        <div v-else-if="popupState == 9" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
                <div class="popupTextheader">{{this.language.popup.p9header}}</div>
                <div class="popupText">{{this.language.popup.p9text}}</div>
      
            <button class="popupconfirm" @click="closePopup()">OK</button>  
        </div>

        <!-- add App to homescreen -->
        <div v-else-if="popupState == 10" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
                <div class="popupTextheader">{{this.language.popup.p10header}}</div>
                <div class="popupText">{{this.language.popup.p10text}}</div>
      
            <button class="popupconfirm" @click="closePopup()">{{this.language.popup.ok}}</button>  
        </div>

        <!-- add App to homescreen -->
        <div v-else-if="popupState == 11" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
              <div class="popupTextheader">{{this.language.popup.p11header}}</div>
              <div class="popupText">{{this.language.popup.p11text}}</div>
      
            <button class="popupconfirm" @click="closePopup()">{{this.language.popup.ok}}</button>  
        </div>

        <!-- "melden" -->
        <div v-else-if="popupState == 12" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
                <div class="popupTextheader">{{this.language.popup.p12header}}</div>
                <div class="popupText">{{this.language.popup.p12text}}</div>
      
            <button class="report" @click="reportUser()">{{this.language.popup.p12button}}</button>  
        </div>

        <!-- "sure to delete your account?" -->
        <div v-else-if="popupState == 13" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
                <div class="popupTextheader">{{this.language.popup.p13header}}</div>
                <div class="popupText">{{this.language.popup.p13text}}</div>
                <div>
                  <input class="field" v-model="password" id="current-password" name="current-password" type="password" placeholder="Password" autocomplete="current-password" aria-describedby="password-constraints" required><br>
                </div>
                
            <button class="report" @click="deleteUser(password)">{{this.language.popup.p13button}}</button>  
        </div>

        <!-- "match people on insta?" -->
        <div v-else-if="popupState == 14" class = "popupCard">
          <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
            <div class="popupTextheader">{{this.language.popup.p14header}}</div>
            <div class="popupText">{{this.language.popup.p14text}}</div>
   
            <div class="linkline">
              <div class="fieldlink">
                <div class="markedlink">www.hidelike.com/?id={{user.uid}}</div>
              </div>

              <div>
                <button @click="copyToClipboard('clipForInstagram', true)">
                  <img v-if="!clipboard" class="copy" src="../assets/copy.svg">
                  <img v-else class="copy" src="../assets/copied.svg">
                </button>
              </div>
            </div>

            <div>
              <a :href="routingState">
               <button class="popupconfirm">{{this.language.popup.p14button}}</button>  
              </a>
            </div>
            
        </div>

          <!-- "match people on snap?" -->
        <div v-else-if="popupState == 15" class = "popupCard">
          <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
            <div class="popupTextheader">{{this.language.popup.p15header}}</div>
            <div class="popupText">{{this.language.popup.p15text}}</div>
            
            <div class="linkline">
              <div class="fieldlink">
                <div class="markedlink">www.hidelike.com/?id={{user.uid}}</div>
              </div>

              <div>
                <button @click="copyToClipboard('clipForSnapchat', true)">
                  <img v-if="!clipboard" class="copy" src="../assets/copy.svg">
                  <img v-else class="copy" src="../assets/copied.svg">
                </button>
              </div>
            </div>

            <div>
              <a :href="routingState">
               <button class="popupconfirm">{{this.language.popup.p15button}}</button>  
              </a>
            </div>
            
        </div>

        <!-- "get your hidelike address" -->
        <div v-else-if="popupState == 16" class = "popupCard">
          <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
    
            <div class="popupTextheader">{{this.language.popup.p16header}}</div>
            <div class="popupText">{{this.language.popup.p16text}}</div>
            
            <div class="linkline">
              <div class="fieldlink">
                <div class="markedlink">www.hidelike.com/?id={{user.uid}}</div>
              </div>

              <div>
                <button @click="copyToClipboard('clipForSnapchat', true)">
                  <img v-if="!clipboard" class="copy" src="../assets/copy.svg">
                  <img v-else class="copy" src="../assets/copied.svg">
                </button>
              </div>
            </div>

            <div>
              <div class="linkline">
     
                  <a href='http://www.snapchat.com/add'>
                    <img class="copy" src="../assets/snapchat.svg"> 
                  </a>

                  <a href='http://www.instagram.com'>
                    <img class="copy" src="../assets/instagram.svg"> 
                  </a>

              </div>
            </div>
            
        </div>

        <!-- user permission to location sharing-->
        <div v-else-if="popupState == 17" class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupTextheader">{{this.language.popup.p17headerA}}
              <img class="iconinline" src="../assets/hidelike-scan-grey.svg" alt="">
            {{this.language.popup.p17headerB}}
          </div>
            <div class="popupText">
                <div>{{this.language.popup.p17text}}</div>
            </div>
            <div class="linkline">
              <button class="popupdeclinesmall" @click="setGeoPermission(false)">{{this.language.popup.no}}</button>
              <button class="popupconfirmsmall" @click="setGeoPermission(true)">{{this.language.popup.yes}}</button>   
            </div>
        </div>

        <!-- "get your hidelike address" -->
        <div v-else-if="popupState == 18" class = "popupCard">
    
            <div class="popupText">{{this.language.popup.p18text}}</div>
            
            <div class="inputlinegender">
              <button @click="selectGender('female')">
                <img v-if="this.settings.searchFilter.gender == 'female'" class="gendericon" src="../assets/femaleselected.svg">
                <img v-else class="gendericon" src="../assets/female.svg">
              </button>
              <button @click="selectGender('male')">
                <img v-if="this.settings.searchFilter.gender == 'male'" class="gendericon" src="../assets/maleselected.svg">
                <img v-else class="gendericon" src="../assets/male.svg">
              </button>
              <button @click="selectGender('x')"> 
                <img v-if="this.settings.searchFilter.gender == 'x'" class="gendericon" src="../assets/xgenderselected.svg">
                <img v-else class="gendericon" src="../assets/xgender.svg">
              </button>
            </div>

          <div  class="infoline">
            <button v-if="this.settings.searchFilter.ages.length > 0" @click="resetAges()"><img class="slidereset" src="../assets/close-block-lightgrey.svg" alt=""></button>
            <div class="everyage" v-if="this.settings.searchFilter.ages.length === 0">{{this.language.popup.p18everyage}}</div>
            <div class="agerange" v-else>{{this.settings.searchFilter.ages[0]}} - {{this.settings.searchFilter.ages[this.settings.searchFilter.ages.length-1]}} {{this.language.popup.p18years}}</div>      
          </div>
            
            <div class="inputline">
              
              <input @input="slide()" id="ageslider" type="range" class="slider" min= "21" max="56" value="38" step="1">
            </div>

           <button class="resetFilterButton" @click="resetFilter()">{{this.language.popup.p18resetFilter}}</button> 
 
            <button class="popupconfirm" @click="saveSearchFilter()">{{this.language.popup.ok}}</button>  

            
        </div>

        <!-- remove filter removal info in community-->
        <div v-else-if="popupState == 19" class = "popupCard">
          <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupText">
              {{this.language.popup.p19removeFilter}}
            </div>
            <button class="resetFilterButton" @click="resetFilter()">{{this.language.popup.p18resetFilter}}</button> 
        </div>

        <!--loading screen-->
        <div v-else-if="popupState == 'loading'" class = "popupCard">
            <div class="loadingtext">
                <div>{{this.language.popup.loading}}</div>
            </div>
        </div>
        
        <!-- generic popup-->
        <div v-else class = "popupCard">
            <p><button class="exit" @click="closePopup()"><img class="closeblock" src="../assets/close-block-grey.svg" alt=""></button></p>
            <div class="popupText">
                <div>{{popupState}}</div>
            </div>
            <button class="popupconfirm" @click="closePopup()">{{this.language.popup.ok}}</button>  
        </div>


    </div>
    
</template>


<script>

import { store, mutations } from '@/components/store'
import firebase from "firebase";

export default {

  props: [
    'popupState',
    'likeslistener',
    'routingState',
    'user'
  ],

  data() {
    return {
      password: '',
      clipboard: false,
      sliderPosition: 40,
    }
  },

  computed: {
    language() {return store.language},
    profile() {return store.profile},
    settings() {return store.settings}
   },


  methods: {

    selectGender(gender) {
      if (this.settings.searchFilter.gender  == gender) {
          mutations.setGender(null)
      } else {
        mutations.setGender(gender)
      }   
    },

    slide() {
      
      // get value from slider
      let sliderValue = Number(document.getElementById('ageslider').value)

      // calculate limits of ages range to be display to the user
      // and for calculating ages array below
      let maxAge = 4 + sliderValue
      let minAge = sliderValue - 5

      // fill ages array with all selected ages
      let ages = []
      for (let i = minAge; i <= maxAge; i++) {
        ages.push(i)
      }
      // save ages array in store
      mutations.setAges(ages)

    },

    saveSearchFilter () {
        
      // copying last version of search filter into new object
      let newSearchFilter = {...this.settings.searchFilter}

      // assing new values to copy of search filter
      if (newSearchFilter.ages.length > 0) {
        newSearchFilter.ageMin = newSearchFilter.ages[0]
        newSearchFilter.ageMax = newSearchFilter.ages[newSearchFilter.ages.length-1]
      }

      // trigger filter icon
      if (newSearchFilter.gender === null && newSearchFilter.ageMin === null) {
        newSearchFilter.filter = false
      } else {
        newSearchFilter.filter = true
      }

      console.log("search filter on closing: ", this.settings.searchFilter)

      // update local store
      mutations.setSearchFilter(newSearchFilter)

      // close popup
      this.closePopup()

      // update backend
      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({
        "settings.searchFilter": newSearchFilter
      })

      console.log("ages saved:", newSearchFilter.ages)

      // re-initialize community
      this.$emit('refreshCommunity')
    },

    resetAges () {
      // copying last version of search filter into new object
      let newSearchFilter = {...this.settings.searchFilter}

      // assing new values to copy of search filter
      // and flush ages array
      newSearchFilter.ages = []
      newSearchFilter.ageMin = null
      newSearchFilter.ageMax = null

      // update local store
      mutations.setSearchFilter(newSearchFilter) 

    },

    resetFilter () {

      // generate default filter object
      const newSearchFilter = {
        filter: false,
        ageMin: null,
        ageMax: null,
        gender: null,
        ages: []
      }

      // update local sotre
      mutations.setSearchFilter(newSearchFilter)

      // close popup
      this.closePopup()

      // re-initialize community
      this.$emit('refreshCommunity')

      // update backend
      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({
        "settings.searchFilter": newSearchFilter
      })
    },

      closePopup() {
        this.clipboard = false
        this.$emit('openPopup')
      },

      PushOptIn() {
        this.$emit('openPopup')
        this.$emit('PushOptIn')
      },

      profileDialogOn() {
        this.$emit('openPopup')
        this.$emit('profileDialogOn')
      },

      blockChat() {
        this.$emit('openPopup')
        this.$emit('blockChat')
      },

      installPwa() {
        this.$emit('installPwa')

      },

      reportUser() {
        this.$emit('openPopup')
        this.$emit('reportUser')
      },

      deleteUser(pass) {
        console.log("password in popup:", pass)
        this.$emit('openPopup')
        this.$emit('deleteUser', pass)
        this.password = ''
        
      },

      copyToClipboard(key, value) {

        navigator.clipboard.writeText("www.hidelike.com/?id="+this.user.uid);
        
        this.clipboard = true

        this.$emit('getAnalytics', key, value)

      },

      setGeoPermission(state) {
        this.$emit('openPopup')
        this.$emit('setGeoPermission', state)

      },


    
  }
}

</script>

<style scoped>

.popupContainer {
    display: block;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.473); /* Black background with opacity */
    z-index: 9000; 
    padding: 20px;
}

.exit {
    float: right;
    height: 30px;
}

.closeblock {
    height: 30px;
}

.popupCard {
    border-radius: 20px;
    max-width: 500px;
    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    text-align: center;
    justify-items: center;
    padding: 20px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.popupTextheader {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
}

.popupText {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    line-height: normal;
}

.iconinline {
  height: 30px;
  margin-bottom: -10px;
  margin-left: 5px;
  margin-right: 5px
}

.loadingtext {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 20px;
    color: #5F5F5F
}

.popupconfirm {
    margin-left: auto;
    margin-right: auto;
    border-radius: 35px;
    background-color: #f5416c;
    width: 200px;
    height: 70px;
    justify-content: center;
    align-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
    font-weight: bold;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .popupconfirmsmall {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 35px;
    background-color: #f5416c;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-content: center;
    padding: 5px;
    color: #ffffff;
    font-weight: bold;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .popupdeclinesmall {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 35px;
    background-color: #d0d0cf;
    width: 70px;
    height: 35px;
    justify-content: center;
    align-content: center;
    padding: 5px;
    color: #ffffff;
    font-weight: bold;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

    .resetFilterButton {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
      border-radius: 35px;
      background-color: #d0d0cf;
      width: 200px;
      height: 35px;
      justify-content: center;
      align-content: center;
      padding: 5px;
      color: #3d3d3d;
      font-weight: bold;
      -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
      box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

    .report {
      margin-left: auto;
      margin-right: auto;
      border-radius: 35px;
      background-color: red;
      width: 200px;
      height: 70px;
      justify-content: center;
      align-content: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #ffffff;
      font-weight: bold;
      -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
      box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .hidelike {
    margin-left: auto;
    margin-right: auto;
    border-radius: 35px;
    background-color: #ffcc30;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .browsericons {
    height: 20px
  }

  .field {
    justify-content: center;
    text-align: -webkit-center;
    border: 2px solid rgb(255, 255, 255);
    height: 60px;
    border-radius: 15px;
    font-size: 18px;
    max-width: 400px;
    padding: 0.5rem;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.233);
    color: rgb(0, 0, 0);
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

  .linkline {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    text-align: -webkit-center;
  }

    .inputline {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      text-align: -webkit-center;
    }

  .inputlinegender {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    text-align: -webkit-center;
  }

  .gendericon {
    height: 40px;
    margin-left: 10px;
    margin-right: 10px
  }
  
  .slidereset {
    width: 25px;
    height: 25px;
    margin-right: 10px
  }

  .slider {
    -webkit-appearance: none;
    width: 200px;
    height: 25px;
    background: #d0d0cf;
    outline: none;
    border-radius: 12px;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider:hover {
      opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 25px;
    border-radius: 12px;
    background: #1eb2a6;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 50px;
    height: 25px;
    border-radius: 12px;
    background: #1eb2a6;
    cursor: pointer;
  }

  .infoline {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    font-weight: bold;
    height: 25px;
  }

  .everyage {
    color: #3d3d3d;
    height: 25px;
  }

  .agerange {
    color: #1eb2a6;
    height: 25px;
  }

  .fieldlink {
    justify-content: center;
    text-align: -webkit-center;
    border: 2px solid rgb(255, 255, 255);
    height: auto;
    border-radius: 15px;
    width: 200px;
    padding: 0.5rem;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.233);
    color: rgb(0, 0, 0);
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .markedlink {
    white-space: nowrap;
    overflow-x: hidden;
    background-color: rgb(160, 200, 249);
    font-size: 14px;
  }

.copy {
  height: 40px;
  margin-left: 15px
}

</style>


