<template>
  <div class="background">
    
    <div class="form">
      
      <form @submit.prevent="registerUser">
        <div class="exit">
          <button  @click="goBack()" type="button"><img class="closeblock" src="../assets/back.svg" alt=""></button>
        </div>
        <input class="field" v-model="userData.email" id="email" name="email" type="email" placeholder="Email" autocomplete="username" required><br>     
        <input class="field" v-model="userData.password" id="current-password" name="current-password" type="password" placeholder="Password" autocomplete="current-password" aria-describedby="password-constraints" required><br>
        
        <select @change="setLanguage()"  class="select" v-model="userData.language">
          <option value="german">Deutsch</option>
          <option value="english">English</option>
          <option value="french">Francais</option>
          <option value="italian">Italiano</option>
        </select>

        <div class="terms">{{this.language.register.termsIntro1}}<a class="termslink" target="_blank" href="https://sites.google.com/view/hidelike-terms-of-service/">{{this.language.register.termsLink}}</a>{{this.language.register.termsIntro2}}<a class="termslink" target="_blank" href="https://sites.google.com/view/hidelike-privacy/">{{this.language.register.privacyLink}}</a> </div>

        <button class="registerbutton" id="register" type="submit">
          <div v-if="loading" class="progress"></div>
          <div class="buttonfont" v-else>{{this.language.register.registerButton}}</div>
        </button>
        
      </form>
    </div>



    <popup 
      :popupState="popupState" 
      class="popup" 
      @openPopup="openPopup()"
    >
    </popup>

  </div>
</template>

<script>
import firebase from "firebase/";
import popup from '@/components/popup';
import { store, mutations } from '@/components/store'

export default {
  components: {
    popup,
  },
  name: "register",
  data() {
    return {
      user: null,
      loading: false,
      popupState: 0,
      userData: {
        email: '',
        password: '',
        language: 'english' //default language setting      
      },
    };
  },

  computed: {
      language() {return store.language}
  },

  mounted() {


  },

  methods: {

    goBack() {
      this.$router.replace({
        name: "landingpage"
      })   
    },

    setLanguage() {

      mutations.setLanguage(this.userData.language)

    },

    registerUser() {

      this.loading = true

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.userData.email, this.userData.password)
        .then(() => {
          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              this.user = user
              this.loading = false
              this.goIntro()
              this.addUserToDB(user)
              this.addUserToLikesDB(user)
              this.addUserToUnreadsDB(user)
              this.addUserToRadarDB(user)
            }
          })
        })
        .catch(error => {
          console.log(error.message)
          this.loading = false
          this.openPopup(error.message)
          
        })
    },

    goIntro() {
      this.$router.replace({
        name: "intro"
      })
    },

    openPopup (state) {
      const popupState = (document.getElementById("popup").style.display == "block")

      if (!popupState) {
        this.popupState = state
        document.getElementById("popup").style.display = "block"
      } else {
        document.getElementById("popup").style.display = "none"
        this.popupState = 0
      }

    },

    addUserToDB(user) {
      firebase.firestore().collection("users").doc(user.uid).set({
        user: user.uid,
        email: user.email,
        active: false,
        newbiestate: true,
        likes: [],
        lat: 0,
        lng: 0,
        geohash: 0,
        imgurl: "0",
        picstate: false,
        settings: {
          language: this.userData.language,
          geoPermission: false
        },
        updated: firebase.firestore.FieldValue.serverTimestamp(), 
        lastaction: firebase.firestore.FieldValue.serverTimestamp(),
        description: "",
        lastScanResult: [],
      })
    },

    addUserToLikesDB(user) {
      firebase.firestore().collection("likes").doc(user.uid).set({
        likedby: [],
      })
    },

    addUserToUnreadsDB(user) {
      firebase.firestore().collection("unreads").doc(user.uid).set({
        unreadfrom: [],
      })
    },

    addUserToRadarDB(user) {
      firebase.firestore().collection("radarUpdate").doc(user.uid).set({
        radar: [],
      })
    },
  },
};
</script>

<style scoped>

.background {
  position: fixed;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: -webkit-center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(315deg, #ffcc30 30%,#f5416c 80%);
}

.popup {
  display: none;
}

.exit {
  display: flex;
  justify-content: flex-start;
  color: white;
}

.closeblock {
    height: 30px;
    margin: 15px;
}

.form {
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
}

.terms {
  max-width: 400px;
  color: white;
  font-size: 14px;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px
}

.termslink {
  color: white;
  text-decoration: underline;
  font-weight: bold
}

.field {
  justify-content: center;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 60px;
  border-radius: 15px;
  width: 90vw;
  font-size: 18px;
  max-width: 400px;
  padding: 0.5rem;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 50px;
  border-radius: 15px;
  width: 90vw;
  font-size: 18px;
  max-width: 400px;
  padding: 0.5rem;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.registerbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonfont {
font-weight: bold;
font-size: 16px
}

.popup {
  display: none;
}

.progress {
  margin: auto;
  height:20px;
  border-radius: 19px;
  color:rgb(182, 182, 182);
  position: relative;
}
.progress::before {
  content:"";
  position: absolute;
  margin:2px;
  inset:0 100% 0 0;
  border-radius: inherit;
  background:currentColor;
  animation:p6 4s infinite;
}
@keyframes p6 {
    100% {inset:0}
}


</style>