<template>
  <div class="background">
    <img class="landinglogo" src="../assets/hidelike-logo-quer-mit-slogan.svg">
    <video class="teaser" autoplay muted loop playsinline>
      <source src="../assets/firstscan_club.mp4" type="video/mp4">
      <img class="teaser" src="../assets/startscreen.png">
    </video>

    <div id="landingpage" class="buttoncontainer">
      <button @click="goToRegister()" class="registerbutton"><div class="buttonfont">REGISTER</div></button><br>
      <button @click="goToLogin()" class="loginbutton"><div class="buttonfont">LOGIN</div></button><br>
    </div>

      <div class="footbar">
        
        <a href='https://apps.apple.com/ch/app/hidelike/id1597251452'>
          <img class="storebadges" alt='Get it on App Store' src='../assets/appstore.svg'/>
        </a>
        <br>
        <a href='https://play.google.com/store/apps/details?id=com.hidelike.www.twa'>
          <img class="storebadges" alt='Get it on Google Play' src='../assets/googleplay.png'/>
        </a>
      </div>


    <button class="info" @click="showFaq()"><img class="infoimg" src="../assets/info-white.svg" alt=""></button>
    <div class="footnote">
        copyright by HIDELIKE<br>
        Zürich (Swiss), 2023
    </div>

      

      <!-- dialog for faq -->
      <faq id="faq" class="faq_dialog"
        @showFaq="showFaq()"
      >
      </faq>


  </div>
    
</template>


<script>

import { store, mutations } from '@/components/store'
import faq from '@/components/faq'

export default {
  
  components: {
    faq,
  },

  data() {
    return {

      installevent: null,
      teaserReady: false

    }

  },

  created() {
    window.addEventListener("beforeinstallprompt", (e) => {

      e.preventDefault()
      mutations.setInstallEvent(e)
      console.log("deferred installevent:", e)
    })
  },

  methods: {

    teaserLoaded() {
      this.teaserReady = true
      console.log("teaser loaded")
    },

    goToRegister() {
      this.$router.replace({
        name: "Register"
      })
    },

    goToLogin() {
      this.$router.replace({
        name: "Login"
      })
    },

    showFaq () {
      const faqState = (document.getElementById("faq").style.display == "block")
      console.log(faqState)
      if (!faqState) {
        document.getElementById("faq").style.display = "block"
      } else {
        document.getElementById("faq").style.display = "none"
      }
    },
  }
}

</script>

<style scoped>

.background {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  justify-items: center;
  text-align: -webkit-center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(315deg, #ffcc30 30%,#f5416c 80%);

}

.teaser {
  width: 100vw;
  max-height: 50vh;
     margin-left: auto;
   margin-right: auto;
}

.landinglogo {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 75vw;
  max-width: 400px;
  z-index: 8100
}

.buttoncontainer {
   z-index: 8000;
   margin-left: auto;
   margin-right: auto;
}

.registerbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  margin-top: 20px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.loginbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  margin-top: 20px;
  width: 90vw;
  background-color: #ffffff38;
  color: #ffffff;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

}

.buttonfont {
font-weight: bold;
}

.footnote {
margin-top: 10px;
   margin-left: auto;
   margin-right: auto;
color: white;
padding-bottom: 30px;
z-index: 8100;
}

.faq_dialog {
  display: none; /* Hidden by default */
}


.footbar {
  margin-top: 10px;
     margin-left: auto;
   margin-right: auto;
  z-index: 8000
}

.info {
  margin-top: 5px;
  z-index: 8000;
  height: 40px;
}

.infoimg {
  height: 40px
}


.storebadges {
  margin-top: 10px;
  width: 150px;

}





</style>


