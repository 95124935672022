<template>
  <div class="faq" id="faq">

      <div class="topbar">
        <button class="exit" @click="close()"><img class="closeblock" src="../assets/back-grey.svg" alt=""></button>  
        <select id="selector" @change="setLanguage()"  class="select" v-model="selectedLanguage">
          <option value="german">Deutsch</option>
          <option value="english">English</option>
          <option value="french">Francais</option>
          <option value="italian">Italiano</option> 
        </select>  
      </div>

      <!-- question-answer-section -->
      <div class="faqwrapper">

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.whatIsQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.whatIsA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.likeTinderQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.likeTinderA}}
        </div>


        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.realAppQ}}
        </div>
          <div class="faq_text">
            {{this.language.faq.realAppA1}}<img class="browsericons" src="../assets/addhome.png">{{this.language.faq.realAppA2}}<img class="browsericons"  src="../assets/addhomechrome.png">{{this.language.faq.realAppA3}}
          </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.notifcationQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.notifcationA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.privacyQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.privacyA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.anonymityQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.anonymityA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.reHidelikeQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.reHidelikeA}}
        </div>
 
        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.matchQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.matchA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.blockQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.blockA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.deleteAppQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.deleteAppA}}
        </div>

        <!-- question-answer-section -->
        <div class="faq_title">
          {{this.language.faq.aboutQ}}
        </div>
        <div class="faq_text">
          {{this.language.faq.aboutA}}
        </div>
      
       <!-- terms section -->
      <div class="faqwrapper">
        <a class="termslink" target="_blank" href="https://sites.google.com/view/hidelike-terms-of-service/">{{this.language.faq.terms}}</a><br><br>
        <a class="termslink" target="_blank" href="https://sites.google.com/view/hidelike-privacy/">{{this.language.faq.privacyPolicy}}</a>
      </div>

    </div>

    <div class="distancebottom"></div>

  </div>
</template>


<script>
import { store, mutations } from '@/components/store'
export default {


  data() {
    return {
      selectedLanguage: "english"
    }
    
  },

  mounted () {
    let selectElement = document.getElementById("selector")
    selectElement.value = this.language.language
    this.selectedLanguage = this.language.language

  },

  computed: {
    language() {return store.language},
  },

  methods: {

      close() {
        this.$emit('showFaq')
      },

      setLanguage() {
        mutations.setLanguage(this.selectedLanguage)
      
      },

    
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.faq {
  position: fixed; /* Sit on top of the page content */
  overflow-y: scroll;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  padding-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f1f2;
  z-index: 8999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  color: #5F5F5F
}

.topbar {
  
  position: fixed;
  top: 0;
  margin-left: 5px;
  padding: 20px;
  width: 100vw;
  height: 70px;
  background-color: #f3f1f2;

}


.exit {
 float: left
}

.closeblock {  
  height: 40px;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 20px;
  padding-left: 10px;
  border: 2px solid rgb(255, 255, 255);
  height: 40px;
  border-radius: 15px;
  width: 100px;
  font-size: 16px;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.233);
  color: #5F5F5F;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}


  .faqwrapper {
    margin-top: 30px;
    padding: 10px

  }
  
  .faq_title {
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}

  .faq_text {
    font-size: 14px;
    padding: 10px;

  }

  .browsericons {
    height: 16px
  }

  .termslink {
  color: #5F5F5F;
  text-decoration: underline;
  font-weight: bold
  }

  .distancebottom {
    height: 150px
  }

</style>



