<template>
      <div id="friends" class="friends">
        <div id="friends_top"> </div>
    
        <div id="chattable" v-for="chatroom in chatrooms" v-bind:key="chatroom.id">
          <div class="chatcards">
            <div class="chatrooms">
              <div>
                <img class="chatroomImages" v-if="chatroom.img == 0" v-on:click="goChatroom(chatroom)" src="../assets/profile-grey.svg">
                <img class="chatroomImages" v-else v-on:click="goChatroom(chatroom)" :src="chatroom.img">
                <div class="deletedLabel" v-if="chatroom.deleted">deleted</div>
              </div>
                    
              <div>
                <button class="hidelike_defaultsmall" @click="blockRequest(chatroom)"><img src="../assets/no-match.svg"></button>
              </div>
                    
              <div class="gomessagebutton" >
                <div v-if="unreadslistener.includes(chatroom.id)" class="badgeFriends" @click="goChatroom(chatroom)">1</div>
                <button class="hidelikesmall" @click="goChatroom(chatroom)">
                  <img src="../assets/contacts-white.svg">
                </button>
              </div>
            </div>
          </div>
        </div>
        <popup 
          id="friendspopup"
          :popupState="popupState" 
          class="popup" 
          @blockChat="blockChat()"
          @openPopup="openPopup"
        >
        </popup>
      </div>
</template>

<script>

import firebase from "firebase"
import popup from '@/components/popup'
import { store, mutations } from '@/components/store'


export default {

components: {
    popup
  },

  props: [
      'unreadslistener',
    ],


  data() {
    return {
      user: firebase.auth().currentUser,
      chatrooms: [],
      chatroomsLoader: [],
      itemsProcessed: 0,
      popupState: 0,
      blockIdRequest: null,
      order: 0,
      deletedUserId: ''
    }
  },

  computed: {  
    chatswith() {return store.chatswith},
    likes() {return store.likes},
    chatsOrder() {return store.chatsOrder}
  },

  mounted () {
    //this.initFriends()
  },

  activated () {
    
    // check if amounts of chats have changed (due to deletion)
    if (this.chatrooms.length != this.chatswith.length) {
 
      this.initFriends()

      this.$nextTick(
        () => { 
          let elmnt = document.getElementById('friends_top')
          elmnt.scrollIntoView(false)
        }
      )
    // check if chatorder has changed or if an unread message has been received
    } else if (this.chatsOrder[0] != this.chatrooms[0].id || this.unreadslistener.length > 0) {

      // empty loader array
      this.chatroomsLoader = []

      // re-sort chatrooms
      this.chatrooms.forEach((snap) => {

        // take new order value (set + 1 of array index to be after new messages which are set to 0 below)
        if (this.chatsOrder.includes(snap.id)) {
          this.order = this.chatsOrder.indexOf(snap.id) + 1
        } else {
          // set max value as fallback
          this.order = this.chatswith.length
        }
        // set chats with new messages to 0
        if (this.unreadslistener.includes(snap.id)) {
          this.order = 0
        } 

        const data = {
          'id': snap.id,
          'img': snap.img,
          'imgurlGallery': snap.imgurlGallery,
          'profile': snap.profile,
          'description': snap.description,
          'order': this.order,//this.unreadslistener.includes(snap.id)
          'deleted': snap.deleted
        }
        
        this.chatroomsLoader.push(data)
     
      })
      this.chatroomsLoader.sort((a, b) => a.order- b.order)
      this.chatrooms = this.chatroomsLoader

      this.$nextTick(
        () => { 
          let elmnt = document.getElementById('friends_top')
          elmnt.scrollIntoView(false)
        }
      )
    } else {
      this.$nextTick(
        () => { 
          let elmnt = document.getElementById('friends_top')
          elmnt.scrollIntoView(false)
        }
      )
    }
  },
  
  methods: {

    initFriends() {
  
      const db = firebase.firestore()
      const promises = []
      for (const b of this.chatswith) {
        const q = db.collection('users').doc(b)
        promises.push(q.get())
      }

      // Collect all the query results together into a single list
      Promise.all(promises)
      .then(
        (snapshots) => {

          // empty loader array
          this.chatroomsLoader = []

          // empty cleanup array
          const cleanup = []

          snapshots.forEach((snap) => {

            if (snap.data() != undefined) {
            
              // set order value in array

              // set order value according to chatsorder
              // set index + 1 to be below chats with unread messages which are set to 0 below
              if (this.chatsOrder.includes(snap.id)) {
                this.order = this.chatsOrder.indexOf(snap.id) + 1
              } else {
                // take the max value as fallback
                this.order = this.chatswith.length
              }
              // set chats with unread messages to 0
              if (this.unreadslistener.includes(snap.id)) {
                this.order = 0
              } 

              const data = {
                'id': snap.id,
                'img': snap.data().imgurl,
                'imgurlGallery': snap.data().imgurlGallery,
                'profile': snap.data().profile,
                'description': snap.data().description,
                'order': this.order,//this.unreadslistener.includes(snap.id),
                'deleted': false
              }
              
              this.chatroomsLoader.push(data)
              
              // collect all other users which are not "undefined" (not deleted on db)
              // this array will be used below to process deleted users
              cleanup.push(data.id)

            } else {
              console.log("deleted user:", snap.id)
            
            }
          })

          this.chatroomsLoader.sort((a, b) => a.order- b.order)
          this.chatrooms = this.chatroomsLoader

          this.$nextTick(
            () => { 
              let elmnt = document.getElementById('friends_top')
              elmnt.scrollIntoView(false)
            }
          )

          //display deleted users at the end of the list
          this.chatswith.forEach((snap) => {

            // check which matches are now undefined (deleted in db)
            if (!cleanup.includes(snap)) {

              const data = {
                'id': snap,
                'img': "0",
                'order': this.chatswith.length, //put on the bottom of the list
                'deleted': true // flag this to prompt a "deleted user"-badge on DOM
              }

              // set order for matches with deleted users
              if (this.unreadslistener.includes(snap)) {
                //if there is an unread message from a deleted user the user
                // is placed on top of the list
                this.chatrooms.unshift(data)
              } else {
                // if there is no unread message from a deleted user the user
                // is placed on the bototm of the list
                this.chatrooms.push(data)
              }
            
            //collect each deleted users to the cleanup arry which is used for
            // a fallback clean-up function below
            cleanup.push(data.id)
              
            }
          })

          // the following clean up function catches the -so far impossible- case
          // that the user has an unread message from a user which is not displayed
          // on his friends list. Even this should not be the case right now some lines
          // of code are worth it as it would be very annoying for the user.

          //clean-up unreadslistener
          this.unreadslistener.forEach((snap) => {
           if (!cleanup.includes(snap)) {
              db.collection("unreads")
              .doc(this.user.uid)
              .update({ unreadfrom: firebase.firestore.FieldValue.arrayRemove(snap)})  
           }
          })

        }
      )
    },

    goChatroom(chatroom) {

      let selectedProfile = {}

      //assign profile informations
      if (chatroom.profile != undefined) {
        selectedProfile = chatroom.profile
      } else {
        selectedProfile = {
          description: chatroom.description,
          snapchat: null,
          instagram: null
        }
      }

      // set empty array to be copied below into Gallery
      let galleryFiller = []
      
            //check if a gallery is available (for older versions)
      if (chatroom.imgurlGallery != undefined) {
          
        //check each item of gallery for 0 values to be skipped
        chatroom.imgurlGallery.forEach(item => {

          if (item.imgurl != 0) {
            galleryFiller.push(item)
          }
        })
        // if no picture could be found a zero value is pushed
        if (galleryFiller.length < 1) {
          galleryFiller.push({imgurl: 0, uid: 0})
        }
      // if old version: the gallery is filled with the single profile pic
      } else {
        galleryFiller.push({imgurl: chatroom.img, uid: 0})
      }

      this.$router.push(
        {
            name: "chat",
            params: {
              selectedUser: chatroom.id,
              selectedGallery: galleryFiller,
              selectedProfile: selectedProfile,
              selectedDescription: chatroom.description
            }
        }
      )
    },

    blockRequest (friend) {
      this.blockIdRequest = friend.id
      this.openPopup(4)
    },

    blockChat() {
      if (this.blockIdRequest != null) {

        // remove from DOM list
        const index = this.chatrooms.findIndex(x => x.id === this.blockIdRequest)
        if (index > -1) {this.chatrooms.splice(index, 1)}

        // remove from likes state
        if (this.likes.includes(this.blockIdRequest)) {
           mutations.deleteLikes(this.blockIdRequest)
        } else {
          console.log("user is unliked already")
        }

        // remove chatswith state
        if (this.chatswith.includes(this.blockIdRequest)) {
           mutations.deleteChatswith(this.blockIdRequest)

          // set new order of friendslist
          if (this.chatsOrder.includes(this.blockIdRequest)) {

            // copy chatswith array to reset chatsorder for friendslist
            const newChatOrder = [...this.chatsOrder]

            // take out other userschat id
            const index = newChatOrder.indexOf(this.blockIdRequest);
            if (index > -1) {
            newChatOrder.splice(index, 1); // 2nd parameter means remove one item only
            }

            // update internal state of chatorder
            mutations.setChatsOrder(newChatOrder)

          }

        } else {
          console.log("user is unliked already")
        }

        // remove respective db fields
        const db = firebase.firestore();
        db.collection("users")
        .doc(this.user.uid)
        .update({ 
          likes: firebase.firestore.FieldValue.arrayRemove(this.blockIdRequest),
          chatswith: firebase.firestore.FieldValue.arrayRemove(this.blockIdRequest), 
          lastaction: firebase.firestore.FieldValue.serverTimestamp(),
          chatsOrder: this.chatsOrder
        }),
          

        // update other users likeslistener
        db.collection("likes")
        .doc(this.blockIdRequest)
        .update({ likedby: firebase.firestore.FieldValue.arrayRemove(this.user.uid)})

        // update blocked users unreads listener
        db.collection("unreads")
        .doc(this.blockIdRequest)
        .update({ unreadfrom: firebase.firestore.FieldValue.arrayRemove(this.user.uid)})

        // inform other user about blocking
        const channelid = this.user.uid > this.blockIdRequest ? this.blockIdRequest + this.user.uid : this.user.uid + this.blockIdRequest
        db.collection("chatrooms").doc(channelid).collection("messages")
        .doc()
        .set({text: "I blocked you", block: true, sender: this.user.uid, time: firebase.firestore.FieldValue.serverTimestamp()})

        // check if an unread message is pending an has to be muted
        if (this.unreadslistener.includes(this.blockIdRequest)) {
        db.collection("unreads")
          .doc(this.user.uid)
          .update({ unreadfrom: firebase.firestore.FieldValue.arrayRemove(this.blockIdRequest)})
        }
        // resed BlockIdRequest for next run
        this.blockIdRequest = null
      }
    },

    openPopup (state) {
      
      const popupState = (document.getElementById("friendspopup").style.display == "block")

      if (!popupState) {
        this.popupState = state
        document.getElementById("friendspopup").style.display = "block"
      } else {
        document.getElementById("friendspopup").style.display = "none"
        this.popupState = 0
      }

    },

  }

}

</script>

<style scoped>

.friends {
  display: grid;
  justify-content: center;
	margin-top: 100px;
  margin-bottom: 20vh;
}

.chatcards {
  width: 80vw;
  max-width: 500px;
  border-radius: 15px;
  background-color: #ffffff38;
  border: 1px solid #a8a8a800;
  margin: 5px;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  
}

.chatrooms {
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
}

.chatroomImages {
  height: 10vh;
  width: 10vh;
  border-radius: 15px;
  max-height: 100px;
  max-width: 100px;
  z-index: 8996;
}

 .hidelike {
  border-radius: 35px;
  background-color: #ffcc30;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  }

  .hidelike_defaultsmall {
  border-radius: 35px;
  background-color: #d0d0cf;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 8996;
  font-size: 12px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

  .gomessagebutton {
    position: relative

  }

  .badgeFriends {
  position: absolute;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  background-color: #f5416c;
  margin-top: -10px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  color: white
}

    .hidelikesmall {
  border-radius: 20px;
  background-color: #ffcc30;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

 .deletedLabel {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    border-radius: 12px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    height: 24px;
    background-color: #5F5F5F;
    font-size: 14px;
    color: white;
  }

  .popup {
  display: none;
}



</style>


