import Vue from "vue";
import { languages } from '@/components/languages'

export const store = Vue.observable({
  profileImage: 0,
  imgurlGallery: [{imgurl: 0,uid: 0},{imgurl: 0,uid: 0},{imgurl: 0,uid: 0}],
  description: '',
  avatardata: {accessories: "Blank",clothes: "CollarSweater",clothescolor: "Black",eyebrow: "Default",eyes: "Happy",facialhair: "Blank",hairscolor: "Auburn",mouth: "Smile",skin: "Light",top: "LongHairBigHair"},
  profile: {
    age: null,
    description: null ,
    snapchat: null ,
    instagram: null,
    gender: null,
    ages: []
  },
  installEvent: null,
  language: languages.english, // select english as default
  likesStorage: [],
  likes: [],
  chatswith: [],
  chatsOrder: [],
  firstLikeDone: false,
  pushAvailable: false,
  newbiestate: true,
  settings: {
    language: "english",
    geoPermission: false,
    searchFilter: {
      filter: false,
      ageMin: null,
      ageMax: null,
      gender: null,
      ages: []
    }
  },
  urlState: null,
  localSession: {
    communityTimer: 0,
  },

});

export const mutations = {

  setSession(field, setter) {

    store.localSession[field] = setter

    console.log ("saved value: ", setter, "under field: ", field, "in store")

  },

  setSettings(setter) {

    /// get Keys from setter to check which settings are available from backend
    const keys = Object.keys(setter);
    
    // iterate over object keys to copy all available settings to local store
    keys.forEach((key, index) => {
      
      /* check if the key represents a nested object to avoid overriding of 
      default variables in store which are not available in data got from backend (for example:
        The object was saved from an older version of the app where the respective field was 
        not available. Such an object should not be copied as new fields would be overwritten) */
      if (
        //check if key is an object
        typeof store.settings[key] === 'object' &&
        !Array.isArray(store.settings[key]) &&
        store.settings[key] !== null
      ) {
        // loop over available key from backend and copy to local store
        
        const subKeys = Object.keys(setter[key])
        subKeys.forEach((nextkey, index) => {
          store.settings[key][nextkey] = setter[key][nextkey]
       
        })
      } else {

      // if not a nested object, the key can be copied to local store
      store.settings[key] = setter[key]
      }
    })

    // assign languege
    store.language = languages[store.settings.language]

  },

  setGeoPermission(state) {
    store.settings.geoPermission = state
  },

  setSearchFilter(newSearchFilter) {
    store.settings.searchFilter = newSearchFilter
  },

  setGender(newGender) {
    store.settings.searchFilter.gender = newGender
  },

  setAges(ages) {
      store.settings.searchFilter.ages = ages
  },

  setLanguage(language) {
    store.language = languages[language]
    console.log("set language in store to: ", language)

  },

  setProfileImage(profileImage) {
    store.profileImage = profileImage
  },

  setImgurlGallery(imgurlGallery) {
    if (!imgurlGallery) {
      store.imgurlGallery[0].imgurl = store.profileImage

    } else {
      store.imgurlGallery = imgurlGallery
    }
  },

  setProfile(profile) {
    if (!profile) {
      store.profile.description = store.description
    } else {
      for (const key in store.profile) {
        if (profile.hasOwnProperty(key)) {
          store.profile[key] = profile[key]
        } 
      }
    }
  },

  setDescription(description) {
    store.description = description
  },

  setAvatar(avatardata) {
    store.avatardata = avatardata
  },

  setInstallEvent(installEvent) {
    store.installEvent = installEvent
  },

  setLikesStorage(likesStorage) {
    store.likesStorage = likesStorage
  },

  pushLikesStorage(likesStorage) {
    store.likesStorage.push(likesStorage)
  },

  setLikes(likes) {
    store.likes = likes
  },

  pushLikes(likes) {
   // check if user has already been liked
    if (store.likes.includes(likes)) {console.log("user already liked")}
    else {
      store.likes.unshift(likes)
    }
  },

  deleteLikes(likes) {
    // check if user has already been liked
    if (store.likes.includes(likes)) {
      const index = store.likes.indexOf(likes); 
      if (index > -1) {store.likes.splice(index, 1)}
    } else {console.log("user is unliked already")}
    
   },

   setChatswith(chatswith) {
    store.chatswith = chatswith
  },

  setChatsOrder(chatsOrder) {
    store.chatsOrder = chatsOrder
  },

  pushChatswith(chatswith) {
    // check if user has already been liked
     if (store.chatswith.includes(chatswith)) {console.log("user already liked")}
     else {
       store.chatswith.unshift(chatswith)
     }
   },

   deleteChatswith(chatswith) {
    // check if user has already been liked
    if (store.chatswith.includes(chatswith)) {
      const index = store.chatswith.indexOf(chatswith); 
      if (index > -1) {store.chatswith.splice(index, 1)}
    }
    
   },

   setPushAvailable(pushAvailable) {
    store.pushAvailable = pushAvailable
    console.log("save push available to store")
    
   },

   setfirstLikeDone(firstLikeDone) {
    store.firstLikeDone = firstLikeDone
    console.log("first like done saved to store")
    
   },

   setNewbiestate(newbiestate) {
    store.newbiestate = newbiestate
  },

  setUrlState(urlState) {
    store.urlState = urlState
  },


};