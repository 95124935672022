<template>
  <div id="app">


<keep-alive><router-view v-if="$route.meta.keepAlive"></router-view></keep-alive>
<!-- // Non cache component jump page -->
<router-view v-if="!$route.meta.keepAlive"></router-view>


  </div>
</template>



<script>

export default {
  name: "App"
};

</script>


