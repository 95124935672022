export const languages =
{

  german: {
    language: "german",
    avatarcreator: {
      createYourAvatar: "Zeichne deinen Avatar!",
      save: "speichern"},
    chat: {       
      newMatch: "create your avatar",
      blocked: "blocked",
      typeMessageHere: "Schreibe hier deine Nachricht"
    },
    community: null,
    description: {
      intro: "Wer bist du? Erzähl etwas über dich...",
      save: "speichern",
      gender: "Geschlecht:",
      undefinedGender: "keine Angabe",
      male: "Mann",
      female: "Frau",
      cross: "X",
      age: "Alter:",
      aboutYou: "Über dich:"
    },
    settings: {
      settings: "Einstellungen",
      language: "Sprache",
    },
    driver: {
      tapOnRadar: "starte jetzt den Radar!",
      noFriends: "Du hast keine Freunde...hier 😉",
      startRadarFirst: "Bitte starte zuerst den Radar."
    },
    faq: {
      terms: "Allgemeine Bedingungen",
      privacyPolicy: "Datenschutz",
      whatIsQ: "Was ist Hidelike?",
      whatIsA: "Mit Hidelike kannst du Leute kennenlernen, denen du im Alltag begegnest. Hidelike zeigt dir andere Personen im Umkreis von 50 Meter an. Magst du jemanden? Gib einfach einen Hidelike (versteckter Like). Deine Hidelikes sind geheim und niemand kann sie sehen, ausser jemand gibt dir auch einen Hidelike: Dann könnt ihr chatten oder -besser- euch offline unterhalten. Probier es aus!",
      likeTinderQ: "…wie Tinder?",
      likeTinderA: "Nein, genau umgekehrt: Man sieht sich in der Regel zuerst live oder kennt sich aus dem Alltag und benutzt die App nur als Eisbrecher. Der Scanner von Hidelike ist dazu viel genauer (50 Meter) als bei Tinder & Co. (mehrere Kilometer) und immer real-time. Hidelike ist zudem 100% gratis, ohne Werbung und niemand verdient Geld damit.",
      realAppQ: "Gibt es eine «richtige App»?",
      realAppA1: "Hidelike ist auf dem Apple App Store und auf dem Google Playstore verfügbar. Hidelike entspricht der neusten Generation von Apps (PWA) und kann auch direkt aus dem Browser (ohne App-Store) auf jedem Smartphone installiert werden: Geh einfach auf ",
      realAppA2: " (Safari) bzw. ",
      realAppA3: " (Chrome) und installiere die App auf deinem Homescreen. Danach öffnet sich Hidelike als normale App im Fullscreen-Mode. Du kannst Hidelike aber auch im Browser benutzen.",
      notifcationQ: "Bekomme ich Benachrichtigungen?",
      notifcationA: "Nur, wenn Du das möchtest: Hidelike wird dich fragen, ob du bei einem neuen Hidelike, einem Match oder bei einer neuen Nachricht informiert werden möchtest.",
      privacyQ: "Was passiert mit meinen Daten?",
      privacyA: "Alle Daten werden mit einer SSL-Verschlüsselung übertragen und auf Firebase-Servern von Google gesichert. Wenn du «Scan» drückst, wird einmalig ein Schnappschuss deines Standortes mit der Datenbank abgeglichen. Du bestimmst also, wo und wann du einen Schnappschuss deines Standorts abgleichen möchtest. Nur Personen, in deinem Umkreis von 50 Metern sehen deinen Standort...aber den sehen sie auch ohne die App, oder?",
      anonymityQ: "Kann ich anonym bleiben?",
      anonymityA: "Ja, deine E-Mail-Adresse wird in keinem Fall gezeigt und dient nur dem Zugang zur App. Wenn Du kein Foto von dir zeigen möchtest, kannst du einen Avatar gestalten. Wenn Du aber keinen Avatar und auch kein Foto im Profil zeigst, wird dein Profil anderen Personen nicht angezeigt und du kannst keine Hidelikes vergeben.",
      reHidelikeQ: "Kann ich Hidelikes zurücknehmen?",
      reHidelikeA: "Ja, einfach das Herzchen nochmals drücken. Sofern dein Hidelike aber einen Match ausgelöst hat, wird die andere Person darüber informiert.",
      matchQ: "Was passiert bei einem Match?",
      matchA: "Wenn Du mit deinem Hidelike einen Match auslöst, verwandelt sich der Hidelike in ein Herz und ein Chat wird freigeschalten. Wenn du nicht in der App bist, wirst du per Email oder durch eine Push-Notification informiert.",
      blockQ: "Kann ich eine Person blockieren?",
      blockA: "Ja, unter «Friends» findest du für jede Person einen «block»-button. Danach kann dich diese Person nicht mehr per Chat kontaktieren, wird aus deiner Friends-List gelöscht und der Hidelike verschwindet. Sobald du einer blockierten Person von dir aus trotzdem wieder einen Hidelike gibst und ihr schreibst, wird die Blockade wieder aufgehoben.",
      deleteAppQ: "Kann ich Hidelike löschen?",
      deleteAppA: "Ja, genau so, wie jede andere App auch. Wenn Du dein Profilbild löschst, verschwindet dein Profil auf Hidelike. Du hast aber jederzeit Zugriff darauf, wenn du möchtest.",
      aboutQ: "Wer steckt hinter Hidelike?",
      aboutA: "Hidelike wurde von Louis Maag entwickelt. Schreib ihm unbedingt, wenn Du Entwicklungsvorschläge hast: thehidelike@gmail.com.",
    },
    friends: null,
    gallery: {
      intro: "Wähle deine Bilder aus:"
    },
    hidelikecard: {
      youLikeEachOther: "Ihr habt Interesse aneinander!",
      goForChat: "Zeit für einen Chat!",
      giveHidelike: "Gib einen versteckten Like",
      nobodyCanSee: "...bleibt bis zum Match geheim!",
      load: "lädt"
    },
    intro: {
      intro1: "Willkommen auf Hidelike!",
      intro2a: "Als Erstes,",
      intro2b: "Wähle deine Profilbilder",
      intro2c: "oder zeichne einen Avatar",
      intro3: "Danke! Starte deinen Radar an jedem neuen Ort!",
      buttonLetMein: "Jetzt probieren!",
      buttonPicUpload: "Bilder laden",
      buttonCreateAvatar: "Avatar erstellen",
      uploadYourPictures: "Lade Bilder von dir hoch:",
      createYourAvatar: "Zeichne deinen Avatar!",
      describeYou: "Wer bist du? Erzähl von dir...",
      scanNow: "Danke! Starte jetzt den Radar für Personen um dich herum!",
      next: "weiter"
    },
    langingpage: null,
    login: null,
    profile: {
      profilePictures: "Profilbilder",
      aboutYou: "Über dich",
      settings: "Einstellungen",
      yourHidelikeAddress: "Deine Hidelike-Adresse",
      faq: "Hilfe (FAQ)",
      deleteMyAccount: "Mein Profil löschen",
      logout: "Abmelden"
    },
    profilepicture: {
      intro: "wähle ein Profilbild oder erstelle einen Avatar...",
      picturesButton: "Bilder",
      avatarButton: "Avatar"
    },
    register: {
      registerButton: "REGISTRIEREN",
      termsIntro1: "Mit dem Registrieren akzeptierst du unsere ",
      termsIntro2: " und unsere ",
      termsLink: "Allgemeinen Bedingungen",
      privacyLink: "Bedingungen zum Datenschutz "
    },
    popup: {
      yes: "ja",
      no: "nein",
      ok: "OK",
      loading: "lädt...",
      p1text: "Möchtest du bei einem Match informiert werden?",
      p2header: "Kein GPS",
      p2text: "Du hast GPS blockiert für diese Anwendung. Prüfe deine Einstellungen",
      p3header: "Kein Profilbild",
      p3text: "Ohne Profilbild kannst du keine Hidelikes vergeben. Bitte wähle ein Profilbild oder zeichne deinen Avatar: ",
      p4header: "Diese Person blockieren?",
      p4button: "blockieren",
      p5header: "Die App installieren?",
      p5text: "...macht mehr Spass 😎🤟",
      p5button: "installieren",
      p6header: "Möchtest du Hidelike zum Homescreen hinzufügen?",
      p6text: "...macht viel mehr Spass 😎🤟",
      p6instruction1: "tippe auf ",
      p6instruction2: " (Safari) oder ",
      p6instruction3: " (Chrome)",
      p7header: "neue Version laden...",
      p8text1: "Du hast ",
      p8text2: " hidelikes  💪",
      p8text3: "Du hast einen Hidelike bis jetzt 👍",
      p8text4: "Du hast keine Hidelikes 😭 ...warte nur...die kommen schon noch 😎",
      p8text5: "Finde heraus, wer es war und gib selber Hidelikes!",
      p9header: "Das ist der Radar vom letzten Ort",
      p9text: "Matche am letzten Ort oder aktualisiere den Radar!",
      p10header: "kein match",
      p10text: "sorry, du kannst nur bei einem Match chatten.",
      p11header: "Keine Personen in der Nähe",
      p11text: "...in der Community sind aber immer Personen online zum Kennenlernen!",
      p12header: "Etwas Unpassendes entdeckt?",
      p12text: "Bitte melde uns unpassende Inhalte!",
      p12button: "Inhalt melden",
      p13header: "Sicher, dass Du dein Profil löschen möchtest?",
      p13text: "Alle deine Daten werden gelöscht!",
      p13button: "Profil jetzt löschen",
      p14header: "Mit Personen auf Insta matchen?",
      p14text: "Kopiere deine Hidelike-Adresse als Link in dein Insta-Profil",
      p14button: "weiter zu Instagram",
      p15header: "Mit Personen auf Snap matchen?",
      p15text: "Kopiere deine Hidelike-Adresse als Link in dein Snap-Profil",
      p15button: "weiter zu Snapchat",
      p16header: "Mit Personen auf Insta oder Snap matchen?",
      p16text: "Kopiere deine Hidelike-Adresse als Link in dein Profil auf Insta, Snapchat oder auf jede andere Plattform!",
      p17headerA: "Drücken auf ",
      p17headerB: " bedeutet:",
      p17text: "Hidelike kann dich und andere Personen um dich herum auf einem gemeinsamen Radar anzeigen. Möchtest du an diesem Standort den Radar starten?",
      p18text: "Wen suchst du?",
      p18selectGender: "Geschlecht?",
      p18selectAllGender: "alle Geschlechter",
      p18selectMale: "Männer",
      p18selectFemale: "Frauen",
      p18selectX: "X",
      p18years: "Jahre",
      p18everyage: "jedes Alter",
      p18resetFilter: "Filter zurücksetzen",
      p19removeFilter: "Um mehr Profile zu sehen, solltest Du den Filter zurücksetzen, weil nicht alle User ihr Alter oder Geschlecht angeben."
    },
  
  },

  english: {
    language: "english",
    avatarcreator: {
      createYourAvatar: "create your avatar",
      save: "save"},
    chat: {       
      newMatch: "create your avatar",
      blocked: "blocked",
      typeMessageHere: "type your message here"
    },
    community: null,
    description: {
      intro: "Who are you? Tell me something about you...",
      save: "save",
      gender: "gender:",
      undefinedGender: "undefined",
      male: "male",
      female: "female",
      cross: "X",
      age: "age:",
      aboutYou: "about you:"
    },
    settings: {
      settings: "settings",
      language: "language",
    },
    driver: {
      tapOnRadar: "tap on the scan button",
      noFriends: "you don't have friends...here 😉",
      startRadarFirst: "please tap the scan button first"
    },
    faq: {
      terms: "terms of service",
      privacyPolicy: "privacy policy",
      whatIsQ: "What is Hidelike?",
      whatIsA: "Get to know people you meet in everyday life with Hidelike: On Hidelike you see people 50 meters around you. Do you like someone? Just give a Hidelike (hidden like). Your given hidelikes are your secret and nobody can see it ...unless someone gives you a hidelike too: Then go for a chat -or better- meet offline. Try it!",
      likeTinderQ: "…like Tinder?",
      likeTinderA: "No, the opposite: You meet in real life first and the app is just an ice breaker to get in touch. Therefore, the Scanner of Hidelike is more accurate (50 meter) compared to Tinder & Co. (several kilometers) and real time. And hidelike is 100% free with no adds.",
      realAppQ: "Is there a real App?",
      realAppA1: "Hidelike is on the Apple App Store and on the Google Playstore. However, Hidelike is from the latest generations of Apps (Progressive Web Apps) and therefore installable right from your browser (without any apps store) on every smartphone or computer: Just tap on ",
      realAppA2: " (Safari) or ",
      realAppA3: " (Chrome) and install the app on your homescreen. After installation Hidelike can be launched full screen as a real app. If you don't want to install it you can just use hidelike in your browser.",
      notifcationQ: "Do I get notifcations?",
      notifcationA: "Only if you want: Hidelike will ask you whether you want to be notified in case of new hidelikes, new matches or new messages.",
      privacyQ: "What happens with my data?",
      privacyA: "All data is transferred SSL-encrypted and saved on firebase servers from google. By clicking on radar-scan-button the coordinates of your location is sent to the server. Therefore, you decide when and where you want to share your location (No auto-tracking of your location). Only persons 50 meters around you see your location in the app...but they would see you anyway, right?",
      anonymityQ: "Can I stay anonymous?",
      anonymityA: "Yes, nobody will see your email-address and we only need it for your access to the app. If you do not want to show a picture of you just create an avatar. Without a picture or avatar it is not possible to give hidelikes and other people do not see your profile.",
      reHidelikeQ: "Can I reverse a given hidelike?",
      reHidelikeA: "Yes, just click the hidelike-button again. If your hidelike has triggered a match the other person will be notified about it.",
      matchQ: "What happens in case of a match?",
      matchA: "If you triggered a match, the hidelike-button turns into red and the chat-button is active (yellow) as you can go for a chat now. If you got a match while you are not in the app you will be notified by push-notification (android) or email (iOS).",
      blockQ: "Can I block someone?",
      blockA: "Yes, under your Friends-list you find the block-button. A blocked person is deleted from your friends-list, the hidelike is reversed and no further communication is possible. As soon as you re-like a blocked person you can continue to chat and the user will be unblocked.",
      deleteAppQ: "Can I delete Hidelike?",
      deleteAppA: "Yes, just delete the app from your homescreen. As soon as you delete your profile picture, your will disappear on hidelike.",
      aboutQ: "Who has made Hidelike?",
      aboutA: "Hidelike is developed by Louis Maag...just let me know your feedback: thehidelike@gmail.com.",
    },
    friends: null,
    gallery: {
      intro: "Upload your pictures:"
    },
    hidelikecard: {
      youLikeEachOther: "You like each other!",
      goForChat: "Go for a chat!",
      giveHidelike: "Give a Hidelike",
      nobodyCanSee: "...nobody can see it",
      load: "load",
    },
    intro: {
      intro1: "Welcome on Hidelike!",
      intro2a: "First,",
      intro2b: "upload your pictures",
      intro2c: "or create your avatar",
      intro3: "Thanks! Check your radar now!",
      buttonLetMein: "Yeah, let me in!",
      buttonPicUpload: "upload pictures",
      buttonCreateAvatar: "create avatar",
      uploadYourPictures: "upload your pictures",
      createYourAvatar: "create your avatar:",
      describeYou: "Who are you? Tell me something about you...",
      scanNow: "Thanks! Now check the Radar for people around you!",
      next: "next"
    },
    langingpage: null,
    login: null,
    profile: {
      profilePictures: "profile pictures",
      aboutYou: "about you",
      settings: "settings",
      yourHidelikeAddress: "your Hidelike-Address",
      faq: "help (FAQ)",
      deleteMyAccount: "delete my account",
      logout: "log out"
    },
    profilepicture: {
      intro: "set profile pics or create an avatar...",
      picturesButton: "pictures",
      avatarButton: "avatar"
    },
    register: {
      registerButton: "REGISTER",
      termsIntro1: "By tapping on register, you agree to our ",
      termsIntro2: " and our ",
      termsLink: "terms of service",
      privacyLink: "privacy policy "
    },
    popup: {
      yes: "yes",
      no: "no",
      ok: "ok",
      loading: "loading",
      p1text: "see when you have a match?",
      p2header: "No GPS",
      p2text: "You don't allow GPS for this application. Please check your settings.",
      p3header: "No profile Pic",
      p3text: "A profile Pic is required to give hidelikes. Please upload your photo or avatar:",
      p4header: "block this user?",
      p4button: "block",
      p5header: "install real app?",
      p5text: "...it's much cooler fullscreen 😎🤟",
      p5button: "install",
      p6header: "install App to homescreen?",
      p6text: "...it's much cooler fullscreen 😎🤟",
      p6instruction1: "tap on ",
      p6instruction2: " (Safari) or ",
      p6instruction3: " (Chrome)",
      p7header: "updating...",
      p8text1: "you got ",
      p8text2: " hidelikes  💪",
      p8text3: "you got one hidelike until now 👍",
      p8text4: "you don't have any hidelikes 😭 ...just wait...they will come 😎",
      p8text5: "give hidelikes to find out who...",
      p9header: "this is your last scan",
      p9text: "scan location to update",
      p10header: "no match",
      p10text: "sorry, you have to wait for a match to chat.",
      p11header: "no users around you",
      p11text: "check our community!",
      p12header: "Bad content?",
      p12text: "please inform Hidelike about inappropriate content",
      p12button: "report this user",
      p13header: "Sure to delete your account?",
      p13text: "All your data will be deleted!",
      p13button: "delete my account",
      p14header: "Match with people on Insta?",
      p14text: "add your hidelike-address to your insta profile",
      p14button: "go to Instagram",
      p15header: "Match with people on Snap?",
      p15text: "add your hidelike-address to your snap profile",
      p15button: "go to Snapchat",
      p16header: "Match with people on Insta or Snap?",
      p16text: "add your hidelike-address to Insta, Snap or any other platform!",
      p17headerA: "tapping on ",
      p17headerB: " means:",
      p17text: "Hidelike can show you and other people around you on a common radar. Do you want to start the radar at this location?",
      p18text: "What are you looking for?",
      p18selectGender: "gender?",
      p18selectAllGender: "all gender",
      p18selectMale: "male",
      p18selectFemale: "female",
      p18selectX: "X",
      p18years: "years",
      p18everyage: "every age",
      p18resetFilter: "reset filter",
      p19removeFilter: "To see more profiles reset the filter as some users don't provide their gender or age."

    },
  
  },

  italian: {
    language: "italian",
    avatarcreator: {
      createYourAvatar: "disegnare un avatar",
      save: "salvare"},
    chat: {       
      newMatch: "disegnare un avatar",
      blocked: "bloccare",
      typeMessageHere: "scrivi un messaggio"
    },
    community: null,
    description: {
      intro: "Chi sei? Dimmi di più su di te...",
      save: "salvare",
      gender: "genere:",
      undefinedGender: "non definito",
      male: "uomo",
      female: "donna",
      cross: "X",
      age: "età:",
      aboutYou: "descrizione:"
    },
    settings: {
     settings: "impostazioni",
     language: "lingua",
    },
    driver: {
      tapOnRadar: "premi il pulsante del radar",
      noFriends: "tu non hai amici...qui 😉",
      startRadarFirst: "per favore avvia prima il radar"
    },
    faq: {
      terms: "Termini di servizio",
      privacyPolicy: "Termini privacy",
      whatIsQ: "Cos'è Hidelike?",
      whatIsA: "Con Hidelike puoi conoscere le persone che incontri. Hidelike ti mostra altre persone entro un raggio di 50 metri. Ti piace qualcuno? Dare un Hidelike (Like nascosto). I tuoi Hidelikes sono segreti e nessuno può vederli, a meno che qualcuno non ti dia anche un Hidelike: Ora puoi chattare. Provalo!",
      likeTinderQ: "...lo stesso di Tinder?",
      likeTinderA: "no, al contrario: Incontri altre persone nella vita reale e ti serve solo l'app per rompere il ghiaccio. Ecco perché il radar di Pedro è molto più preciso (50 metro) rispetto a Tinder & Co. (molti chilometri) e tempo reale. Hidelike è gratis e senza pubblicità.",
      realAppQ: "Esiste una vera app?",
      realAppA1: "Puoi trovare Hidelike al Apple App Store e al Google Playstore. Hidelike è molto avanzato (Progressive Web App) e può essere installato direttamente dal browser (non app store) su qualsiasi smartphone o computer: Basta premere il ",
      realAppA2: " (Safari) o ",
      realAppA3: " (Chrome) e installa l'app sul tuo smartphone. Se non vuoi installarlo puoi semplicemente usare hidelike nel tuo browser.",
      notifcationQ: "Ricevo notifiche push?",
      notifcationA: "Solo se vuoi: Hidelike ti chiederà se vuoi essere avvisato in caso di nuovi hidelike, nuove matches o nuovi messaggi.",
      privacyQ: "Cosa succede con i miei dati?",
      privacyA: "Tutti i dati vengono trasmessi SSL-crittografato. Quando si preme il pulsante radar, la tua posizione verrà comunicata al server. Sei tu a decidere quando e dove vuoi condividere la tua posizione con Hidelike. Solo le persone nel raggio di 50 metri possono vedere la tua posizione sull'app...ma queste persone sanno già che sei in questo posto.",
      anonymityQ: "Posso essere anonimo?",
      anonymityA: "Sì, il tuo indirizzo email non verrà mostrato ed è richiesto solo per la registrazione. Se non vuoi mostrare una tua foto, puoi disegnare un avatar. Ma se non mostri un avatar o una foto nel tuo profilo, il tuo profilo non verrà mostrato ad altre persone e non puoi dare hidelikes",
      reHidelikeQ: "Posso ritirare un Hidelike?",
      reHidelikeA: "Sì, premi di nuovo il pulsante Hidelike. Tuttavia, se il tuo nascondiglio ha attivato un match, l'altra persona verrà informata.",
      matchQ: "Cosa succede durante un match?",
      matchA: "Se attivi un match con il tuo Hidelike, Hidelike si trasforma in un cuore e ora puoi chattare. Se non sei nell'app, sarai informato tramite e-mail o una notifica push.",
      blockQ: "Posso bloccare qualcuno?",
      blockA: "Sì, sotto Messaggi troverai un pulsante blocca per ogni persona. Dopodiché, questa persona non potrà più inviarti messaggi tramite chat, verrà eliminata dalla tua lista di amici e hidelike scomparirà. Non appena dai a una persona bloccata un Hidelike e gli scrivi, il blocco verrà nuovamente rimosso.",
      deleteAppQ: "Posso disinstallare Hidelike?",
      deleteAppA: "Se elimini le tue foto, il tuo profilo su Hidelike scomparirà. Ma puoi accedervi quando vuoi. Puoi anche eliminare completamente il tuo profilo (nelle impostazioni).",
      aboutQ: "Chi ha creato Hidelike?",
      aboutA: "Quello ero io, Louis Maag (thehidelike@gmail.com)",
    },
    friends: null,
    gallery: {
      intro: "Scegli le immagini:"
    },
    hidelikecard: {
      youLikeEachOther: "Entrambi vi piacete",
      goForChat: "Chatta tra loro!",
      giveHidelike: "Dare un Hidelike",
      nobodyCanSee: "...nessuno può vederlo",
      load: "caricare"
    },
    intro: {
      intro1: "Benvenuto a Hidelike!",
      intro2a: "Primo,",
      intro2b: "scegli le tue foto",
      intro2c: "o disegna il tuo avatar",
      intro3: "Grazie! Guarda il radar ora!",
      buttonLetMein: "Sì, fammi entrare!",
      buttonPicUpload: "scegli foto",
      buttonCreateAvatar: "disegna avatar",
      uploadYourPictures: "scegli le tue foto",
      createYourAvatar: "disegna il tuo avatar:",
      describeYou: "Chi sei? Dimmi di più su di te...",
      scanNow: "Grazie! Guarda il radar ora!",
      next: "continua"
    },
    langingpage: null,
    login: null,
    profile: {
      profilePictures: "foto del profilo",
      aboutYou: "descrizione",
      settings: "impostazioni",
      yourHidelikeAddress: "il tuo indirizzo Hidelike",
      faq: "Istruzioni (FAQ)",
      deleteMyAccount: "cancella account",
      logout: "disconnettersi"
    },
    profilepicture: {
      intro: "scegli le tue foto o disegna il tuo avatar",
      picturesButton: "foto",
      avatarButton: "avatar"
    },
    register: {
      registerButton: "REGISTRARE",
      termsIntro1: "Se premi Registrati acconsenti ",
      termsIntro2: " e il nostro ",
      termsLink: "termini di servizio",
      privacyLink: "termini privacy "
    },
    popup: {
      yes: "sì",
      no: "no",
      ok: "ok",
      loading: "caricare",
      p1text: "Essere informato a un Match?",
      p2header: "Nessun GPS",
      p2text: "Non consenti il ​​GPS per questa app. Regola le tue impostazioni!",
      p3header: "Nessuna tua foto",
      p3text: "Hai bisogno di una foto da dare a Hidelike. Scegli una foto o disegna un avatar:",
      p4header: "bloccare questa persona?",
      p4button: "bloccare",
      p5header: "Installa l'app?",
      p5text: "...è molto più divertente 😎🤟",
      p5button: "installare",
      p6header: "installare questa app sulla schermata iniziale",
      p6text: "...è molto più divertente 😎🤟",
      p6instruction1: "premere ",
      p6instruction2: " (Safari) o ",
      p6instruction3: " (Chrome)",
      p7header: "Aggiornare...",
      p8text1: "hai ",
      p8text2: " hidelikes  💪",
      p8text3: "hai un hidelike until now 👍",
      p8text4: "non hai hidelikes 😭 ...aspetta...arriveranno il Hidelikes 😎",
      p8text5: "se dai Hidelikess, scoprirai chi è la persona....",
      p9header: "questo è il tuo ultimo radar",
      p9text: "rimani lì o aggiorna la tua posizione",
      p10header: "nessuna match",
      p10text: "scusa, devi aspettare un match per chattare.",
      p11header: "nessuna persona sul radar",
      p11text: "visita la nostra comunità!!",
      p12header: "Contenuto offensivo?",
      p12text: "Si prega di informarci su contenuti inappropriati",
      p12button: "denuncia persona",
      p13header: "Sicuro cancella account?",
      p13text: "tutti i dati verranno cancellati!",
      p13button: "cancella account",
      p14header: "Match con le persone su insta?",
      p14text: "scrivi il tuo indirizzo hidelike sul tuo profilo insta",
      p14button: "vai su Instagram",
      p15header: "Match con le persone su Snap?",
      p15text: "scrivi il tuo indirizzo hidelike sul tuo profilo snap",
      p15button: "vai su Snapchat",
      p16header: "Match con le persone su insta o snap?",
      p16text: "scrivi il tuo indirizzo hidelike sul tuo profilo snap, insta,...!",
      p17headerA: "premere il ",
      p17headerB: " significa:",
      p17text: "Hidelike può mostrare te e le altre persone intorno a te su un radar comune. Vuoi avviare il radar in questa posizione?",
      p18text: "Chi stai cercando?",
      p18selectGender: "sesso?",
      p18selectAllGender: "tutti i sesi",
      p18selectMale: "uomini",
      p18selectFemale: "donne",
      p18selectX: "X",
      p18everyage: "tutte le età",
      p18years: "anni",
      p18resetFilter: "resettare il filtro",
      p19removeFilter: "Per vedere più profili è necessario reimpostare il filtro poiché non tutti gli utenti indicano la loro età o sesso."

    },
  
  },

  french: {
    language: "french",
    avatarcreator: {
      createYourAvatar: "dessine ton avatar",
      save: "enregistrer"},
    chat: {       
      newMatch: "dessine ton avatar",
      blocked: "bloquer",
      typeMessageHere: "écris ton message"
    },
    community: null,
    description: {
      intro: "Qui est tu? Dis moi quelque chose de toi...",
      save: "enregistrer",
      gender: "sexe:",
      undefinedGender: "indéfini",
      male: "homme",
      female: "femme",
      cross: "X",
      age: "âge:",
      aboutYou: "décris-toi:"
    },
    settings: {
      settings: "Réglages",
      language: "Langue",
    },
    driver: {
      tapOnRadar: "appuie sur le bouton radar",
      noFriends: "Tu n'as pas d'amis...ici 😉",
      startRadarFirst: "d'abord démarrer le radar s'il te plait."
    },
    faq: {
      terms: "termes et conditions",
      privacyPolicy: "avis de confidentialité",
      whatIsQ: "Qu'est-ce qu'Hidelike?",
      whatIsA: "Entre en contact avec les gens que tu recontres dans la vie: Sur le Hidelike Radar, tu peux voir des personnes dans un rayon de 50 mètres. Intéressé par quelqu'un? Donne un Hidelike (like secrète). Les hidelikes sont secrets et personne ne peut les voir ...moins que quelqu'un ne t'aime aussi: Et maintenant tu peux chatter en ligne. Essaye-le!",
      likeTinderQ: "…comme Tinder?",
      likeTinderA: "Non, au contraire: Tu rencontres des gens dans la vraie vie et Hidelike n'est qu'un brise-glace pour la première conversation. Le radar d'Hidelike est beaucoup plus précis (50 meter) comparé à Tinder & Co. (plusieurs kilomètres) et temps réel. Hidelike est 100% gratuit et sans adds.",
      realAppQ: "Existe-t-il une app?",
      realAppA1: "Hidelike est à Apple App Store et à Google Playstore. Hidelike es très progressif (Progressive Web Apps) et installable directement du navigateur (non app store) sur smartphone ou sur ordinateur: Appuie just ",
      realAppA2: " (Safari) ou ",
      realAppA3: " (Chrome) et installe l'application sur ton écran. Si tu ne veux pas installe Hidelike, il est possible d'utiliser Hidelike directement à navigateur (Chrome, Safare, ...).",
      notifcationQ: "Est-ce que je reçois des notifications push?",
      notifcationA: "Seulement si tu veux: Hidelike te demandera si tu souhaites être informé d'un match ou d'un nouveau message.",
      privacyQ: "Que se passe-t-il avec mes données?",
      privacyA: "Toutes les données sont transmises sous forme cryptée (SSL). Si tu appuies le bouton de radar ta position sera enregistré. Tu décides quand et où tu veux partager ta position. Seules les personnes à moins de 50 mètres verront ta position...mais ils peuvent aussi te voir sans l'app...",
      anonymityQ: "Puis-je rester anonyme?",
      anonymityA: "Oui, personne ne peut voir votre adresse e-mail et elle n'est utilisée que pour accéder à l'app. Si tu ne vuex pas montrer des photos de toi, tu peux dessiner un avatar. Si tu n'as pas de photo ou d'avatar, tu ne peux pas donner des hidelikes",
      reHidelikeQ: "Puis-je reprendre une hidelike?",
      reHidelikeA: "Oui, il suffit d'appuyer à nouveau sur le bouton. Cependant, si ton hidelike a déclenché une match, l'autre personne sera notifié.",
      matchQ: "Que se passe-t-il en cas d'un match?",
      matchA: "Le bouton hidelike sara rouge et le bouton de chat est actif (jaune). Tu peux mainetant commence une converstation. S'il y a une match, tu reçois une notification push ou un e-mail",
      blockQ: "Puis-je bloquer des personnes?",
      blockA: "Oui, il suffit d'appuyer le boutton pour bloquer une persone (le symbole coeur brisé). Une personne bloquée ne pourra plus t'écrire et sera supprimée de ton liste d'amis. Tu peux re-bloquer une personne par donner un hidelike.",
      deleteAppQ: "Puis-je supprimer mon compte Hidelike?",
      deleteAppA: "Oui, tu peux supprimer Hidelike dans les réglages. Attention : Toutes vos données seront supprimées!",
      aboutQ: "Qui a fait Hidelike?",
      aboutA: "C'est moi...Louis Maag, écris-mois pour des commentaires ou des questions: thehidelike@gmail.com.",
    },
    friends: null,
    gallery: {
      intro: "Choisir des images:"
    },
    hidelikecard: {
      youLikeEachOther: "vous vous aimez!",
      goForChat: "écris un message!!",
      giveHidelike: "donne un Hidelike",
      nobodyCanSee: "...il est secret",
      load: "charge"
    },
    intro: {
      intro1: "Bienvenue à hidelike!",
      intro2a: "Première,",
      intro2b: "choisis les photos",
      intro2c: "ou dessine ton avatar",
      intro3: "Merci! Explore le radar maintenant!",
      buttonLetMein: "Oui!",
      buttonPicUpload: "choisis photos",
      buttonCreateAvatar: "dessine avatar",
      uploadYourPictures: "choisis les photos",
      createYourAvatar: "dessine ton avatar:",
      describeYou: "Qui est tu? Dis moi quelque chose de toi...",
      scanNow: "Merci! Explore le radar maintenant!",
      next: "continue"
    },
    langingpage: null,
    login: null,
    profile: {
      profilePictures: "photos de profil",
      aboutYou: "ta description",
      settings: "réglages",
      yourHidelikeAddress: "ta adress d'Hidelike",
      faq: "instructions (FAQ)",
      deleteMyAccount: "supprimer le compte",
      logout: "déconnexion"
    },
    profilepicture: {
      intro: "choisis photos ou dessine avatar...",
      picturesButton: "photos",
      avatarButton: "avatar"
    },
    register: {
      registerButton: "ENREGISTRER",
      termsIntro1: "Si tu enregistres, tu acceptes ",
      termsIntro2: " et ",
      termsLink: "les termes et les conditions",
      privacyLink: "l'avis de confidentialité "
    },
    popup: {
      yes: "oui",
      no: "non",
      ok: "ok",
      loading: "charge",
      p1text: "notifié moi du match?",
      p2header: "Non GPS",
      p2text: "Tu ne permets pas GPS pour Hidelike. Ajuste les réglages!",
      p3header: "Non photo de toi!",
      p3text: "Tu ne peux pas donner Hidelikes sans photo. Choisis photos ou dessine avatar:",
      p4header: "bloquer cette personne?",
      p4button: "bloquer",
      p5header: "installer app?",
      p5text: "...c'est mieux pour toi 😎🤟",
      p5button: "installer",
      p6header: "installer app?",
      p6text: "...c'est mieux pour toi 😎🤟",
      p6instruction1: "appuie ",
      p6instruction2: " (Safari) ou ",
      p6instruction3: " (Chrome)",
      p7header: "mettre à jour...",
      p8text1: "tu as ",
      p8text2: " hidelikes  💪",
      p8text3: "tu as un hidelike maintenant 👍",
      p8text4: "tu n'as pas de Hidelikes 😭 ...attends...ils viendront 😎",
      p8text5: "donne hidelikes pour savoir qui l'a fait!",
      p9header: "c'est ton dernier radar",
      p9text: "reste là ou mets à jour le radar",
      p10header: "pas de match",
      p10text: "Tu dois attendre un match pour chatter",
      p11header: "personne sur le radar",
      p11text: "regarde dans la community!",
      p12header: "contenu inapproprié?",
      p12text: "informer du contenu inapproprié.",
      p12button: "dénoncer cette personne",
      p13header: "Sûr de supprimer ton compte?",
      p13text: "Toutes les données seront supprimées!",
      p13button: "supprimer compte",
      p14header: "Trouver des amis sur Instagram?",
      p14text: "mets ta adress hidelike à l'instagram",
      p14button: "Continue à Instagram",
      p15header: "Trouver des amis sur Snap?",
      p15text: "mets ta adress hidelike à snapchat",
      p15button: "Continue à Snapchat",
      p16header: "Trouver des amis à Insta ou Snap?",
      p16text: "mets ta adress hidelike à l'instagram, à snap ou...!",
      p17headerA: "appuie sur ",
      p17headerB: " moyens:",
      p17text: "Hidelike peut te montrer toi et d'autres personnes autour de toi sur un radar commun. Est-ce que tu veux démarrer le radar à cet endroit?",
      p18text: "Qui cherches-tu?",
      p18selectGender: "sexe?",
      p18selectAllGender: "tous sexes",
      p18selectMale: "hommes",
      p18selectFemale: "femmes",
      p18selectX: "X",
      p18years: "ans",
      p18everyage: "tous les âges",
      p18resetFilter: "réinitialiser filtre",
      p19removeFilter: "Pour voir plus de profils, tu dois réinitialiser le filtre car tous les utilisateurs n'indiquent pas leur âge ou leur sexe."

    },
  
  }

}