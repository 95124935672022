<template>
  <div>   
    <div class="socialinputs">
      <label class="label" for="gender">{{this.language.description.gender}} </label>
      <select @change="editGender('gender')" class="select" name="gender" id="gender" :value='profile.gender' @keypress.enter.prevent="sendDescription">
        <option value="undefined">{{this.language.description.undefinedGender}}</option>
        <option value="male">{{this.language.description.male}}</option>
        <option value="female">{{this.language.description.female}}</option>
        <option value="x">{{this.language.description.cross}}</option>
      </select>
 
    </div>  
    <div class="socialinputs">
      <label class="label" for="age">{{this.language.description.age}} </label>
      <input @focus="editMode('age')" @click="editMode('age')" type="number" min="10" inputmode="numeric" pattern="[0-9]*" title="Non-negative integral number" class="agefield" name="age" id="age" maxlength="280" :placeholder='profile.age' @keypress.enter.prevent="sendDescription">
    </div>    
    <div class="labelabove">
      {{this.language.description.aboutYou}}
    </div>    
    <div class="socialinputs">
      <textarea @focus="editMode('description')" @click="editMode('description')" class="textfield" name="description" id="description" maxlength="280" :placeholder='profile.description' @keypress.enter.prevent="sendDescription"></textarea>
    </div>
    <div class="socialinputs">
      <div><img class="socialicon" src="../assets/snapchat.svg"></div><br>
      <input type="text" @focus="editMode('snapchat')" @click="editMode('snapchat')" class="snapchat" name="snapchat" id="snapchat" maxlength="30" :placeholder='profile.snapchat' @keypress.enter.prevent="sendDescription">
    </div>
    <div class="socialinputs">
      <div><img class="socialicon" src="../assets/instagram.svg"></div>
      <input type="text" @focus="editMode('instagram')" @click="editMode('instagram')" class="instagram" name="instagram" id="instagram" maxlength="30" :placeholder='profile.instagram' @keypress.enter.prevent="sendDescription">
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { store, mutations } from '@/components/store'


export default {


  data() {
    return {
      user: firebase.auth().currentUser,
      edited: [], //check if the textfield has been edited
    }
  },

    computed: {
      description() {return store.description},
      profile() {return store.profile},
      language() {return store.language},
    },


  methods: {

    sendDescription () {

      let newProfile = {...this.profile}

      for (const key in newProfile) {
        console.log(key)
        if (newProfile.hasOwnProperty(key) && this.edited.includes(key)) {

            newProfile[key] = document.getElementById(key).value
            document.getElementById(key).style.backgroundColor = "rgba(255, 255, 255, 0)"

            if (key == "age") {
                if (isNaN(parseInt(newProfile[key]))) {
                  newProfile[key] = null
                } else {
                  let convertedValue = parseInt(newProfile[key])
                  console.log("converted value: ", convertedValue)
                  
                  let maxValue = 150
                  let minValue = 10
                  if (convertedValue > maxValue) {
                    convertedValue = maxValue
                  }
                  if (convertedValue < minValue) {
                    convertedValue = minValue
                  }
                  newProfile[key] = convertedValue
                }
            }
        } 
      }

      mutations.setDescription(newProfile.description)
      mutations.setProfile(newProfile)
      console.log("new profile: ", newProfile)

      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({
        description: newProfile.description,
        profile: newProfile
      })
       
      this.edited = [] 
    },

    
    editMode (fieldName) {

     if (!this.edited.includes(fieldName)) {
        console.log("fieldname: ", fieldName)
        this.edited.push(fieldName)
        let textref = document.getElementById(fieldName)
        textref.style.backgroundColor = "rgba(255, 255, 255, 0.350)"
        textref.placeholder = ''
        textref.value = this.profile[fieldName] // adding latest description as default text
        textref.scrollIntoView()
     } 
    },

        editGender (fieldName) {

     if (!this.edited.includes(fieldName)) {
        console.log("fieldname: ", fieldName)
        this.edited.push(fieldName)
        let textref = document.getElementById(fieldName)
        textref.scrollIntoView()
     } 
    },
    
  }
}

</script>

<style scoped>
.introContainer {
    display: block;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.473); /* Black background with opacity */
    z-index: 9000; 
    padding: 20px;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 42px;
  border-radius: 15px;
  font-size: 18px;
  width: 150px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.label {
  text-align: left;
  font-size: 18px;
  height: 42px;
  padding-top: 0.5rem;
  width: 150px;
  color: white

}

.agefield {
  justify-content: center;
  font-size: 18px;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 42px;
  border-radius: 15px;
  width: 150px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.textfield {
  justify-content: center;
  font-size: 18px;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 100px;
  border-radius: 15px;
  width: 90vw;
  max-width: 400px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
  font-size: 18px;
}

.socialinputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 5vw;
  margin-right: 5vw
}

.labelabove {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: left;
  font-size: 18px;
  color: white
}


.socialicon {
  height: 30px;
  margin-right: -50px;
  margin-top: 7px;
  margin-left: 10px
}

.snapchat {
  font-size: 18px;
  border: 2px solid rgb(255, 255, 255);
  height: 42px;
  border-radius: 15px;
  width: 90vw;
  max-width: 400px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 50px;
  padding-right: 10px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
  font-size: 18px;
  overflow-y: scroll;
}

.instagram {
  font-size: 18px;
  border: 2px solid rgb(255, 255, 255);
  height: 42px;
  border-radius: 15px;
  width: 90vw;
  max-width: 400px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  overflow-y: scroll;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
  font-size: 18px;
}

.savebutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonfont {
font-weight: bold;
font-size: 16px
}

</style>


