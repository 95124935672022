<template>
  <v-app id="driver">
    <div class="mainscreen" id="mainscreen">
      
      <!-- top navbar-->
      <div class="topbar">
        <div class="topleft">
          <img v-if="profileImage != 0" class="profileButton" v-on:click="profileDialogOn()" :src="profileImage">
          <img v-else class="profileButtonDefault" v-on:click="profileDialogOn()" src="../assets/profile.svg">
        </div>
        <div>
          <div v-if="!online" class="offline">
            offline
          </div>  
          <div v-else-if="this.screenState == 4" class="filter_button_container"  v-on:click="openPopup(18)">
            <img v-if="settings.searchFilter.filter" class="filter_button" src="../assets/filterfilled.svg">
            <img v-else class="filter_button" src="../assets/filter.svg">
          </div>  
        </div>
        <div class="topright">
          <button @click="openPopup(8)" class="hidelikecounter">
            <img class="tile_button" src="../assets/hidelike.svg">
            <div class="counterfigure">{{this.counterFigure}}</div>
          </button>
        </div>
      </div>
      
      <!-- radar screen -->
      <div class="scanner" id="scanner" v-show="this.screenState == 3">
        <div class="radarmap">
          <img id="heart" class="heart" src="../assets/heart-radar.svg">
          <canvas class="scannercanvas"  ref="radarcanvas" width="300" height="300"></canvas>
        </div>
        <div class="centerwrapper">
          <button v-show="!radarAnimationState" id="heading" class="headingButton" @click="startHeading()">live radar {{this.lastRadiusInfo}}</button>
        </div>
      
        <div class="radardisplay" >
          <div class="usertable" >
            <div  v-for="item in winesSelected" v-bind:key="item.id"> 
                <button v-if="item.img != 0" @click="dialogOn(item)">
                  <div v-if="item.badge" class="badgeRadar">new 50m</div>
                  <div v-else class="badgeRadartransp"></div>
                  <img class="radar_cards" loading="lazy" :src="item.thumb">
                </button>
            </div>
          </div>
        </div>
      </div>

      <!-- community screen -->
      <keep-alive>
      <community v-show="this.screenState == 4"
          ref="communityComponent"
          :lat="lat"
          :lng="lng"
          :nousersfound="nousersfound"
          :screenState="screenState"
          :lastScanTime="lastScanTime"
          :settings="settings"
          @dialogOn="dialogOn"
          @openPopup="openPopup"
          @initCommunity="initCommunity"
        >
      </community>
      </keep-alive>

      <!-- friends screen -->
      <keep-alive><friends v-if="this.screenState == 2"
        :unreadslistener="unreadslistener"
        >
      </friends></keep-alive>

      <!-- intro instruction -->
      <div v-if="this.screenState == 1" class="introinstruction">
        <div class="introinstructiontext">
        {{this.language.driver.tapOnRadar}}
        </div>
        <img class="iconinline" src="../assets/hidelike-scan-grey.svg" alt="">
      </div>

    <!-- bottom nav bar -->
    <div class="centerwrapper">
      <div class="navbar">
        <div class="bottomwrapper">

          <div class="bottombuttons">
            <button @click="initFriends">
              <div v-if="this.unreadslistener.length>0" class="badge">{{this.unreadslistener.length}}</div>
              <img class="navbuttons" v-if="screenState == 2" src="../assets/contacts_select.svg" height="25">
              <img class="navbuttons" v-else src="../assets/contacts.svg" height="25">
            </button>
          </div>

          <div class="bottombuttons">
            <div v-if="!loadingstate">
              <button @click="locationPermission" class="scanbutton">
                <img class="buttonicon" src="../assets/hidelike-scan.svg">
              </button>
            </div>
            <div v-else>
              <button class="scanbutton">
                <img class="buttonicon pulsate" src="../assets/hidelike-scan.svg">
              </button>
            </div>
          </div>

          <div class="bottombuttons">
            <button v-if="screenState == 4" @click="refreshCommunity">
              <img class="navbuttons"  src="../assets/community_select.svg" height="25">
            </button>
            <button v-else @click="initCommunity">
              <img class="navbuttons"  src="../assets/community.svg" height="25">
            </button>
          </div>

        </div>
      </div>
    </div>
    
    <!-- dialog for faq -->
    <faq id="faq" class="faq_dialog"
      @showFaq="showFaq()"
    >
    </faq>


    <!-- generic popup component-->
    <popup 
      ref="popupComponent"
      id="popup"
      :popupState="popupState" 
      :routingState="routingState"
      :likeslistener="likeslistener"
      :user="user"
      class="popup" 
      @openPopup="openPopup()"
      @PushOptIn="PushOptIn()"
      @profileDialogOn="profileDialogOn()"
      @blockChat="blockChat()"
      @installPwa="installPwa()"
      @reportUser="reportUser()"
      @refreshCommunity="refreshCommunity()"
      @setGeoPermission="setGeoPermission"
      @getAnalytics="getAnalytics"
    >
    </popup>

      <!-- show other users' profiles-->
      <hidelikecard id="friends_dialog" class="friends_dialog"
        :selectedUser="selectedUser"
        :selectedImage="selectedImage"
        :selectedGallery="selectedGallery"
        :selectedProfile="selectedProfile"
        :selectedDescription="selectedDescription"
        :likeslistener="likeslistener"
        @close="dialogOff()"
        @openPopup="openPopup"
      >
      </hidelikecard>

    </div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import Avataaars from 'vuejs-avataaars'
import faq from '@/components/faq'
import popup from '@/components/popup'
import profile from '@/components/profile'
import community from '@/components/community'
import hidelikecard from '@/components/hidelikecard'
import friends from '@/components/friends'
import { store, mutations } from '@/components/store'

const geofire = require('geofire-common');

export default {

    components: {
    Avataaars,
    faq,
    popup,
    profile,
    community,
    hidelikecard,
    friends
  },

  data() {
    return {

      // popup states:
        // 0 = no popup, 
        // 1 = opti in push notifications, 
        // 2 = no GPS, 
        // 3 = no profile pic, 
        // 4 = block user question
        // 5 = install pwa
        // 6 = add to homescreen
        // 7 = updating app
        // 8 = hidelike counter info
      popupState: 0, // default 0

      // states 
      screenState: 0, // 1 = intro, 2 = fiends, 3 = scanner, 4 = community
      newbiestate: null,
      radarAnimationState: false,
      online: navigator.onLine, //onlinestate
      lastScanTime: 0,
      routingState: 0,
       
      // user data
      user: firebase.auth().currentUser,
      lat: 0,
      lng: 0,

      // scanning
      scanRadius: 100,
      lastScanRadius: 100,
      lastRadiusInfo: 100,
      intervalRadius: [
        100, 
        200, 
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
      ],
      wines: [],
      winesProv: [],
      community: false,
      nousersfound: false,
      loadingstate: false,

      // radar screen
      lastScan: [], // var to catch last 50m-scan-results
      lastScanLocal: [], // var to catch every last scan result to prevent duplicated keys out of the radarListener
      radarcanvas: null,
      heading: 0,
      headingDiff: 0,
      circCoords: [],
      winesSelected: [],
      selectedIds: [],
      filterset: false,
      toCanvas: [],

      // likes
      likesStorage: [],
      likeslistener: [],
      unsubscribeLikes: '',

      // messaging (chatswith can be used to show matches)
      unreadslistener: [],
      unsubscribeUnreads: '',

      // scanned profiles
      selectedUser: [],
      selectedImage: null,
      selectedGallery: [{imgurl: 0,uid: 0},{imgurl: 0,uid: 0},{imgurl: 0,uid: 0}],
      selectedDescription: '',
      selectedProfile: {},

      // user profile
      uploadValue: 0,
      description: '',

      // push notifications
      notificationToken: null,
      deviceToken: null,
      firstLikeDone: false,
      pushAvailable: false,

      // service worker & release management
      refreshing: false,
      registration: null,
      version: {version: 1.69, os: "web"},
      release: {version: 1.69, os: "web"},

    }
  },

    computed: {   
    language() {return store.language},
    likes() {return store.likes},
    chatswith() {return store.chatswith},
    installEvent() {return store.installEvent},
    profileImage() {return store.profileImage},
    urlState() {return store.urlState},
    settings() {return store.settings},

    // filter search results
    filteredItems () {
      if (this.filterset == false) {return this.paginatedData} /// if filterset is switched "off" show full wines array (old code: {return this.wines})
      else {return this.winesSelected} 
    },

    // calculates counter figure to reduce figures displayed to the user likeslistener.length
    counterFigure () {
      if (this.likeslistener.length < 1000) {
        return this.likeslistener.length
      } else if (this.likeslistener.length < 10000) {
        return (this.likeslistener.length/1000).toFixed(1)+"K"
      } else {
        return "10K+"
      }

    }

  },


  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },


  mounted() {

    firebase.auth().onAuthStateChanged(
      user => {
        this.user = user;
        this.getUserData()
        this.initUnreadsListener()
        this.initRadarListener()
      }
    )

    // check if push notification is supported and save to var "pushAvailable"
    if (('PushManager' in window)) {
      mutations.setPushAvailable(true)
    }

    // the nextTick is required to make sure that DOM is ready
    this.$nextTick(() => { 
      this.initRadarCanvas()
    })

    // checking network state
    window.addEventListener("online", e => {this.networkState()})
    window.addEventListener("offline", e => {this.networkState()})
    this.networkState()

  },

  activated() {
       /*  Documentation of URL params catcher
   URL params are caught on landingpage, login and driver
   and on each routing (via main.js). The catching on each route is required
   as routing might override the param included in the URL.

   URL params are saved in global store (except a URL state caught from driver is save locally only)

   global URL params from the store is resetted by leaving the hidelicard element
   
   URL-schema: /?id=nWPcYqEwe8a9IW1Ui2EuPub81953
   */

    //get local URL state of driver
    let params = new URLSearchParams(document.location.search);
    let paramId = null

    // check if a URL state is available in store and if not if an
    // URL param can be caught from this url
    if (this.urlState != null) {
      paramId = this.urlState
    } else {
      paramId = params.get("id")
    }

    // if param from URL is not NULL the hidelike card is shown
    if (paramId != null) {
      const db = firebase.firestore()
      db.collection('users').doc(paramId)
      .get()
      .then(
        (doc) => {   
          if (doc.exists) {  
            const data = {
              'id': doc.id,
              'geohash': doc.data().geohash,
              'img': doc.data().imgurl,
              'imgurlGallery': doc.data().imgurlGallery,
              'profile': doc.data().profile,
              'thumb': doc.data().imgurl,
              'description': doc.data().description,
              'updated': doc.data().updated,
              'lastScanResult': doc.data().lastScanResult,
              'badge': false,
              'lat': doc.data().lat,
              'lng': doc.data().lng
            }
            this.dialogOn(data)
            this.getAnalytics('viaDirectLink', true)
          } else {
             console.log("document from param not found")
          }
        }
      )

    } else {
      console.log("no params from URL")
    }

  },

  methods: {

    getAnalytics(key, value) {

      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({
        [`analytics.${key}`]: value
      })

    },


    networkState () {//onlinestate

      this.online = navigator.onLine

    },

    reportUser() {
      const db = firebase.firestore()
      
      db.collection("contentreports")
      .add({
        reportedUid: this.selectedUser.id, 
        reporter: this.user.uid,
        timeOfreport: firebase.firestore.FieldValue.serverTimestamp()
      })    

    },

    profileDialogOn () {
      this.$router.push(
        {
          name: "profile",
          params: {
            deviceToken: this.deviceToken,
            notificationToken: this.notificationToken
          }
        }
      )
    },

    openPopup (state, routing) {
    
      const popupState = (document.getElementById("popup").style.display == "block")

      if (!popupState && state != undefined) {
        this.popupState = state
        this.routingState = routing
        document.getElementById("popup").style.display = "block"
      } else {
        document.getElementById("popup").style.display = "none"
        this.popupState = 0
      }
    },


    getUserData () {
      const db = firebase.firestore()
      
      db.collection('users').doc(this.user.uid)
      .get()
      .then(
        (doc) => {   
          if (doc.exists) {  
            this.lat = doc.data().lat
            this.lng = doc.data().lng
            this.newbiestate = doc.data().newbiestate
            this.description = doc.data().description
            this.notificationToken = doc.data().notificationToken
            this.likesStorage = doc.data().likesStorage

            if (this.lat == 0) {this.screenState = 1}

            mutations.setNewbiestate(doc.data().newbiestate)
            mutations.setProfileImage(doc.data().imgurl)
            mutations.setDescription(doc.data().description)
            mutations.setLikes(doc.data().likes)
            
            // if avatar data has been defined the values are loaded into store
            if (doc.data().avatar != undefined) {
              this.avatardata = doc.data().avatar
              mutations.setAvatar(doc.data().avatar)
            }

            // this.likesStorage is set to empty array ([]) in case of inexistence in DB as otherwise
            // further functions prompt an error
            if (doc.data().likesStorage != undefined) {mutations.setLikesStorage(doc.data().likesStorage)}

            // this.chatswith is set to empty array ([]) in case of inexistence in DB as otherwise
            // further functions prompt an error
            if (doc.data().chatswith != undefined) {mutations.setChatswith(doc.data().chatswith)}

            // get Order of Friendslist if not undefined
            if (doc.data().chatsOrder != undefined) {mutations.setChatsOrder(doc.data().chatsOrder)}

            // catching the case where the db field does not exist (applies for existing user too which is good to launch the push notes)
            if (doc.data().firstLikeDone != undefined) {
              mutations.setfirstLikeDone(doc.data().firstLikeDone)
            }

            // available profilepictures
            // if the Gallery is available it's fully imported, else the profile-pic is imported
            if (doc.data().imgurlGallery != undefined) {mutations.setImgurlGallery(doc.data().imgurlGallery)} 
            else {mutations.setImgurlGallery(false)}

            // available profile information
            // if the profile is available it's fully imported, else the description value is imported
            if (doc.data().profile != undefined) {mutations.setProfile(doc.data().profile)} 
            else {mutations.setProfile(false)}

            // available settings information
            // if the setting is available it's fully imported, else the default from the store applies
            if (doc.data().settings != undefined) {mutations.setSettings(doc.data().settings)} 


            // check first if the user has already done 1 like and therefore already seen the 
            // soft-opt-in for Push-Notifications
            // it the user hat not done his first like the savelikestoarray funciton triggers the soft-opt in dialog on first like
            if (doc.data().firstLikeDone) {
              this.initPushNotifications()
            }
              
            /// the initlikeslistener should be runned after getUserData as some calculations are made
            /// with variables from the users' DB Profile
            this.initLikesListener()

            if (this.lat !== 0) {
              this.openPopup(9)
              this.getData(this.lat, this.lng) 
            }
              
          } else {
              setTimeout(() => {
              this.getUserData()
            }, 2000) 
          }
        }
      )
    },

    installPwa () {
      // close popup from internal prompt first
      this.openPopup()

      // install pwa
      if (this.installEvent != null) {
        this.installEvent.prompt()
      }

      mutations.setNewbiestate(false)
      
      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({newbiestate: false})

    },

    updateAvailable(event) {
      // Store the SW registration so we can send it a message
      // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
      // To alert the user there is an update they need to refresh for
      this.registration = event.detail
      this.openPopup(7)
      setTimeout(() => {
        this.openPopup()
        this.refreshApp()
      }, 1000) 
    },

    // Called when the user accepts the update
    refreshApp() {

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      
    },

    initRadarCanvas () {
    // triggered from mounted () hook after nextTick
      
      // define canvas and get context for radar feature
      const canvas = this.$refs.radarcanvas
      this.radarcanvas = canvas.getContext("2d")

      // move canvas for center-oriented rotation
      this.radarcanvas.translate(canvas.height/2,canvas.width/2)

      // add listener to get clicks on canvas points
      canvas.addEventListener('click', (e) => {
        const rect = canvas.getBoundingClientRect()
        const pos = {
          x: e.clientX - rect.left - (canvas.width/2),
          y: e.clientY - rect.top - (canvas.width/2),
        }

        // flush array for selected items
        this.winesSelected = []
        this.selectedIds = []
        
        // check whether click touches one of the collected point-coordinates
        this.circCoords.forEach(circle => {

          if (Math.sqrt((pos.x - circle.x) ** 2 + (pos.y - circle.y) ** 2) < 40) { ///change last value for setting tap range
            
            this.filterset = true
            
            const pushCord = {
              id: circle.id,
              thumb: circle.thumb,
              imgurlGallery: circle.imgurlGallery,
              profile: circle.profile,
              badge: circle.badge,
              description: circle.description
            }

             this.winesSelected.push(pushCord)
             this.selectedIds.push(pushCord.id)
          
            this.radar()
            
          } 
        })
      })
    },

    startHeading () {

      document.getElementById("heading").style.background = "#f5416c"
      document.getElementById("heading").style.color = "white"

      const isIOS = (navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/))

      const doAvailable = (typeof DeviceMotionEvent.requestPermission === 'function')

      if (isIOS && doAvailable) {
        
        DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              window.addEventListener("deviceorientation", e => {
                
                this.heading = Math.round(e.webkitCompassHeading*-1)
                
                // before rotating canvas according to compass data it has to be checked whether
                // a radarAnimation is running and if so no update of the cavas should happen as
                // to avoid disruption of animation
                if (this.headingDiff != this.heading && !this.community && !this.radarAnimationState) {
                    
                  this.radar()
                }
              })
            } else {
              alert("has to be allowed!")
            }
          })
          .catch(() => alert("error!"))
      } else {
        window.addEventListener("deviceorientationabsolute", e => {    

          this.heading = Math.round(Math.abs(360-e.alpha)*-1)

          // before rotating canvas according to compass data it has to be checked whether
            // a radarAnimation is running and if so no update of the cavas should happen as
            // to avoid disruption of animation
          if (this.headingDiff != this.heading && !this.community && !this.radarAnimationState) {
                    
              this.radar()
          }
        })
      }
    },

    scanAnimationCounter () {

      if (!this.radarAnimationState) {
        this.screenState = 3
        this.radarAnimationState = true
  
        this.scanAnimation(), 

        setTimeout(() => 
          this.scanAnimation(1), 
          100
        )


        setTimeout(() => 
          this.scanAnimation(2), 
          200
        )

        setTimeout(() => 
          this.scanAnimation(3), 
          300
        )

        setTimeout(() => 
          this.scanAnimation(4), 
          400
        )

        setTimeout(() => 
          this.scanAnimation(5), 
          500
        )

        setTimeout(() => 
          this.scanAnimation(6), 
          600
        )

        setTimeout(() => 
          this.scanAnimation(7), 
          700
        )

        setTimeout(() => {
            if (this.loadingstate) {
              this.radarAnimationState = false
              this.scanAnimationCounter()}
            else {
              this.radarAnimationState = false
              if (this.nousersfound) {
                this.initCommunity()
                this.openPopup(11)
              } else {
              this.radar()
              }
            }
          }
          , 
          800

        )
      }
    },

    scanAnimation (step) {

        // define canvas and get context
        const canvas = this.$refs.radarcanvas
        this.radarcanvas = canvas.getContext("2d")

        // define zoom factor
        const zoom = 0.6

        // define canvas value to be processed
        const canvasWidth = canvas.width*zoom

        // define mid of canvas axis
        const canvasMidX = canvas.width / 2
        const canvasMidY = canvas.height / 2

        // clear canvas before drawing; old: this.radarcanvas.clearRect(-150, -150, 300, 300)
        this.radarcanvas.clearRect(-canvasMidX, -canvasMidY, canvas.width, canvas.height)

        // reset line width
        this.radarcanvas.lineWidth = 1

        // save this rotation for re-rotate canvas on next run
        this.headingDiff = this.heading

        // draw heart in the middle of canvas
        var img = document.getElementById("heart");
        this.radarcanvas.drawImage(img, -30, -30, 60, 60);

        // circle 1 around cross
        if (step >= 1) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.30, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f3ced7'
          this.radarcanvas.lineWidth = 8
          if (step >= 2) {this.radarcanvas.globalAlpha = 1/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }
        // circle 2 around cross
        if (step >= 2) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.375, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f3ced7'
          this.radarcanvas.lineWidth = 8
          if (step >= 3) {this.radarcanvas.globalAlpha = 2/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }

        // circle 3 around cross
        if (step >= 3) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.45, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f4d1d6'
          this.radarcanvas.lineWidth = 8
          if (step >= 4) {this.radarcanvas.globalAlpha = 3/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }

        // circle 4 around cross
        if (step >= 4) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.525, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f4d1d6'
          this.radarcanvas.lineWidth = 8
          if (step >= 5) {this.radarcanvas.globalAlpha = 4/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }

        // circle 5 around cross
        if (step >= 5) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.60, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f4dad2'
          this.radarcanvas.lineWidth = 8
          if (step >= 6) {this.radarcanvas.globalAlpha = 5/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }

        // circle 6 around cross
        if (step >= 6) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.675, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f4dad2'
          this.radarcanvas.lineWidth = 8
          if (step >= 7) {this.radarcanvas.globalAlpha = 6/step} else {this.radarcanvas.globalAlpha = 1}
          this.radarcanvas.stroke()
        }

        // big circle 7 around cross
        if (step == 7) {
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(0, 0, canvasWidth*0.75, 0, 2 * Math.PI)
          this.radarcanvas.strokeStyle = '#f5dfd0'
          this.radarcanvas.lineWidth = 8
          this.radarcanvas.globalAlpha = 1
          this.radarcanvas.stroke()
        }
    
    },
    
    radar () {
      // note that the canvas is drawed on mounted () instance when launching the site

      // reset array of collected point-coordinates
      this.circCoords = []

      // merge this user's position with scanned user
      // (only for relative calculations; eg. axis spread)
      const centerOfUser = [{lat: this.lat, lng: this.lng, id: "center"}]
      const scaleDefine = [...this.toCanvas, ...centerOfUser] // before this.wines

      // define spread of x-axis
      const xAxisMax = Math.max.apply(Math, scaleDefine.map(o => o.lat))
      const xAxisMin = Math.min.apply(Math, scaleDefine.map(o => o.lat))
      const xAxisSpread = xAxisMax - xAxisMin

      
      // define spread of y-axis
      const yAxisMax = Math.max.apply(Math, scaleDefine.map(o => o.lng))
      const yAxisMin = Math.min.apply(Math, scaleDefine.map(o => o.lng))
      const yAxisSpread = yAxisMax - yAxisMin

      // define mid of axis
      const xaxisMid = xAxisSpread / 2
      const yaxisMid = yAxisSpread / 2

      // define canvas and get context
      const canvas = this.$refs.radarcanvas
      this.radarcanvas = canvas.getContext("2d")

      // define zoom factor
      const zoom = 0.6

      // define canvas value to be processed
      const canvasWidth = canvas.width*zoom
      const canvasHeight = canvas.height*zoom

      // define mid of canvas axis
      const canvasMidX = canvas.width / 2
      const canvasMidY = canvas.height / 2

      // clear canvas before drawing; old: this.radarcanvas.clearRect(-150, -150, 300, 300)
      this.radarcanvas.clearRect(-canvasMidX, -canvasMidY, canvas.width, canvas.height)

      // reset line width
      this.radarcanvas.lineWidth = 1

      // save this rotation for re-rotate canvas on next run
      this.headingDiff = this.heading

      // value for radius of points to be drawed
      const arcRadius = 20

      // draw radar cross on canvas
      /// vertical line of cross
/*    this.radarcanvas.beginPath();
      this.radarcanvas.moveTo(0, -canvasHeight);
      this.radarcanvas.lineTo(0, canvasHeight);
      this.radarcanvas.strokeStyle = 'white'
      this.radarcanvas.stroke() */

      // horizontal line of cross
/*    this.radarcanvas.beginPath();
      this.radarcanvas.moveTo(-canvasHeight, 0);
      this.radarcanvas.lineTo(canvasHeight, 0);
      this.radarcanvas.strokeStyle = 'white'
      this.radarcanvas.stroke() */

      // big circle 1 around cross
      this.radarcanvas.beginPath()
      this.radarcanvas.arc(0, 0, canvasWidth*0.75, 0, 2 * Math.PI)
      this.radarcanvas.strokeStyle = '#f5dfd0'
      this.radarcanvas.lineWidth = 8
      this.radarcanvas.stroke()

      // big circle 2 around cross
      this.radarcanvas.beginPath()
      this.radarcanvas.arc(0, 0, canvasWidth*0.60, 0, 2 * Math.PI)
      this.radarcanvas.strokeStyle = '#f4dad2'
      this.radarcanvas.lineWidth = 8
      this.radarcanvas.stroke()

      // big circle 3 around cross
      this.radarcanvas.beginPath()
      this.radarcanvas.arc(0, 0, canvasWidth*0.45, 0, 2 * Math.PI)
      this.radarcanvas.strokeStyle = '#f4d1d6'
      this.radarcanvas.lineWidth = 8
      this.radarcanvas.stroke()

      // big circle 4 around cross
      this.radarcanvas.beginPath()
      this.radarcanvas.arc(0, 0, canvasWidth*0.30, 0, 2 * Math.PI)
      this.radarcanvas.strokeStyle = '#f3ced7'
      this.radarcanvas.lineWidth = 8
      this.radarcanvas.stroke()

/*       // big circle 5 around cross
      this.radarcanvas.beginPath()
      this.radarcanvas.arc(0, 0, canvasWidth*0.15, 0, 2 * Math.PI)
      this.radarcanvas.strokeStyle = '#f4d6d4'
      this.radarcanvas.lineWidth = 7
      this.radarcanvas.stroke() */

      // draw heart in the middle of canvas


  var img = document.getElementById("heart");
  this.radarcanvas.drawImage(img, -30, -30, 60, 60);


      // transform world coordinates from scan results to canvas coordinates,
      // draw to canvas and save to array for click listener
      this.toCanvas.forEach(
        item => {

          //transform from world latitude ccordinate to canvas coordinate
          const xCord = canvasMidX + (canvasWidth/xAxisSpread*(item.lat-xaxisMid-xAxisMin)) - (canvas.width / 2)

          //transform from world longitude ccordinate to canvas coordinate
          const yCord = canvasMidY + (canvasHeight/yAxisSpread*(item.lng-yaxisMid-yAxisMin)) - (canvas.height / 2)

          // rotate each point according to angele defined from compass or -if not applicable- to 0 or -360
          let angle = ((this.heading-90)* Math.PI / 180)/// the -90 is to correct a failure which I frankly don't understand so far
          let x2 = xCord
          let y2 = yCord
          let cos = Math.cos(angle);
          let sin = Math.sin(angle);
          let newx = x2*cos - y2*sin
          let newy = x2*sin + y2*cos

          // draw points on canvast
          this.radarcanvas.beginPath()
          this.radarcanvas.arc(newx, newy, arcRadius, 0, 2 * Math.PI)

          if (item.badge) {
            this.radarcanvas.fillStyle = '#f5416c'
          } else {
            this.radarcanvas.fillStyle = '#fecc34'
          }

          // check if dots are part of the seleciton and colorize respecitve
          if (this.selectedIds.includes(item.id)) {
            this.radarcanvas.strokeStyle = '#1eb2a6'
            this.radarcanvas.lineWidth = 3

          } else {
            this.radarcanvas.strokeStyle = '#f3f1f2'
            this.radarcanvas.lineWidth = 3
          }
   
          //this.radarcanvas.strokeStyle = 'grey'
          this.radarcanvas.fill()
          this.radarcanvas.stroke()

          // collect coordinates of points for click-listener
          const poscord = {
            x: newx,
            y: newy,
            id: item.id,
            thumb: item.thumb,
            imgurlGallery: item.imgurlGallery,
            profile: item.profile,
            badge: item.badge,
            description: item.description
          }
          this.circCoords.push(poscord)
        }
      )
    },

    initLikesListener () {

      const db = firebase.firestore()
      
      // a forEach run over the likeslistener array of this users' profile is made to
      // check which user has been liked by this user already (= match). Note that
      // any match is stored under the chatswith variable (actually for any match
      // a chatroom is initiated for both users with all corresponding DB entries
      // and a message is sent to this user to inform about the match as he might
      // not have been present to check it).

      // check if document exists first
      db.collection('likes').doc(this.user.uid).get()
      .then(
        (docSnapshot) => {
          if (docSnapshot.exists) {

            this.unsubscribeLikes = db.collection('likes').doc(this.user.uid)
            .onSnapshot(
              (doc) => {     

                // set the likedby to an empty array if the assigned value form DB is "undefined"
                // to avoid any errors on further calculations
                if (doc.data().likedby == undefined) {this.likeslistener = []} else {this.likeslistener = doc.data().likedby}

                // looping through each element of the likedby array
                this.likeslistener.forEach(
                  user => {
                    
                    const likedby = user

                    /// check the other user who liked this user has already been liked
                    // by this user and is not part of the chatswith array
                    if (this.likes.includes(likedby) && !this.chatswith.includes(likedby)) {   /// && !this.matcheslistener.includes(likedby)

                      // if the other user has been liked by this user already the other User ID is pushed
                      // to the this.chatswith (to avoid another adding by the if-then-check above)
                      mutations.pushChatswith(likedby)

                      // generate chatid by combining the two user ids (as made when the chat was built by the user who fired the match)
                      const channelid = this.user.uid > likedby ? likedby + this.user.uid : this.user.uid + likedby

                      // update this users' profile with the information about the
                      // new created chat
                      db.collection("users")
                      .doc(this.user.uid)
                      .update({ 
                        chats: firebase.firestore.FieldValue.arrayUnion(channelid),
                        chatswith: firebase.firestore.FieldValue.arrayUnion(likedby)
                      })

                      // update the unreadslistener
                      //(this is only required for cases where the other user has an old version of HL, 
                      // where the savelikestoArray does not send e message and does therefore not trigger
                      // the new messages sign at this users UI)
/*                       db.collection("unreads")
                      .doc(this.user.uid)
                      .update({ unreadfrom: firebase.firestore.FieldValue.arrayUnion(likedby)})
 */
                    }
                  }
                )
              }
            )
          } else {
            setTimeout(() => 
              this.initLikesListener(), 
              2000
            )
          }
        }
      )     
    },

    initUnreadsListener() {

      const db = firebase.firestore()

      // check if document exists in collection unreads
      db.collection('unreads').doc(this.user.uid).get()
      .then(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            
            this.unsubscribeUnreads = db.collection('unreads').doc(this.user.uid)
            .onSnapshot(
              (doc) => {     
              this.unreadslistener = doc.data().unreadfrom
              }
            )
          } else {
            setTimeout(() => 
              this.initUnreadsListener(), 
              2000
            )
          }
        }
      )
    },

    initRadarListener() {
      
      // initialize listener to get updates in case a new user enters the 50m-range

      const db = firebase.firestore()
      
      // check if document exists in collection radarUpdate
      db.collection('radarUpdate').doc(this.user.uid).get()
      .then(
        (docSnapshot) => {
          if (docSnapshot.exists) {

            // reset array on initializing as getData() has runned after mounted()
            // and therefore all results should be up to date
            // without wiping data here a duplication of keys might occur as users
            // are pushed from getData() and from this listener
            db.collection("radarUpdate")
            .doc(this.user.uid)
            .set({ radar: []})

              // starting listener to this users' radarUpdate-field
              db.collection('radarUpdate').doc(this.user.uid)
              .onSnapshot(
                (doc) => {

                  //on any update of the field the array from radarUpdate is looped to get data
                  // from users DB for all new users in this area 
                  doc.data().radar.forEach(
                    userid => {
                      db.collection('users').doc(userid)
                      .get()
                      .then(
                        (userdata) => { 
                          const data = {
                            'id': userdata.id,
                            'img': userdata.data().imgurl,
                            'imgurlGallery': userdata.data().imgurlGallery,
                            'profile': userdata.data().profile,
                            'thumb': userdata.data().imgurl,
                            'description': userdata.data().description,
                            'updated': userdata.data().updated,
                            'badge': true,
                            'lat': userdata.data().lat,
                            'lng': userdata.data().lng
                          }

                          // check last updated field for undefined values
                          if (userdata.data().updated == undefined) {data.updated = 0} else {data.updated = userdata.data().updated.valueOf()}

                          // check if user is not already part of the last scan (which would lead to duplicated keys)
                          if (!this.lastScanLocal.includes(data.id)) {

                            // push data of each new user to selected wines 
                            this.winesSelected.unshift(data)

                            // push data of each new user to selected wines
                            this.toCanvas.push(data)
                            this.radar()

                            // update lastScanLocal var to save new users and prevent looping of the snapshotlistener
                            this.lastScanLocal.push(data.id)

                            // remove every new user from the radarUpdate to avoid another listing
                            db.collection("radarUpdate")
                            .doc(this.user.uid)
                            .update({radar: firebase.firestore.FieldValue.arrayRemove(userdata.id)})

                            // update this user's last scan result with the just added new users to avoid 
                            // another triggering in case one of these new users performs another scan at the same
                            // place
                            db.collection("users")
                            .doc(this.user.uid)
                            .update({
                              lastScanResult: firebase.firestore.FieldValue.arrayUnion(userdata.id)
                            })
                          }
                        }
                      )
                    }
                  )
                }
              )
          } else {
            
            // if document does not exist it's created and with a time delay the lister is restarted
            db.collection("radarUpdate")
            .doc(this.user.uid)
            .set({ radar: []})

            setTimeout(() => {
              this.initRadarListener()
            }, 5000) 
          }
        }
      )
    },

    initPushNotifications () {

    /// initialize Push Notifications ///

    // check if push is supported //

      if (('PushManager' in window)) {
  
        console.log("push supported")

        /// init firebase messaging ///
        const messaging = firebase.messaging()

        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken({ vapidKey: 'BF8RJcgDMYFhx_RuX7jEBWe1Z0Je7auoHFhM9C12EaiGmTSReNtAtl2o5Id_Oi0WYI8QPiuKbawpgpom0mVOxtQ' })
        .then((currentToken) => {
          if (currentToken) {

            this.deviceToken = currentToken

            // if field for token does not exist a field is set
            if (this.notificationToken == undefined) {
              const db = firebase.firestore();
              db.collection("users")
              .doc(this.user.uid)
              .set({ notificationToken: [currentToken]}, { merge: true })
            }
            
            // if field exists the array is unioned with the new token
            else {
              const db = firebase.firestore();
              db.collection("users")
              .doc(this.user.uid)
              .update({ notificationToken: firebase.firestore.FieldValue.arrayUnion(currentToken)})
            }

          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
                // ...
          });
              /// end push code

      } else {console.log("push not supported")}

    },

    PushOptIn () {

      if (('PushManager' in window)) {
  
        console.log("push supported")

        /// init firebase messaging ///
        const messaging = firebase.messaging()

        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken({ vapidKey: 'BF8RJcgDMYFhx_RuX7jEBWe1Z0Je7auoHFhM9C12EaiGmTSReNtAtl2o5Id_Oi0WYI8QPiuKbawpgpom0mVOxtQ' })
        .then((currentToken) => {
          if (currentToken) {

            console.log(currentToken)

            this.deviceToken = currentToken

            // if field for token does not exist a field is set
            if (this.notificationToken == undefined) {
              const db = firebase.firestore();
              db.collection("users")
              .doc(this.user.uid)
              .set({ notificationToken: [currentToken]}, { merge: true })

              console.log("set new field for token")
            }
            
            // if field exists the array is unioned with the new token
            else {
              const db = firebase.firestore();
              db.collection("users")
              .doc(this.user.uid)
              .update({ notificationToken: firebase.firestore.FieldValue.arrayUnion(currentToken)})

              console.log("update existing field for token")
            }

          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
                // ...
          });
              /// end push code
      } else {console.log("push not supported")}
    },

    closePushOptInDialog () {
      // triggers closing of soft-opt-in dialog
      this.popupState = 0
    },

    locationPermission () {
      console.log("store permission state: ",this.settings.geoPermission)
      if (this.settings.geoPermission) {
        this.startLocationUpdates()
      } else {
        this.openPopup(17)
      }
      


    },

    setGeoPermission(state) {

      // if geo permission state is false no location update is triggered but permisssion state is saved on db
      if (!state) {
        // set permission state in store
        mutations.setGeoPermission(state)

        // save permission state on db
        const db = firebase.firestore()
        db.collection("users")
          .doc(this.user.uid)
          .update({
            "settings.geoPermission": state
          })
      } else {
        this.startLocationUpdates()
      }
      
    },

    startLocationUpdates() { // this method will be called on initial enter and by clicking on button "refresh"
      // show cirular progress to user
      mutations.setGeoPermission(true)
      this.loadingstate = true
      this.scanAnimationCounter()

      this.geoErrorFallback()
      // getting coordinates by GPS
      navigator.geolocation.getCurrentPosition( //for realtime: watchPosition instead of getCurrentPosition
        
        position => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;

          // pass coordinates to function "updateLocation"
          if (this.lat * this.lng != 0) {
            this.updateLocation(this.lat, this.lng)
            this.getData(this.lat, this.lng)
            this.lastScanTime = Date.now()
          } 
          else {console.log("no gps data")} 
        },
        error => {
          this.openPopup(2)
          this.loadingstate = false
          console.log(error.message)
          console.log("no gps data")
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 4000 } ///// delete this for lower accuracy
      )
    },

    geoErrorFallback() {
    // this Fallback is required because of some strange iOS bug where dissabled GPS does not lead to an error at geolocation
      setTimeout(() => {if(this.lat == 0) {
        this.openPopup(2)
        console.log("no gps data")
        this.loadingstate = false
      }}, 15000)
    },

    updateLocation (lat, lng) {
    // convert coordinates to geohash and save under user firestore document
      const geohash = geofire.geohashForLocation([lat, lng])
      const geohashLength = geohash.length
      const geohashes = []

      for (let i = 0; i < geohashLength; i++) {
        let geoSliced = geohash.slice(0,geohashLength-i)
        geohashes.unshift(geoSliced)
      }

      const db = firebase.firestore();
      db.collection("users")
        .doc(this.user.uid)
        .update({ 
          lat: lat, 
          lng: lng, 
          geohash: geohash,
          geohashes: geohashes, 
          active: true, 
          updated: firebase.firestore.FieldValue.serverTimestamp(), 
          lastaction: firebase.firestore.FieldValue.serverTimestamp(),
          "settings.geoPermission": true,
          release: this.release
        })

    },


   getData () {

      if (!this.loadingstate) {
        this.loadingstate = true
        this.scanAnimationCounter()
      }
      
      const db = firebase.firestore()

      // get center coordinates which have been saved unter this.lat/lng from db or from last location scan
      const center = [this.lat, this.lng]

      // set radius
      const radiusInM = this.scanRadius
       
       // save last radius
      this.lastScanRadius = this.scanRadius

      // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
      // a separate query for each pair. There can be up to 9 pairs of bounds
      // depending on overlap, but in most cases there are 4.
      const bounds = geofire.geohashQueryBounds(center, radiusInM);
      const promises = [];

      for (const b of bounds) {
        const q = db.collection('users')
        .orderBy('geohash')
        .startAt(b[0])
        .endAt(b[1])
        promises.push(q.get())
      }

      // Collect all the query results together into a single list
      Promise.all(promises)
      .then(
        (snapshots) => {
          const matchingDocs = []
          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const lat = doc.get('lat');
              const lng = doc.get('lng');
                    
              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = geofire.distanceBetween([lat, lng], center);
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {matchingDocs.push(doc)}
            }
          }
          return matchingDocs
        }
      )
          
      .then(
        querySnapshot => {
          //flush all collector arrays first
          this.winesProv = [] 
          this.toCanvas = []
          this.lastScan = [] 
          this.lastScanLocal = [] 
          this.selectedIds = []

          // trigger initializing of community if no data has been found except of this users' data set
          if (querySnapshot.length < 2) {

            this.nousersfound = true
            //this.initCommunity()
            this.loadingDone() 

          } else {
        
            this.nousersfound = false

            querySnapshot.forEach(
              doc => {
                /// get all date from geo-query and assign to const "data" after filtering out this user
                
              if (doc.id != this.user.uid && doc.data().imgurl != 0) /// filter out this user and users with no picture
                { 
                  const data = {
                    'id': doc.id,
                    'geohash': doc.data().geohash,
                    'img': doc.data().imgurl,
                    'imgurlGallery': doc.data().imgurlGallery,
                    'profile': doc.data().profile,
                    'thumb': doc.data().imgurl,
                    'description': doc.data().description,
                    'updated': doc.data().updated,
                    'lastScanResult': doc.data().lastScanResult,
                    'badge': false,
                    'lat': doc.data().lat,
                    'lng': doc.data().lng
                  }

                  // check last updated field for undefined values
                  if (doc.data().updated == undefined) {data.updated = 0} else {data.updated = doc.data().updated.valueOf()}

                  // push new array "mergeddata" to "wines" for for DOM
                  this.winesProv.push(data) 

                  this.toCanvas.push(data)
                

                  // collect user id's to save last scan results locally
                  // (note that this array is wiped above on every run)
                  this.lastScanLocal.push(doc.id)

                }   
              }
            )
          }
          // return whole array of objects from this scan to check below whether one of the users'
          // has to be updated by the radar feature
          return this.winesProv
        }
      )
      .then(
        
        queryResult => {

          queryResult.sort((a, b) => b.updated - a.updated)  // perfup
          this.pageNumber = 0

          // assign query result to the row displayed under the radar screen
          this.winesSelected = queryResult
        
      
          if (!this.nousersfound && this.winesSelected.length > 0) { 
            // hand over to radar feature
            this.screenState = 3
            
            this.loadingDone() 

          } else if (!this.nousersfound) {
            this.nousersfound = true 
            this.loadingDone()

          }
          
          // in case of a low range scan: if one of the found users has this user not saved under
          // his last scan result -> this users' ID is saved in the other user's radar field
          // note that that other users radar should be updated only with this users id, if this
          // user has a valid profile picture
          if (this.profileImage != 0) {
            queryResult.forEach(
              doc => {

                this.lastScan.push(doc.id)
   
                // catching cases where the lastScanResult does not exist so far (old version)
                if (doc.lastScanResult != undefined) {

                  // check if has not been seen by the other user so far
                  if (!doc.lastScanResult.includes(this.user.uid)) {

                    // update the radar of other users which have not seen this user so far
                    db.collection("radarUpdate")
                    .doc(doc.id)
                    .set({radar: firebase.firestore.FieldValue.arrayUnion(this.user.uid)},{merge: true})
                  }
                }
              }
            )
          }
          return this.lastScan
        }
      )
      .then( 
        doc => {
          db.collection("users")
          .doc(this.user.uid)
          .update({ 
            lastScanResult: doc,
            release: this.release
          })
        }
      )
    },

    loadingDone () {
    
      /* if no users have been found and a broader scan radius is available according to the intervalRadius 
      (the interval according to which low range scans are performed) another scan will be made*/
      if (this.nousersfound && this.intervalRadius.indexOf(this.scanRadius) < this.intervalRadius.length-1) {
        
        // set index for the element of intervalRadius to pick the next scan radius below
        let nextRadius = this.intervalRadius.indexOf(this.scanRadius)+1

        // assign next scan Radius
        this.scanRadius = this.intervalRadius[nextRadius]
        
        // perform another search for the new radius
        this.getData()

      } else {
              
        // get last scan radius to be displayed to the user and format with "m" or "km", depending on value
        if (this.scanRadius < 1000) {this.lastRadiusInfo = this.scanRadius+" m"} else {this.lastRadiusInfo = (this.scanRadius/1000)+" km"}

        // reset scan radius to its default value according to the intervall
        this.scanRadius = this.intervalRadius[0]

        // set filter to its default value to show all results first
        this.filterset = false

        // set loading spinner to off  
        this.loadingstate = false

      }

    },

    refreshCommunity() {
      this.$refs.communityComponent.loadCommunity("init")
    },

    initCommunity () {

      this.screenState = 4
      this.$refs.communityComponent.loadCommunity("init")
      
    },

    initFriends() {
      if (this.chatswith.length > 0) {
        this.screenState = 2
      } else {
        this.openPopup(this.language.driver.noFriends)

        // clean-up function to catch cases where -somehow- the unreads-listener of this user
        // is not empty even there are no chats (this would lead to the annoying situation where the "new message"-badge
        // could not be removed as there are no chats
        // Note: This should not be the case. However, some line of codes are worth it to be on de safe side
        if (this.unreadslistener.length > 0 ) {
          const db = firebase.firestore()
          db.collection("unreads")
          .doc(this.user.uid)
          .update({ unreadfrom: []})
        } 

      }
      
    },

    showFaq () {
      const faqState = (document.getElementById("faq").style.display == "block")
      if (!faqState) {
        document.getElementById("faq").style.display = "block"
      } else {
        document.getElementById("faq").style.display = "none"
      }
    },

    flyaway() {



/*       this.lat = 46
      this.lng = 8

        this.updateLocation(this.lat, this.lng)
        this.getData(this.lat, this.lng) */

    },



    dialogOn (wine) {

      //assign selected User
      this.selectedUser = wine

      //assign single image for older versions
      this.selectedImage = wine.thumb

      // flush selectedGallery array
      this.selectedGallery = []

      //assign description
      this.selectedDescription = wine.description

      //assign profile informations
      if (wine.profile != undefined) {
        this.selectedProfile = wine.profile
      } else {
        this.selectedProfile = {
          description: wine.description,
          snapchat: null,
          instagram: null
        }
      }
 
      //check if a gallery is available (for older versions)
      if (wine.imgurlGallery != undefined) {

        // set empty arry to be copied below into Gallery
        let galleryFiller = []

        //check each item of gallery for 0 values to be skipped
        wine.imgurlGallery.forEach(item => {
          if (item.imgurl != 0) {
            galleryFiller.push(item)
            //assign new array to selectedGallery
            this.selectedGallery = [...galleryFiller]
          }
        })
      // if old version: the gallery is filled with the single profile pic
      } else {
        let galleryFiller = []
        galleryFiller.push({imgurl: wine.thumb,uid: 0})
        this.selectedGallery = [...galleryFiller]
      }

      document.getElementById("friends_dialog").style.display = "block";

    },

    dialogOff () {
      // reset selectedGallery
      this.selectedGallery = [{imgurl: 0,uid: 0},{imgurl: 0,uid: 0},{imgurl: 0,uid: 0}]
      document.getElementById("friends_dialog").style.display = "none";
    },

    reloadwindow() {
      window.location.reload()
    },

    signOutButtonPressed() {

      if (this.notificationToken != undefined) {
        

        const db = firebase.firestore()
        db.collection("users")
        .doc(this.user.uid)
        .update({ notificationToken: firebase.firestore.FieldValue.arrayRemove(this.deviceToken)})
      }
      

      firebase
      .auth()
      .signOut()
      .then(
        user => {
          this.$router.push(
            {
              name: "Login"
            }
          )
          this.$destroy()
          window.location.reload()
        }
      )
      .catch(
        error => {
          console.log(error.message);
        }
      )
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#map {
		height: 75vh;
    width: 100vw;
		background: gray;
	}

  .mainscreen {
    overflow-y: scroll;
    background-color: #f3f1f2;
    height: 100vh

  }

  .profileButton {

    height: 70px;
    width: 70px;
    border-radius: 35px;

  }

  .profileButtonDefault {
    background-color: #5F5F5F;
    height: 70px;
    width: 70px;
    border-radius: 35px;

  }

  .userimage {
  border-radius: 25px;
  width: 60%;
  max-width: 400px

}


.centerwrapper {
padding: 10px;
display: flex;
justify-content: center;

}

.topbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #f3f1f200;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 8997
}

.topcenter {
  display: flex; 
  justify-content: center; 
  align-items: top;
}

.offline {
  display: flex;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  background-color: #5F5F5F;
  height: 30px;
}


.topright {
  display: flex; 
  justify-content: flex-end; 
  align-items: top;

}

.hidelikecounter {
  display: flex;
  max-width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  background-color: #5F5F5F;
  margin-right: 5px;
  height: 30px;
}

.counterimage {
  height: 30px;
  width: 30px;
  margin: auto;
}

.counterfigure {
 font-size: 20px;
 color: white;
 margin-left: 6px
}

.popup {
  display: none;
}

.alignleft {
	float: left;
  margin-top: 7px;
  margin-left: 7px;
}
.alignright {
	float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.tile_button {
  background-color: #ffffff00;
  width: 30px;
  height: 30px;
  justify-self: center
}

.filter_button_container {
  display: flex;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.filter_button {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto
}


.introinstruction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60vh
}

.introinstructiontext {
 margin-left: auto;
 margin-right: auto;
 color: #5F5F5F;
 font-size: 20px
}

.iconinline {
  padding: auto;
 height: 30px;

}


.radardisplay {
  display: flex;
  position: top;
  margin-bottom: 0;
  justify-content: center;

  }

.radar_cards {
  margin-left: 2px;
  border-radius: 15px;
  width: 100px;
  height: 100px;
  margin-left: 3px;
  margin-right: 3px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

}

.badgeRadar {
  position: relative;
  border-radius: 12px;
  text-align: center;
  width: 70px;
  height: 24px;
  background-color: #f5416c;
  top: 15px;
  left: 0px;
  font-size: 14px;
  color: white
}

.radarmap {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }

.scanner {
  overflow-y: hidden;
}

.heart {
  display: none;
}

.scannercanvas {
  height: 40vh;
  width: 40vh;
}

.badgeRadartransp {
  position: relative;
  border-radius: 12px;
  text-align: center;
  width: 70px;
  height: 24px;
  background-color: #ffffff00;
  top: 15px;
  left: 25px;
  font-size: 14px;
  color: white
}

.scanner {
  overflow-y: hidden;
}

  .headingButton {
  position: absolute;
  border-radius: 15px;
  background-color: #ffffff;
  color: #f5416c;
  width: auto;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-content: center;
  margin-top: -5px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

.usertable {
bottom: 0;
display: flex;   
overflow-x: auto;
overflow-y: hidden;
align-items: center;
margin-top: 10px;
margin-left: auto;
margin-right: auto

}

.badge {
  border-radius: 12px;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #f5416c;
  margin-top: -12px;
  margin-left: 18px;
  font-size: 16px;
  text-align: center;
  color: white
}


.exit {
  margin-top: 10px;
  margin-right: 10px;
 font-size: 35px;
 color: #aaa5a5;
}

.faq_dialog {
  display: none; /* Hidden by default */
}

.bottomwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.navbuttons {
  height: 35px
  }

  .navbar {
  background-color: #ffffff;
  max-width: 400px;
  overflow: visible;
  position: fixed;
  bottom: 0;
  width: 80%;
  height: 60px;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-radius: 30px;
  z-index: 8997;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

}


.bottombuttons {
  display: grid;
  justify-content: center
}

.scanbutton {
  border-radius: 50px;
  background-color: #f5416c;
    width: 100px;
  height: 100px;
  position: absolute;
  top: -18px;
  left: 0; 
  right: 0; 
  margin: auto;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonicon {
  height: 70px;
  margin-top: 6px;
  border-radius: 35px;
}


.pulsate {
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.90);
	}
}








</style>

