<template>
  <div id="friends_dialog" class="friends_dialog">
   
    <div id="gallery_container" class="gallery_container">

      <div id="imgselector" class="imgselector">
        <div id="scroll_gallery" v-for="(item, index) in selectedGallery" :key='index' >
          <img class="swipe_image" @load="imgagesLoaded()" :src="item.imgurl" :id="'galleryImg' + index">
        </div>
      </div>

      <div class="swipebuttoncontainer">
        <button v-if="selectedGallery.length > 1 && allImagesLoaded" id="nextImg" class="swipeRight" @click="selectViewStateUp()">
          <img v-if="viewState+1 === selectedGallery.length" class="closeblocksmall" src="../assets/startImg.svg" alt="">
          <img v-else class="closeblock" src="../assets/swipeRight.svg" alt="">
        </button>
            
        <div v-else-if="selectedGallery.length > 1" class="loader">
          load...
        </div>
      </div>
    </div>
      
    <div class="friends_dialogflex">
      <div class="friends_toolbar">
        <div class="friends_buttons">
          <button @click="close()" class="hidelike_default"><img class="iconsmall" src="../assets/back.svg" alt=""></button>
        </div>
      </div>
    </div>

    <div class="infocontainer">

      <div class="descriptionbox"> 
        {{selectedProfile.description}}
      </div>
    
      <div class="socialbox">
        <a v-if="selectedProfile.snapchat != null && selectedProfile.snapchat != ''" class="socialtext" :href="instaRoute+selectedProfile.instagram">
          <img class="socialimage" src="../assets/instagram.svg">
        </a>
        <a v-if="selectedProfile.instagram != null && selectedProfile.instagram != ''" class="socialtext" :href="snapRoute+selectedProfile.snapchat">
          <img class="socialimage" src="../assets/snapchat.svg">
        </a>
      </div>

    </div>  
    
  </div>
</template>

<script>
import firebase from "firebase";
import { store, mutations } from '@/components/store'

export default {

props: [
      'selectedUser',
      'selectedGallery',
      'selectedDescription',
      'selectedProfile'
    ],

  data() {
    return {
      user: firebase.auth().currentUser,
      viewState: 0, //index of displayed image (triggered by the next-button)
      loadedImages: 0, //counter for all images which are loaded to cache
      allImagesLoaded: false, //triggers the "load..."-sign at DOM
      instaRoute: "http://www.instagram.com/",
      snapRoute: "http://www.snapchat.com/add/"
    }
  },

  computed: {
    profileImage() {return store.profileImage},
  },

  methods: {

      imgagesLoaded () {
        //triggered by the loaded-event in the v-for list
        this.loadedImages++
        if (this.loadedImages > 1) {
          //trigger the loader to disappear
          this.allImagesLoaded = true
          // reset the loaded images counter
          this.loadedImages = 0
        }
      },

      selectViewStateUp () {

        // programmatically scroll to selected image by viewState

        // scroll to the right
        if (this.viewState+1 < this.selectedGallery.length) {
          let scroller = document.getElementById('galleryImg' + (this.viewState+1))
          scroller.scrollIntoView(false)
          this.viewState++
        // scroll back to start when end is reached
        } else {
          let scroller = document.getElementById('galleryImg0')
          scroller.scrollIntoView(false)
          this.viewState = 0
        }
      },

      close() {
        //reset all trigger-variables of this child
        this.viewState = 0
       // this.allImagesLoaded = false
        //this.loadedImages = 0

        //this is a work-around which is required as iOS Safari does
        // not support the smooth-opion for scrolling. Therefore the
        // scroll-behaviour has to be set to auto to reset the scroll
        // and after closing resetted to "smooth"
        // ...so far no other solution in sight :(
        let elmnt = document.getElementById('imgselector')
        elmnt.style.scrollBehavior = "auto";
        elmnt.scrollLeft = 0
    
        this.$emit('close')

        elmnt.style.scrollBehavior = "smooth";

      }

  }
}

</script>

<style scoped>

.friends_dialog {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0px;
  z-index: 8998; /* Specify a stack order in case you're using a different order for other elements */
  background-color: #f3f1f2;
}

.exit {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  top: 0;
  padding: 20px;
  color: white;
  font-size: 30px
}

.gallery_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -25px;
  max-width: 600px;
}

.imgselector {
  justify-content: flex-start;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  align-items: flex-end;
  bottom: 0;
  display: flex;   
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: auto;
  margin-right: auto;

}

.imgselector::-webkit-scrollbar {
  display: none;
}

.swipe_image {
  max-height: 600px;
  height: 100vw;
  width: auto;
  scroll-snap-align: start
}


.swipebuttoncontainer {
  position: fixed;
  display: flex;
  margin-left: auto;
  padding-right: 15px;
  height: 40px;
  width: 75px;
  z-index: 8999;
}

.swipeRight {
  margin-left: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #00b7a883;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.closeblock {
    height: 20px;
    margin: auto;
}

.closeblocksmall {
    height: 18px;
    margin: auto;
}

.iconsmall {
  height: 25px;
  width: 25px;
}

.loader {
  font-size: 15px;
  color: white;
  margin-left: auto;
  margin-right: 15px;
  position: fixed;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 60px;
  border-radius: 20px;
  background-color: #7a7a7a83;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  z-index: 8999;
}


.friend_container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: auto;
  background-color: #f3f1f2;
}

.friends_description {
  max-width: 300px;
  width: 70vw;
  position: fixed;
  padding: 7px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 60px;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.774);
  z-index: 8999;
}


.friends_dialogflex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  
}

.friends_toolbar {
  width: 100vw;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  max-width: 600px;
  background-color: #f3f1f2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8999;
}

.friends_buttons {
    padding: 20px;
    font-size: 30px;
    color: white
}

  .hidelike_default {
  display: flex;
  border-radius: 35px;
  background-color: #d0d0cf;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
  }

.infocontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
}

.descriptionbox {
  display: flex;
  text-align: center;
  justify-content: center;
  justify-items: center;
  max-width: 500px;
  width: 90vw;
  padding: 7px;
  height: auto;
  max-height: 100px;
  min-height: 40px;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  z-index: 8999;
}

.socialbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  max-width: 500px;
  width: 90vw;
  margin-top: 10px;
  padding: 7px;
  height: auto;
  z-index: 8999;

}

.socialtext {
 font-size: 18px;
 color: #5F5F5F;
 margin-right: 15px
}


.socialimage {
  height: 40px;
  width: 40px;
  margin: auto;
}

  



</style>


