import Driver from '@/components/Driver'
import Login from '@/components/Login'
import Register from '@/components/Register'
import chat from '@/components/chat'
import profile from '@/components/profile'
import landingpage from '@/components/landingpage'
import intro from '@/components/intro'


  export const routes = [

  {
      path: '/landingpage',
      name: 'landingpage',
      component: landingpage,
      meta: {
        requiresAuth: false,
        keepAlive: false
      }
   },
  
  {
     path: '/Register',
     name: 'Register',
     component: Register,
  },

  {
    path: '/intro',
    name: 'intro',
    component: intro,
    meta: {
      requiresAuth: true,
    }
 },
  
  {
    path: '/Login',
    name: 'Login',
    component: Login,
   },

   {
     path: '/Driver',
     name: 'Driver',
     component: Driver,
     meta: {
        requiresAuth: true,
        keepAlive: true
      }
   },

   {
    path: '/Driver',
    name: 'profile',
    component: profile,
    meta: {
       requiresAuth: true,
       keepAlive: true
     }
  },


   {
      path: '/Driver',
      name: 'chat',
      component: chat,
      props: true,
      meta: {
         requiresAuth: true,
         keepAlive: false
       }
    
   },

   {
      path: '*',
      redirect: '/landingpage'
    },
  
]
