<template>
  <div id="picture" class="introcontainer">
    <div class="profile_dialogcontent">       
      <div class="imagecontainer"> 
        <input id="file" type="file" accept="image/*" @change="previewGallery" style="display:none"> 
          <img v-if="previewScreen != 0" class="userimage" :src="previewScreen" @click="openFileUpload()">
          <img v-else class="userimage" src="../assets/profile.svg" @click="openFileUpload()">
        </div>
        <div class="deletepic">
          <button class="deletebutton" @click="deleteProfilePic()">delete</button>
        </div>
      </div>
      <div class="imgselector">
        <div v-for="(item, index) in localGalleryState" :key='index' >
          <div v-if="previewState === index" class="addimg_container">
            <img v-if="item.imgurl != 0" class="addimgselected" :src="item.imgurl" @click="selectImage(index)">
            <img v-else class="addimgselected" src="../assets/profile.svg" @click="selectImage(index)">
          </div>
          <div v-else>
            <img v-if="item.imgurl != 0" class="addimg" :src="item.imgurl" @click="selectImage(index)">
            <img v-else class="addimg" src="../assets/profile.svg" @click="selectImage(index)">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import firebase from "firebase";
import loadImage from 'blueimp-load-image'
import { store, mutations } from '@/components/store'

export default {

  data() {
    return {
      user: firebase.auth().currentUser,
      picture: null, //temporary store for download URL
      previewState: 0, //image clicked by the user (triggers name for field and data too)
      uploadValue: 0, // upload progress 0 - 100 (not in usage so far)
      picstate: false, //picstate (has at least 1 Profile Pic: True/False)
      localGalleryState: [{imgurl: 0,uid: 0},{imgurl: 0,uid: 0},{imgurl: 0,uid: 0}], // mounted from global store and filled with uploaded blob
      previewScreen: 0, // the big screen to show the selected image
    }
  },

  computed: {   
    imgurlGallery() {return store.imgurlGallery}, //import total gallery with all user pics
    storeImgurl() {return store.profileImage}, //import main profile picture
   //previewScreen() {return this.localGalleryState[this.previewState]}, //big screen to show selected picture
  },

  mounted () {

    this.localGalleryState = this.imgurlGallery
    this.previewScreen = this.localGalleryState[0].imgurl

    //if all values are 0 a data fetch from DB is performed to make sure that last state is available
    //it is possible that a user leaves the app while being in the profile menue and returns later
    //which will lead to an empty array as mounted in Driver was not runned
    if (this.localGalleryState[0].imgurl*this.localGalleryState[1].imgurl*this.localGalleryState[2].imgurl === 0) {
      this.getUserData()
    }
  },

  methods: {

    getUserData () {
      const db = firebase.firestore()
      
      db.collection('users').doc(this.user.uid)
      .get()
      .then(
        (doc) => {   
          if (doc.exists) {  

            mutations.setProfileImage(doc.data().imgurl)

            // available profilepictures
            // if the Gallery is available it's fully imported, else the profile-pic is imported
            if (doc.data().imgurlGallery != undefined) {mutations.setImgurlGallery(doc.data().imgurlGallery)} 
            else {mutations.setImgurlGallery(false)}

            this.localGalleryState = this.imgurlGallery
            this.previewScreen = this.localGalleryState[0].imgurl   
          } 
        }
      )
    },

    selectImage(item) {

      // if a user taps again on the selected image it is likely that he wants to change the picture
      if (this.previewState == item) {this.openFileUpload()}
      else {
        // assign clicked value from list to preview State which triggers the big screen
        this.previewState = item
       this.previewScreen = this.localGalleryState[this.previewState].imgurl  
      }
    },

    previewGallery(event) {
      /// when copy this make sure to install blueimp javascript loader and
      /// and to import it on top

      // check if an empty space is available before the selected slot
      let counter = 0
      this.imgurlGallery.forEach(element => {
        if (element.imgurl == 0 && this.previewState > counter) {
         this.previewState = counter 
        } else {
          counter++
        }
      })

      // copy preview state to an other variable to interrupt interactivity during upload process
      const freezePreviewState = this.previewState

      // get timestamp to be processed for db and store reference
      const freezeTimeStamp = Date.now().toString()

      // get reference of the old pic which is going to be deleted below
      const copyOfImgurlGallery = [...this.imgurlGallery]
      const oldImageUid = copyOfImgurlGallery[this.previewState].uid



      const input = event.target.files[0];

      loadImage(input, { canvas: true, orientaton: true, aspectRatio: 1/1})
      .then(
        (data) => {   
          return new Promise(
            function (resolve) {
              data.image.toBlob(
                function (blob) {
                  data.blob = blob
                  resolve(data)
                }, 'image/jpeg', 0.3 // image compression
              )
            }
          )
        }
      )
      .then(
        (data) => { 
          //update local state with image blob to immediately be displayed to the user
          this.previewScreen = URL.createObjectURL(data.blob)
          this.localGalleryState[freezePreviewState].imgurl = URL.createObjectURL(data.blob)
          this.localGalleryState[freezePreviewState].uid = freezeTimeStamp

          return new Promise(
            (resolve, reject) => {

                // save new image to firebase storage
                const storageRef = firebase
                .storage()
                .ref('users/' + this.user.uid + '/' + freezeTimeStamp +'.jpg')
                .put(data.blob)

                // get download URL to be save in DB
                storageRef
                .on(
                  `state_changed`,snapshot=>{
                    this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                  }, error=>{console.log(error.message)},
                    
                  async () => {
                    
                    const imgURL = await storageRef.snapshot.ref
                    .getDownloadURL()
                    this.picture = imgURL
                    resolve()

                    // copy global store Gallery (containing Image URLs)
                    let newImgurlGallery = [...this.imgurlGallery]

                    // replacing selected element with the new URL
                    newImgurlGallery[freezePreviewState].imgurl = this.picture
                    newImgurlGallery[freezePreviewState].uid = freezeTimeStamp

                    // save download URL in firestore
                    const db = firebase.firestore()
                    db.collection("users")
                    .doc(this.user.uid)
                    .update({
                      imgurlGallery: newImgurlGallery,
                      imgurl: newImgurlGallery[0].imgurl, //for older versions
                      picstate: true //for older versions
                      })

                    // update global state
                    mutations.setProfileImage(newImgurlGallery[0].imgurl)
                    mutations.setImgurlGallery(newImgurlGallery)

                    this.picstate = true

                    // delete old picture
                    // Create a reference to the file to delete
                    if (oldImageUid != 0) {
                      const refToDelete1 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'.jpg')
                      const refToDelete2 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'_200x200.jpg')
                      
                      refToDelete1.delete().then(() => {
    
                        }).catch((error) => {
                          console.log("error")
                        })

                      refToDelete2.delete().then(() => {
                          
                        }).catch((error) => {
                          console.log("error")
                        })
                    }
                  }
                )
            } 
          )
        }
      )    
    },


    openFileUpload() {
      const file = document.getElementById("file")
      file.click()
    },

    deleteProfilePic() {

      // copy preview state to an other variable to interrupt interactivity during upload process
      const freezePreviewState = this.previewState

      // get reference of the old pic which is going to be deleted below
      const copyOfImgurlGallery = [...this.imgurlGallery]
      const oldImageUid = copyOfImgurlGallery[this.previewState].uid

      // update DOM to
      // 1. clean the big preview screen
      this.previewScreen = 0

      // 2. delete the local gallery state to upload the clickable list
      // copy the local Gallery
      let localGalleryStateCopy = [...this.localGalleryState]
      // remove deleted element from the copy
      localGalleryStateCopy.splice(freezePreviewState, 1)
      // replace element by pushing a 0 Value at the end of the array
      localGalleryStateCopy.push({imgurl: 0,uid: 0})
      //immediately update DOM with new selected picture
      this.previewScreen = localGalleryStateCopy[freezePreviewState].imgurl
      // re-set the local Gallery with the copied and changed arra
      this.localGalleryState = localGalleryStateCopy


      // 3. Update the global gallery to be uploaded to DB

      // copy the global Gallery
      let imgurlGalleryCopy = [...this.imgurlGallery]
      // remove deleted element from the copy
      imgurlGalleryCopy.splice(freezePreviewState, 1)
      // replace element by pushing a 0 Value at the end of the array
      imgurlGalleryCopy.push({imgurl: 0,uid: 0})

      // update globale store values
      mutations.setImgurlGallery(imgurlGalleryCopy)
      mutations.setProfileImage(imgurlGalleryCopy[0].imgurl)

      // upload new gallery to DB
      // to support older versions image 0 has to be saved in a separate field too

      const db = firebase.firestore()
      db.collection("users")
      .doc(this.user.uid)
      .update({
        imgurlGallery: imgurlGalleryCopy,
        imgurl: imgurlGalleryCopy[0].imgurl,
      })

      // delete old picture
      // Create a reference to the file to delete
      if (oldImageUid != 0) {
      const refToDelete1 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'.jpg')
      const refToDelete2 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'_200x200.jpg')
                      
      refToDelete1.delete().then(() => {
      
      }).catch((error) => {
        console.log("error")
      })

      refToDelete2.delete().then(() => {
       
      }).catch((error) => {
       console.log("error")
      })
      }
    }
  }
}

</script>

<style scoped>
.introContainer {
    display: block;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.473); /* Black background with opacity */
    z-index: 9000; 
    padding: 20px;
}



.imagecontainer {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content:center;
}

  .userimage {
  border-radius: 25px;
  height: 300px;
  width: 300px

}


.deletepic {
  margin-top: -90px;
  display: flex;
  justify-content: center;
  color: white;
  
}

  .deletebutton{
  border-radius: 35px;
  border: 1px solid #ffffff;
  background-color: #f5416c;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  }

  .imgselector {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #f3f1f200;
 
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 8997
  }

    .addimg {
    border-radius: 10px;
    border: 1px solid #cecece;
    width: 75px;
    height: 75px;
    margin-left: 3px;
    margin-right: 3px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

  }

  .addimgselected {
    border-radius: 10px;
    border: 2px solid #1eb2a6;
    width: 75px;
    height: 75px;
    margin-left: 3px;
    margin-right: 3px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

  }


</style>


