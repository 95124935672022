<template>
  <div id="community">
    <div id="community_top"></div>

    <div class ="centerwrapper">

      <div class="community_table">

        <div v-for="wine in endresult" v-bind:key="wine.id"> <!-- UP2022_2 -->
          <!-- if an img value is available the thumbnail URL is assigned -->
          <button class="community_button" v-if="wine.img != 0" v-on:click="dialogOn(wine)">
            <div v-if="wine.badge" class="badgeCommunity">new 50m</div>
            <img class="community_cards" loading="lazy" :src="wine.thumb">
          </button>
        </div>
      </div>
    </div> 
    <!-- pagination buttons -->
    <div v-if="endresult.length > 0" class="pagination">
      <button class="navbuttonsmall" @click="loadCommunity('init')"><img class="closeblock" src="../assets/start.svg" alt=""></button>
      <button class="navbuttonsmall" @click="loadCommunity('prev')"><img class="closeblock" src="../assets/up.svg" alt=""></button>
      <button v-if="isAtEnd && this.settings.searchFilter.filter" class="navbuttonsmall" @click="nextPageByUnfilter()"><img class="closeblock" src="../assets/down.svg" alt=""></button>
      <button v-else-if="!isAtEnd" class="navbuttonsmall" @click="loadCommunity('next')"><img class="closeblock" src="../assets/down.svg" alt=""></button>
    </div>

    <div class="bottomdiscance"></div>
  </div> 
</template>

<script>

import firebase from "firebase"
import { store, mutations } from '@/components/store'

const geofire = require('geofire-common');

export default {

  props: [
    'lat',
    'lng',
    'nousersfound',
    'screenState',
    'lastScanTime',
    'settings'
    ],

  data() {
    return {
      user: firebase.auth().currentUser,
      winesProv: [], // array to catch forEach cycle and -when finished- to be handed over to var endresult
      endresult: [], // major array according to which list is displayed to the user
      dataLoaded: [],
      dataPrepared: [],
      dataDisplayed: [], //now this.endresult
      size: 30, // max size of displayed items
      sizeLoaded: 0, //counter for the displayed items to check whether 
      isAtEnd: false, // states if end of available data is reached
      loading: false, //loading state to trigger loading indicator
      loadingTime: 1,// to check if a lastScanTime has changed and an update of the community is due (default value should be 1 to differ from default value of lastscantime which is 0)
      lastKey: undefined,
      firstKey: "", //first displayed key of list
      loop: 0,
      removedFilter: false,
      

    }
  },

  computed: {   
    localSession() {return store.localSession}
  },


  mounted () {


  },

  activated () {

    // check if last loading time is older than 5 Minutes (300 Seconds)
    // loading time is saved in loadCommunity() in local store
    const timeInSeconds = Date.now() / 1000
    const loadingExpiration = timeInSeconds - this.localSession.communityTimer

    if (this.screenState == 4 && loadingExpiration > 300) {

      // init loading of community data
      this.loadCommunity('init')
    
    } 
    
      this.$nextTick(
        () => { 
          let elmnt = document.getElementById('community_top')
          elmnt.scrollIntoView(false)
        }
      )

  },
  
  methods: {

    nextPageByUnfilter() {
      this.$emit('openPopup', 19)

    },

    loadCommunity(navigationState) { 

      const timeInSeconds = Date.now() / 1000

      // save timestamp of last loading
      mutations.setSession('communityTimer', timeInSeconds)

      //navigations states: init - next - prev

      //trigger for loading state
      this.loading = true
      this.$emit('openPopup', "loading")

      // flood collector array to be filled with the forEach loop
      this.dataLoaded = []

      // reset size of list displayed to the user
      this.dataPrepared = []

      // reset isAtEnd to its default value
      this.isAtEnd = false

      //get data without geoquery according to latest action of users and limiting
      const db = firebase.firestore()

      // flush array to be filled with all search parameters
      const queryConstraints = []

      // add search params to array according to user's settings
      queryConstraints.push('db.collection("users")')
      if (this.settings.searchFilter.gender != null) {
        queryConstraints.push(".where('profile.gender','==', '"+this.settings.searchFilter.gender+"')")
      }

      if (this.settings.searchFilter.ages.length > 0 && this.settings.searchFilter.ages.length < 11) {
        queryConstraints.push(".where('profile.age','in', ["+this.settings.searchFilter.ages.toString()+"])")
        }

      var stringConstraint = null
      var finalConstraint = null

      /// init navigation state
      if (navigationState == "init") {
        queryConstraints.push(".orderBy('updated', 'desc')")
        stringConstraint = String(queryConstraints.join(''))
        console.log(stringConstraint)
        finalConstraint = eval(stringConstraint)
      /// next navigation state
      } else if (navigationState == "next") {
        queryConstraints.push(".orderBy('updated', 'desc')")
        stringConstraint = String(queryConstraints.join(''))
        finalConstraint = eval(stringConstraint).startAfter(this.lastKey)
      /// prev navigation state
      } else {
        queryConstraints.push(".orderBy('updated', 'asc')") 
        stringConstraint = String(queryConstraints.join(''))
        finalConstraint = eval(stringConstraint).startAfter(this.firstKey) 
      }

      // get data from backend            
      finalConstraint.limit(this.size).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          /// collect all loaded data to get first and last key
          this.dataLoaded.push(doc.data().updated)

          /// filter out this user and users with no picture
          if (doc.id != this.user.uid && doc.data().imgurl != 0) { 

            const data = {
              'id': doc.id,
              'geohash': doc.data().geohash,
              'img': doc.data().imgurl,
              'imgurlGallery': doc.data().imgurlGallery,
              'profile': doc.data().profile,
              'thumb': doc.data().imgurl,
              'description': doc.data().description,
              'updated': doc.data().updated,
              'lastScanResult': doc.data().lastScanResult, // UP2022_2 start
              'badge': false
            }

            // push data to the collector array which -when loop is done- will be handed over to var endresult
            // to be displayed to the user
            if (navigationState == "prev") {
              this.dataPrepared.unshift(data)
            } else {
            this.dataPrepared.push(data) 
            }
          } 
        })

          // clear display array
          this.endresult = []

          // assign collected data to display array
          this.endresult = this.dataPrepared
        
          // close loading popup
          this.$emit('openPopup')
     
          // get values for first and last key to paginate further queries
          const loadedSize = this.dataLoaded.length
          
          if (navigationState === "prev") {
          this.firstKey = this.dataLoaded[loadedSize-1]
          this.lastKey = this.dataLoaded[0]
          } else {
          this.firstKey = this.dataLoaded[0] 
          this.lastKey = this.dataLoaded[loadedSize-1]
          }

          if (loadedSize < this.size) {

            // if no data was loaded
            if (loadedSize < 1) {
              this.isAtEnd = true
              console.log("no data found loaded size < 1")

              // catch the case if a user presses the back button on the first page
              // as this would lead to an empty array
              if (navigationState == "prev") {
                this.loadCommunity("init")
              } else if (this.settings.searchFilter.filter) {
                this.$emit('openPopup', 19)
              } else {
                // show popup to inform about "no users found"
                this.$emit('openPopup', "no users found")
              }
            // if data was loaded but without valid information (Pictures) to be displayed to the user
            // another query is performed until data can be showed to the user
            // however a condition prevents endless looping
            } else if (this.dataPrepared.length < 1 && this.loop < 3) {
              // re-fire this function in (unlikely) case that the loaded array is not big enough
              // (which might theoretically be the case if all the last active users would not have a profile pic)
              this.loop++
              console.log("looped")
              this.loadCommunity("next")
            } else if (this.dataPrepared.length < 1) {
              // inform user about "no users found"
              this.loop = 0
              this.isAtEnd = true
              this.$emit('openPopup', "no users found")
            } else if (this.settings.searchFilter.filter) {
              // if a filter was active it is removed to show more profiles when pressing the forward button
              // a conditionally fired popup inform the user about the filter removal
              this.isAtEnd = true
              //this.$emit('openPopup', 19)
              this.loop = 0
            }  
          }
            
          // scroll to top of list
          this.$nextTick(
            () => { 
              let elmnt = document.getElementById('community_top')
              elmnt.scrollIntoView(false)
            }
          )
        
      })
    },

/*     resetFilter () {

      const newSearchFilter = {
        filter: false,
        ageMin: null,
        ageMax: null,
        gender: null,
      }

      mutations.setSearchFilter(newSearchFilter)

      console.log("filter resetted")

        const db = firebase.firestore()
        db.collection("users")
        .doc(this.user.uid)
        .update({
          "settings.searchFilter": newSearchFilter
        })

       // this.loadCommunity("next")

    }, */

    dialogOn (wine) {
      this.$emit('dialogOn', wine)
    },
  }
}

</script>

<style scoped>
.centerwrapper {
padding: 10px;
display: flex;
justify-content: center;
}



.community_table {
  max-width: 420px;
  margin-top: 80px;
  display: flex;
  direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.badgeCommunity {
  border-radius: 12px;
  position: absolute;
  text-align: center;
  width: 100px;
  height: 24px;
  background-color: #f5416c;
  margin-top: -10px;
  margin-left: 12px;
  font-size: 16px;
  color: white
}

.community_button {
  width: 45vw;
  height: 45vw;
  max-width: 200px;
  max-height: 200px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);

}

.community_cards {
  width: 45vw;
  height: 45vw;
  max-width: 200px;
  max-height: 200px;
  border-radius: 15px;
}


.pagination {

margin-top: 10px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

}


.navbutton {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #00b7a9;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.navbuttonsmall {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #00b7a9;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.navbuttontop {
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #00b7a9;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.navbuttondisabled {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #e4e3e3;
}

.closeblock {
    height: 20px;
    margin: auto;
}

.closeblockback {

    height: 20px;
    margin: auto;

}

.paginationtext {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px

}

.bottomdiscance {
 height: 250px

}

</style>


