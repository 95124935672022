<template>
    <div id="profile" class="introcontainer">
     
          <div class="profile_dialogcontent">
               

              <!-- the _final variables are computed properties (see computed @ script section)-->
              <div class="avatar">
                <avataaars ref="avatar"
                  :isCircle= false
                  :topType= topfinal
                  :clotheType= clothesfinal
                  :clotheColor= clothescolorfinal
                  :accessoriesType= accessoriesfinal
                  :hairColor= hairscolorfinal
                  :skinColor= skinfinal
                  :eyebrowType= eyebrowfinal
                  :eyeType= eyesfinal
                  :mouthType= mouthfinal
                  :facialHairColor= hairscolorfinal
                  :facialHairType= facialhairfinal
                  :graphicType="'Cumbia'">
                </avataaars>
              </div>
            
                <!-- sliders are triggered by this.outputvar which is set by the selector buttons below-->
                <!-- the range of each slider is set according to array length of respective catalque variable (array)-->
              <div class="slidecontainer">
                <input type="range" class="slider" v-if="outputvar == 'clothes'" v-model="clothescounter" :max="clothes.length -1">
                <input type="range" class="slider" v-if="outputvar == 'accessories'" v-model="accessoriescounter" :max="accessories.length -1">
                <input type="range" class="slider" v-if="outputvar == 'clothescolor'" v-model="clothescolorcounter" :max="clothescolor.length -1">
                <input type="range" class="slider" v-if="outputvar == 'hairscolor'" v-model="hairscolorcounter" :max="hairscolor.length -1">
                <input type="range" class="slider" v-if="outputvar == 'eyebrow'" v-model="eyebrowcounter" :max="eyebrow.length -1">
                <input type="range" class="slider" v-if="outputvar == 'eyes'" v-model="eyescounter" :max="eyes.length -1">
                <input type="range" class="slider" v-if="outputvar == 'facialhair'" v-model="facialhaircounter" :max="facialhair.length -1">
                <input type="range" class="slider" v-if="outputvar == 'skin'" v-model="skincounter" :max="skin.length -1">
                <input type="range" class="slider" v-if="outputvar == 'top'" v-model="topcounter" :max="top.length -1">
                <input type="range" class="slider" v-if="outputvar == 'mouth'" v-model="mouthcounter" :max="mouth.length -1">
              </div>

                <!-- these selector buttons set this.outputvar to respective avatar element-->
                <!-- each button is available twice: for "to be selected" and "selected" (colored) state-->
              <div class="selectorwrapper">
                <div>
                  <button class="avatarDeselected" @click="selectTop()" v-if="outputvar != 'top'"><img class="avatarSelectorsIcons" src="../assets/hair.svg"></button>
                  <button class="avatarSelected"  v-if="outputvar == 'top'"><img class="avatarSelectorsIcons" src="../assets/hair.svg"></button>
                </div>
                  <!--<v-btn class="mx-auto" x-small  width="40" height="40" v-if="outputvar == 'top'" color="yellow darken-2"><img src="../assets/top.png" height="40" width="40"></v-btn>-->
                <div>
                  <button class="avatarDeselected" @click="selectHairscolor()" v-if="outputvar != 'hairscolor'"><img class="avatarSelectorsIcons" src="../assets/hair-fill.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'hairscolor'"><img class="avatarSelectorsIcons" src="../assets/hair-fill.svg"></button>   
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectSkin()" v-if="outputvar != 'skin'"><div class="skincolor"></div></button>
                  <button class="avatarSelected" v-if="outputvar == 'skin'"><div class="skincolor"></div></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectEyes()" v-if="outputvar != 'eyes'"><img class="avatarSelectorsIcons" src="../assets/eye.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'eyes'"><img class="avatarSelectorsIcons" src="../assets/eye.svg"></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectEyebrow()" v-if="outputvar != 'eyebrow'"><img class="avatarSelectorsIcons" src="../assets/eyebrows.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'eyebrow'"><img class="avatarSelectorsIcons" src="../assets/eyebrows.svg"></button>
                </div>
              </div>
            
              <div class="selectorwrapper">

                <div>
                  <button class="avatarDeselected" @click="selectMouth()" v-if="outputvar != 'mouth'"><img class="avatarSelectorsIcons" src="../assets/mouth.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'mouth'"><img class="avatarSelectorsIcons" src="../assets/mouth.svg"></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectClothes()" v-if="outputvar != 'clothes'"><img class="avatarSelectorsIcons" src="../assets/shirt.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'clothes'"><img class="avatarSelectorsIcons" src="../assets/shirt.svg"></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectClothescolor()" v-if="outputvar != 'clothescolor'"><img class="avatarSelectorsIcons" src="../assets/shirt-fill.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'clothescolor'"><img class="avatarSelectorsIcons" src="../assets/shirt-fill.svg"></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectAccessories()" v-if="outputvar != 'accessories'"><img class="avatarSelectorsIcons" src="../assets/glasses.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'accessories'"><img class="avatarSelectorsIcons" src="../assets/glasses.svg"></button>
                </div>
                <div>
                  <button class="avatarDeselected" @click="selectFacialhair()" v-if="outputvar != 'facialhair'"><img class="avatarSelectorsIcons" src="../assets/beard.svg"></button>
                  <button class="avatarSelected" v-if="outputvar == 'facialhair'"><img class="avatarSelectorsIcons" src="../assets/beard.svg"></button>
                </div>
            
              </div>

            <canvas hidden ref="avatarcanvas" width="250" height="250"></canvas>
          </div>
    



    </div>
</template>


<script>
import firebase from "firebase";
import Avataaars from 'vuejs-avataaars'
import { store, mutations } from '@/components/store'

export default {

  components: {
    Avataaars
  },

  data() {
    return {

      user: firebase.auth().currentUser,

      clothes: [
          'CollarSweater',
          ///'GraphicShirt', deleted
          'Hoodie',
          'Overall',
          'ShirtCrewNeck',
          'ShirtScoopNeck',
          'ShirtVNeck',
          'BlazerShirt',
          'BlazerSweater',
        ],
      clothescounter: 0,
      accessories: [
        'Blank',
        'Kurt',
        'Prescription01',
        'Prescription02',
        'Round',
        'Sunglasses',
        'Wayfarers',
      ],
      accessoriescounter: 0,
      clothescolor: [
          'Black',
          'Blue01',
          'Blue02',
          'Blue03',
          'Gray01',
          'Gray02',
          'Heather',
          'PastelBlue',
          'PastelGreen',
          'PastelOrange',
          'PastelRed',
          'PastelYellow',
          'Pink',
          'Red',
          'White'
        ],
      clothescolorcounter: 0,
      hairscolor: [
          'Auburn',
          'Black',
          'Blonde',
          'BlondeGolden',
          'Brown',
          'BrownDark',
          'PastelPink',
          'Platinum',
          'Red',
          'SilverGray',
        ],
      hairscolorcounter: 0,
      eyebrow: [
          'Default',
          'DefaultNatural',
          'FlatNatural',
          'RaisedExcited',
          'RaisedExcitedNatural',
          'SadConcerned',
          'SadConcernedNatural',
          'UnibrowNatural',
          'UpDown',
          'UpDownNatural',
          'Angry',
          'AngryNatural',
        ],
      eyebrowcounter: 0,
      eyes: [
          'Default',
          'EyeRoll',
          'Happy',
          'Side',
          'Squint',
          'Surprised',
          'Wink',
          'WinkWacky',
          'Close',
          'Dizzy',
          'Hearts',
          'Cry',
        ],
      eyescounter: 0,
      facialhair: [
        'Blank',
        'BeardMedium',
        'BeardLight',
        'BeardMagestic',
        'MoustacheFancy',
        'MoustacheMagnum'
      ],
      facialhaircounter: 0,
      skin: [
        'Pale',
        'Light',
        'Brown',
        'DarkBrown',
        'Black',
        'Tanned',
        'Yellow',
      ],
      skincounter: 0,
      top: [
        'LongHairBigHair',
        'LongHairBob',
        'LongHairBun',
        'LongHairCurly',
        'LongHairCurvy',
        'LongHairDreads',
        'LongHairFrida',
        'LongHairFro',
        'LongHairFroBand',
        'LongHairNotTooLong',
        'LongHairShavedSides',
        'LongHairMiaWallace',
        'LongHairStraight',
        'LongHairStraight2',
        'LongHairStraightStrand',
        'NoHair',
        'ShortHairDreads01',
        'ShortHairDreads02',
        'ShortHairFrizzle',
        'ShortHairShaggyMullet',
        'ShortHairShortCurly',
        'ShortHairShortFlat',
        'ShortHairShortRound',
        'ShortHairShortWaved',
        'ShortHairSides',
        'ShortHairTheCaesar',
        'ShortHairTheCaesarSidePart',
        //'Eyepatch',
        //'Hat',
        //'Hijab',
        //'Turban',
        //'WinterHat1',
        //'WinterHat2',
        //'WinterHat3',
        //'WinterHat4'
      ],
      topcounter: 0,
      mouth: [
        'Smile',
        'Tongue',
        'Twinkle',
        'Serious',
        'Concerned',
        'Default',
        'Disbelief',
        'Eating',
        'Grimace',
        'Sad',
        'ScreamOpen',
        'Vomit'
      ],
      mouthcounter: 0,
      avatar: false,
      outputvar: 'top',
      inputvar: ['clothes','accessories','clothescolor','hairscolor','eyebrow','eyes','facialhair','skin','top','mouth'],
      avatarimg: undefined,
      avatardata: store.avatardata

    }

  },

  computed: {   

    //avatardata() {return store.avatardata},

    // documentation for avatar creation: https://github.com/orgordin/vuejs-avataaars
    // _final props contain the selection from the avatar catalogues (eg clothes) as setted by
    // the respective counter variable which is assigned from the sliders at DOM
    // or from DB on opening of profile dialog (see respective function)
    clothesfinal: function () {return this.clothes[this.clothescounter]},
    accessoriesfinal: function () {return this.accessories[this.accessoriescounter]},
    clothescolorfinal: function () {return this.clothescolor[this.clothescolorcounter]},
    hairscolorfinal: function () {return this.hairscolor[this.hairscolorcounter]},
    eyebrowfinal: function () {return this.eyebrow[this.eyebrowcounter]},
    eyesfinal: function () {return this.eyes[this.eyescounter]},
    facialhairfinal: function () {return this.facialhair[this.facialhaircounter]},
    skinfinal: function () {return this.skin[this.skincounter]},
    topfinal: function () {return this.top[this.topcounter]},
    mouthfinal: function () {return this.mouth[this.mouthcounter]},

    imgurlGallery() {return store.imgurlGallery}
  },

  mounted () {

    if (this.avatardata != undefined) {
      this.clothescounter = this.clothes.indexOf(this.avatardata.clothes)
      this.accessoriescounter = this.accessories.indexOf(this.avatardata.accessories)
      this.clothescolorcounter = this.clothescolor.indexOf(this.avatardata.clothescolor)
      this.hairscolorcounter = this.hairscolor.indexOf(this.avatardata.hairscolor)
      this.eyebrowcounter = this.eyebrow.indexOf(this.avatardata.eyebrow)
      this.eyescounter = this.eyes.indexOf(this.avatardata.eyes)
      this.facialhaircounter = this.facialhair.indexOf(this.avatardata.facialhair)
      this.skincounter = this.skin.indexOf(this.avatardata.skin)
      this.topcounter = this.top.indexOf(this.avatardata.top)
      this.mouthcounter = this.mouth.indexOf(this.avatardata.mouth)
    }
  },

  activated() {
    
    this.clothescounter = this.clothes.indexOf(this.avatardata.clothes)
    this.accessoriescounter = this.accessories.indexOf(this.avatardata.accessories)
    this.clothescolorcounter = this.clothescolor.indexOf(this.avatardata.clothescolor)
    this.hairscolorcounter = this.hairscolor.indexOf(this.avatardata.hairscolor)
    this.eyebrowcounter = this.eyebrow.indexOf(this.avatardata.eyebrow)
    this.eyescounter = this.eyes.indexOf(this.avatardata.eyes)
    this.facialhaircounter = this.facialhair.indexOf(this.avatardata.facialhair)
    this.skincounter = this.skin.indexOf(this.avatardata.skin)
    this.topcounter = this.top.indexOf(this.avatardata.top)
    this.mouthcounter = this.mouth.indexOf(this.avatardata.mouth)
  
  },



  methods: {

    selectTop() {
      this.outputvar = 'top'
    },
    selectHairscolor() {
      this.outputvar = 'hairscolor'
    },
    selectSkin() {
      this.outputvar = 'skin'
    },
    selectEyes() {
      this.outputvar = 'eyes'
    },
    selectEyebrow() {
      this.outputvar = 'eyebrow'
    },
    selectMouth() {
      this.outputvar = 'mouth'
    },
    selectClothes() {
      this.outputvar = 'clothes'
    },
    selectClothescolor() {
      this.outputvar = 'clothescolor'
    },
    selectAccessories() {
      this.outputvar = 'accessories'
    },
    selectFacialhair() {
      this.outputvar = 'facialhair'
    },

    
    createAvatar() {

    if (this.avatar == false) {this.avatar = true}
    else {this.avatar = false}
       
    },

    saveAvatar() {

      // get timestamp to be processed for db and store reference
      const freezeTimeStamp = Date.now().toString()

      // get reference of the old pic which is going to be deleted below
      const copyOfImgurlGallery = [...this.imgurlGallery]
      const oldImageUid = copyOfImgurlGallery[0].uid

      // save avatar
      const svgNode = this.$refs.avatar.$el
      const canvas = this.$refs.avatarcanvas
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = "white"
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      const anyWindow = window
      const DOMURL = anyWindow.URL || anyWindow.webkitURL || window

      const data = svgNode.outerHTML
      const img = new Image()
      const svg = new Blob([data], { type: 'image/svg+xml' })
      const url = DOMURL.createObjectURL(svg)
      img.src = url
      img.onload = () => {
        ctx.save()
        ctx.scale(1, 1)
        ctx.drawImage(img, 0, 0)
        ctx.restore()
        //DOMURL.revokeObjectURL(url)
        canvas.toBlob(
          imageBlob => {
 
            return new Promise(
              (resolve, reject) => {
              const storageRef = firebase
              .storage()
              .ref('users/' + this.user.uid + '/' + freezeTimeStamp +'.jpg')
              .put(imageBlob)
              storageRef
              .on(
                `state_changed`,snapshot=>{
                  this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                }, error=>{console.log(error.message)},
                    
                async () => {
                    
                  const imgURL = await storageRef.snapshot.ref
                  .getDownloadURL()
                  const avatardownloadurl = imgURL
                  resolve()

                  // copy global store Gallery (containing Image URLs)
                  let newImgurlGallery = [...this.imgurlGallery]

                  // replacing selected element with the new URL
                  newImgurlGallery[0].imgurl = avatardownloadurl
                  newImgurlGallery[0].uid = freezeTimeStamp

                  // save download URL in firestore
                  const db = firebase.firestore()
                  db.collection("users")
                  .doc(this.user.uid)
                  .update({
                      imgurlGallery: newImgurlGallery,
                      imgurl: newImgurlGallery[0].imgurl, //for older versions
                      picstate: true //for older versions
                  })

                  // update global state
                  mutations.setProfileImage(newImgurlGallery[0].imgurl)
                  mutations.setImgurlGallery(newImgurlGallery)

                  // delete old picture
                  // Create a reference to the file to delete
                  if (oldImageUid != 0) {
                    const refToDelete1 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'.jpg')
                    const refToDelete2 = firebase.storage().ref('users/' + this.user.uid + '/' + oldImageUid +'_200x200.jpg')
                      
                    refToDelete1.delete().then(() => {
    
                    }).catch((error) => {
                      console.log("error")
                    })

                    refToDelete2.delete().then(() => {
                          
                    }).catch((error) => {
                      console.log("error")
                    })
                  }
                }
              )
            } 
           ) 
          }, 'image/jpeg', 0.8
        )
      }

      // on closing of profile dialog the avatar selections are saved as an object
      // in firebase and assigned to this.avatapric to show the new avatar at DOM
      const avdata = {
        'clothes': this.clothesfinal,
        'accessories': this.accessoriesfinal,
        'clothescolor': this.clothescolorfinal,
        'hairscolor': this.hairscolorfinal,
        'eyebrow': this.eyebrowfinal,
        'eyes': this.eyesfinal,
        'facialhair': this.facialhairfinal,
        'skin': this.skinfinal,
        'top': this.topfinal,
        'mouth': this.mouthfinal
      }

      mutations.setAvatar(avdata)

      const db = firebase.firestore();
      db.collection("users")
        .doc(this.user.uid)
        .set({ avatar: avdata}, { merge: true })

    },
  }
}

</script>

<style scoped>
.introContainer {
    display: block;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.473); /* Black background with opacity */
    z-index: 9000; 
    padding: 20px;
}

.exitwrapper {
 display: flex;
justify-content: flex-end
}

.exit {
  margin-top: 10px;
  margin-right: 10px;
 font-size: 20px;
 color: #aaa5a5;
}

.centerwrapper {
padding: 10px;
display: flex;
justify-content: center;

}

.savebutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonfont {
font-weight: bold;
font-size: 16px
}


.avatar {

max-width: 150px;
margin-top: 10px;
margin-bottom: 10px;
margin-left: auto;
margin-right: auto

}

.slidecontainer {
padding-left: 10%;
padding-right: 10%;
margin-top: 20px;
margin-bottom: 20px;

}

input[type=range] {
  width: 100%;
  margin: 18.25px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #d0d0cf;
  border: 0;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -18.25px;
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #d3d3d2;
}
input[type=range]::-moz-range-track {
  background: #d0d0cf;
  border: 0;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 18.25px 0;
  color: transparent;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #cdcdcc;
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: #d0d0cf;
  border: 0;
}
input[type=range]::-ms-thumb {
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #d0d0cf;
}
input[type=range]:focus::-ms-fill-upper {
  background: #d3d3d2;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

input[type=range] {
  width: 100%;
  margin: 18.25px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #d0d0cf;
  border: 0;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -18.25px;
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #d3d3d2;
}
input[type=range]::-moz-range-track {
  background: #d0d0cf;
  border: 0;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 18.25px 0;
  color: transparent;
  width: 100%;
  height: 3.5px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #cdcdcc;
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: #d0d0cf;
  border: 0;
}
input[type=range]::-ms-thumb {
  width: 40px;
  height: 40px;
  background: #1eb2a6;
  border: 2px solid #1eb2a6;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #d0d0cf;
}
input[type=range]:focus::-ms-fill-upper {
  background: #d3d3d2;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.selectorwrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: -10px;
}

.avatarSelected {
  display: flex;
  border-radius: 30px;
  background-color: #1eb2a6;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  }
  
.avatarDeselected {
  display: flex;
  border-radius: 30px;
  background-color: #ffffffad;
  border: 1px solid #d0d0cf;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;

  }

  .avatarSelectorsIcons {
  width: 30px;
  height: 30px;
  margin: auto
  }

  .skincolor {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%,#60605F 80%);
  margin: auto
  }

  .imagecontainer {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content:center;
}

  .userimage {
  border-radius: 25px;
  width: 60%;
  max-width: 400px

}

</style>


