<template>
  <div class="background">
    <div class="form">

    <div v-if="!forgotPasswordState">
      <form @submit.prevent="login">

        <div class="exit">
          <button @click="goBack()" type="button"><img class="closeblock" src="../assets/back.svg" alt=""></button><br>
        </div>

        <input class="field" v-model="userData.email" id="email" name="email" type="email" placeholder="Email" autocomplete="username" required><br>
      
        <input v-show="!forgotPasswordState" class="field" v-model="userData.password" id="current-password" name="current-password" type="password" placeholder="Password" autocomplete="current-password" aria-describedby="password-constraints" required><br>
 
        <button class="loginbutton" type="submit" id="login">
          <div v-if="loading" class="progress"></div>
          <div class="buttonfont" v-else>login</div>
        </button><br>
        <button @click="initForgotPassword()" class="forgotbutton" id="forgot" type="button">problems with login?</button> 
      </form>
      
    </div>

    <div v-else>
      <button class="exit" @click="goBack()" type="button"><img class="closeblock" src="../assets/back.svg" alt=""></button><br>
      <input class="field" v-model="userData.email" id="email" name="email" type="email" placeholder="Email" autocomplete="username" required><br>
      <button @click="forgotPassword()" class="loginbutton" id="reset">reset password</button> 
    </div>
        
    <popup 
      :popupState="popupState" 
      class="popup" 
      @openPopup="openPopup()"
    >
    </popup>

    </div>
    
   </div>
 
</template>

<script>
import firebase from "firebase";
import popup from '@/components/popup'
export default {
  components: {
    popup,
  },
  name: 'Login',
  data() {
    return {
      userData: {
        email: '',
        password: ''    
      },
      forgotPasswordState: false,
      errorMessage: '',
      popupState: 0, 
      loading: false
    }
  },


    methods: {

      goBack() {
        if (this.forgotPasswordState) {
          this.forgotPasswordState = false
        } else {
            this.$router.replace({
            name: "landingpage"
          })
        }

      },

      
      login() {
        this.loading = true
        firebase.auth().signInWithEmailAndPassword(this.userData.email, this.userData.password)
          .then(() => {
            
            firebase.auth().onAuthStateChanged((user) => {
              if (user) {
                this.loading = false
                this.$router.replace({
                  name: "Driver"
                })
              }
            })
          })
          .catch(err => {
            this.loading = false
            this.openPopup(err.message)
            this.errorMessage = err.message
            console.log(err.message)

          })        
      },

      forgotPassword() {

        firebase.auth().sendPasswordResetEmail(this.userData.email).then(success => {
            this.openPopup("check your email to reset password")
            this.forgotPasswordState = false
          }
        )
        .catch(
          err => {
            console.log(err.message)
            this.errorMessage = err.message
          }
        )
      },

      initForgotPassword() {
        this.forgotPasswordState = true

      },

     goToRegister() {
        this.$router.replace({
          name: "Register"
        })
      },

      openPopup (state) {

      const popupState = (document.getElementById("popup").style.display == "block")

      if (!popupState) {
        this.popupState = state
        document.getElementById("popup").style.display = "block"
      } else {
        document.getElementById("popup").style.display = "none"
        this.popupState = 0
        this.forgotPasswordState = false
      }

    },

    }
   }
</script>

<style scoped>

.background {
  position: fixed;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: -webkit-center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(315deg, #ffcc30 30%,#f5416c 80%);
}

.exit {
  display: flex;
  justify-content: flex-start;
  color: white;
}

.closeblock {
    height: 30px;
    margin: 15px;
}

.form {
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
}


.field {
  justify-content: center;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 60px;
  border-radius: 15px;
  width: 90vw;
  font-size: 18px;
  max-width: 400px;
  padding: 0.5rem;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

.loginbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}



.buttonfont {
font-weight: bold;
font-size: 16px
}

.forgotbutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 90vw;
  background-color: #ffffff38;
  color: white;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.popup {
  display: none;
}

.progress {
  margin: auto;
  height: 19px;
  border-radius: 20px;
  color:grey;
  position: relative;
}
.progress::before {
  content:"";
  position: absolute;
  margin:2px;
  inset:0 100% 0 0;
  border-radius: inherit;
  background:currentColor;
  animation:p6 4s infinite;
}
@keyframes p6 {
    100% {inset:0}
}





</style>