<template>
  <div>   
    <div class="inputs">
      <div><label for="selector" class="label">{{this.language.settings.language}}</label></div>
      <select id="selector" @change="setLanguage()"  class="select" v-model="selectedLanguage">
        <option value="german">Deutsch</option>
        <option value="english">English</option>
        <option value="french">Francais</option>
        <option value="italian">Italiano</option> 
      </select>

    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { store, mutations } from '@/components/store'


export default {


  data() {
    return {
      user: firebase.auth().currentUser,
      selectedLanguage: "english"

    }
  },

  computed: {
      language() {return store.language}
  },

  mounted() {
    let selectElement = document.getElementById("selector")

    selectElement.value = this.language.language
    this.selectedLanguage = this.language.language

  },



  methods: {

    setLanguage() {
      mutations.setLanguage(this.selectedLanguage)

      const db = firebase.firestore()
      db.collection("users")
        .doc(this.user.uid)
        .update({
          "settings.language": this.selectedLanguage
        })
      
    },
    
  }
}

</script>

<style scoped>


.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px
}


.label {
  width: 90vw;
  max-width: 400px;
  font-size: 18px;
  font-weight: bold;
  color: #5F5F5F
  

}


.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  text-align: -webkit-center;
  border: 2px solid rgb(255, 255, 255);
  height: 50px;
  border-radius: 15px;
  width: 90vw;
  font-size: 18px;
  max-width: 400px;
  padding: 0.5rem;
  margin-bottom: 30px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.233);
  color: white;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.savebutton {
  max-width: 400px;
  height: 60px;
  border-radius: 30px;
  width: 90vw;
  background-color: #ffffff;
  color: #f5416c;
  padding: 0.5rem;
  font: inherit;
  outline: none;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26); 
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.26);
}

.buttonfont {
font-weight: bold;
font-size: 16px
}

</style>


